import styled from 'styled-components';
export default function SelectWrapper({
  label,
  children,
  required,
  error,
  color,
}) {
  return (
    <StyleWrapper>
      <p style={{ color: color || '#2254D3', fontSize: '12px' }}>
        {label} {required && <span className="text-danger">*</span>}
      </p>

      {children}
      {error && (
        <p className="text-danger" style={{ fontSize: '12px' }}>
          {error}
        </p>
      )}
    </StyleWrapper>
  );
}

const StyleWrapper = styled.div`
  margin-top: 5px;
  width: 100% !important;
`;
