import Package from '../Package';

export default function Index() {
  return (
    <Package
      url="plan/insurance-plans"
      createUrl="utils/create-health-plan-service-for-a-facility-type"
      editUrl="utils/plan/insurance-plan/retrieve-update-destroy"
      deleteUrl="insurance-plan"
      facilityType="INSURANCE"
    />
  );
}
