import React from 'react';
import Layout from '../layout';
import Header from '../../common/Header';
import PageTop from '../../common/PageTops';
import DashboardStatCard from '@components/DashboardStatsCard';
import styled from 'styled-components';
// import greeting from '@utils/greeting';
import { useSelector } from 'react-redux';

const Index = () => {
  // const { firstName } = useSelector((state) => state.pageInfo);
  const { name } = useSelector((state) => state.country);
  return (
    <Layout>
      <StyledWrapper>
        <Header
          practiceName={name}
          heading={`${name}: Dashhboard`}
          // subheading={`${firstName}`}
          // greeting={greeting()}
        />
        <div className="mt-2">
          <PageTop />
        </div>
        <div className="stats mt-4">
          <DashboardStatCard number="20" label="Consultation calls" />
          <DashboardStatCard number="1900" label="Appointments " />
          <DashboardStatCard number="2910" label="Patients" />
          <DashboardStatCard number="2120" label="Staff" />
          <DashboardStatCard number="120" label="Referred" />
          <DashboardStatCard number="2320" label="Pending Consultation" />
        </div>
      </StyledWrapper>
    </Layout>
  );
};

export default Index;

const StyledWrapper = styled.div`
  .stats {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
`;
