import React, { useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import ClipLoader from 'react-spinners/ClipLoader';
import request, { headers } from '@request';
import SubmitSuccess from '@components/SubmitSuccess';
import Input from '@components/formInputs/FormInputComponent';
import Button from '@components/Button/Button';
import { whiteBackground } from '@styles/react-select-custom';
import Select from 'react-select';
import { useGetSubscriptions } from '@hooks/queries';
import ModalWrapper from '@components/modalComponents/NewModal';
import FormTitleSection from '@components/modalComponents/FormTitleSection';
import { useFetchFacility } from '@hooks/queries';

const validationSchema = Yup.object().shape({
  payment_reference: Yup.string().required('Required'),
  package_id: Yup.object().required('Required'),
  duration: Yup.object().required('Required'),
});

export const RecordSubscriptiionPayment = ({
  facilityId,
  closeModal,
  facilityType,
}) => {
  const facilityTypeMapping = {
    HOSPITAL: 'hospital',
    CONSULTANT: 'consultant',
    PHARMACY: 'pharmacy',
    DIAGNOSTIC: 'diagnostic',
    INSURANCE: 'insurance',
    NGO: 'ngo',
  };
  const { refetch } = useFetchFacility(facilityType);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { data } = useGetSubscriptions(facilityTypeMapping[facilityType]);
  console.log('data', data);
  const planOptions = data?.data?.data?.data?.map((item) => ({
    label: item.name,
    value: item.id,
  })) || [{ label: 'Select country', value: '' }];
  const durationOption = [
    { label: '1 month', value: 1 },
    { label: '1 year', value: 12 },
  ];

  const initialValues = {
    payment_reference: '',
    package_id: null,
    duration: null,
  };

  const token = localStorage.getItem('token');
  const onSubmit = async (values) => {
    values.facility_id = facilityId;
    values.duration = values.duration.value;
    values.package_id = values.package_id.value;

    setLoading(true);
    try {
      // eslint-disable-next-line
      const res = await request.post(
        `/utils/thc-record-and-activate-subscriptiona-payment/${facilityId}/`,
        values,
        headers(token)
      );

      if (res.status === 201) {
        refetch();
        setSubmitted(true);
      }
    } catch (error) {
      toast.error('Network Error');
      console.log('errr', error.response);
    } finally {
      setLoading(false);
    }
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  return (
    <ModalWrapper>
      <FormTitleSection onClick={closeModal} />
      <ScheduleFormWrapper>
        {submitted ? (
          <SubmitSuccess
            message={`An email has been sent ${formik.values.first_name} to verify his/email`}
          />
        ) : (
          <div>
            <form onSubmit={formik.handleSubmit}>
              <div className="">
                <Input
                  className="form-control text-capitalize"
                  type="text"
                  name="payment_reference"
                  placeholder="Payment reference"
                  labelColor="#000"
                  borderColor="#2254D3"
                  value={formik.values.payment_reference}
                  onChange={formik.handleChange}
                  error={formik.errors.first_name}
                />
              </div>

              <Select
                options={planOptions}
                placeholder="Select package"
                style={whiteBackground}
                value={formik.values.country}
                onChange={(e) => formik.setFieldValue('package_id', e[0])}
                name="package_id"
              />
              {formik.touched.package_id && formik.errors.package_id ? (
                <div className="error-msg">{formik.errors.package_id}</div>
              ) : null}

              <Select
                options={durationOption}
                placeholder="duration"
                style={whiteBackground}
                value={formik.values.duration}
                onChange={(e) => formik.setFieldValue('duration', e[0])}
                name="duration"
              />
              {formik.touched.duration && formik.errors.duration ? (
                <div className="error-msg">{formik.errors.duration}</div>
              ) : null}

              <div className="form--button--wrapper mt-2">
                <Button>
                  {loading ? (
                    <ClipLoader
                      type="ThreeDots"
                      color="#00BFFF"
                      height={10}
                      width={10}
                    />
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            </form>
          </div>
        )}
      </ScheduleFormWrapper>
    </ModalWrapper>
  );
};

const ScheduleFormWrapper = styled.div`
  margin-top: 10px;
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .error-msg {
    color: red;
    font-size: 12px;
  }
`;
