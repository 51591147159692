import React, { useState, useEffect } from 'react';
import Button from '@components/Button/Button';
import CheckBox from '@components/formInputs/checkbox';
import ModalWrapper from '@components/modalComponents/NewModal';
import FormTitleSection from '@components/modalComponents/FormTitleSection';
import { toast } from 'react-toastify';
import request, { headers } from '@request';
import Input from '@components/formInputs/FormInputComponent/';
import { useFetchPaymentModeAction } from '@hooks/plans';

export default function PaymentInfo({
  paymentInfo,
  setPaymentInfo,
  planType,
  facilityType,
}) {
  const token = localStorage.getItem('token');
  const [amount, setAmount] = useState(null);
  const [amount_monthly, setMonthly_amount] = useState(null);
  const [is_monthly, setIIs_monthly] = useState(false);
  const [is_yearly, setIs_yearly] = useState(false);
  const { data, isLoading, refetch } = useFetchPaymentModeAction(
    facilityType,
    planType
  );
  const currentData = data?.data?.data;
  console.log('payment info', data?.data?.data);

  const [submitLoading, setSubmitLoading] = useState(false);

  const submit = async (e) => {
    e.preventDefault();

    setSubmitLoading(true);
    try {
      const res = await request.patch(
        `utils/payment-mode/${currentData.id}/`,
        {
          amount,
          amount_monthly,
          is_monthly,
          is_yearly,
        },
        headers(token)
      );
      if (res.status === 200) {
        toast.success(`Payment info updated`);
        refetch();
      } else {
        toast.error(`Error creating service`);
      }
    } catch (error) {
      toast.error(`Error creating service`);
    } finally {
      setSubmitLoading(false);
    }
  };

  function onLoad() {
    if (currentData) {
      setAmount(currentData.amount);
      setMonthly_amount(currentData.amount_monthly);
      setIIs_monthly(currentData.is_monthly);
      setIs_yearly(currentData.is_yearly);
    }
  }

  useEffect(() => {
    onLoad();
  }, [currentData]);

  return (
    <ModalWrapper>
      <FormTitleSection
        title={'Payment info'}
        onClick={() => setPaymentInfo(!paymentInfo)}
      />
      <form className="">
        {isLoading && (
          <p>{`Wait while we load ${planType} payment info for ${facilityType}`}</p>
        )}
        <div className="mb-3">
          <Input
            type="number"
            onChange={(e) => setMonthly_amount(e.target.value)}
            label="Subscription price per month"
            value={amount_monthly}
          />
        </div>

        <div className="mb-3">
          <Input
            type="number"
            onChange={(e) => setAmount(e.target.value)}
            label="Subscription price per year"
            value={amount}
          />
        </div>

        <div className="checkbox">
          <CheckBox
            labelStyle={{ color: '#2254d3', fontWeight: 500 }}
            label="ALLOW MONTHLY PAYMENT"
            onChangeFunction={() => setIIs_monthly(!is_monthly)}
            isChecked={is_monthly}
          />
          <CheckBox
            labelStyle={{ color: '#2254d3', fontWeight: 500 }}
            label="ALLOW YEARLY PAYMENT"
            onChangeFunction={() => setIs_yearly(!is_yearly)}
            isChecked={is_yearly}
          />
        </div>
        <div className="d-flex flex-row-reverse mt-3">
          <Button
            width="100px"
            onClick={submit}
            type="submit"
            loading={submitLoading}
          >
            Save
          </Button>
        </div>
      </form>
    </ModalWrapper>
  );
}
