import React from 'react';
import { TabNavigationWrapper } from '@styles/TabNavWrapper';

const TabNavigation = ({ tabs, activeTab, onTabClick }) => {
  return (
    <TabNavigationWrapper isActive={activeTab}>
      <div className="tab">
        {tabs.map((tab) => (
          <p
            key={tab.id}
            className={activeTab === tab.id ? 'isActiveTab' : ''}
            onClick={() => onTabClick(tab.id)}
          >
            {tab.label}
          </p>
        ))}
      </div>
      {tabs.map(
        (tab) => activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
      )}
    </TabNavigationWrapper>
  );
};

export default TabNavigation;
