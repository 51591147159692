import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { GoBack } from '../../components/GoBackIcon';
import { useHistory } from 'react-router';
// import greeting from '@utils/greeting';

export default function Header({ heading, inner }) {
  const [time, setTime] = useState('');

  // console.log(practiceName);
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date().toLocaleTimeString());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // eslint-disable-next-line
  const currentTime = () => {
    let date = new Date();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    let time = hours + ':' + minutes + ':' + seconds + ' ' + ampm;
    return setTime(time);
  };

  const history = useHistory();
  return (
    <HeaderWrapper>
      <div className="row-2">
        <div>
          {/* {!inner && <h3 className="heading_title">{practiceName}</h3>} */}
          <div className="heading">
            {inner && (
              <div
                onClick={() => history.goBack()}
                className="goback d-flex justify-content-center align-items-center mr-3"
                style={{ cursor: 'pointer' }}
              >
                <GoBack width="0.8em" height="0.8em" />
              </div>
            )}

            <h3
              className="heading_title"
              style={{ color: inner && '#071232', fontWeight: inner && '500' }}
            >
              {heading}&nbsp;
            </h3>
          </div>
        </div>
        <h3 className="heading_title">{time}</h3>
        <div></div>
      </div>
    </HeaderWrapper>
  );
}

const HeaderWrapper = styled.div`
  .row-2 {
    padding: 55px 0px 10px 0px;
    background: transparent;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f3f4f8;
  }

  .goback {
    background: #dfe8fc;
    border-radius: 50%;
    width: 2.3em;
    height: 2.3em;
    border: none;
    outline: none;
  }

  & .heading {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  & .img--wrapper {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  img {
    width: 50px;
    /* height: 50px; */
  }
  .notification {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: #8e919c;
  }
  .heading_title {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #2254d3;
    letter-spacing: 10%;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  }
  .subheading {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #8e919c;
    letter-spacing: 10%;
    margin: 0;
    padding: 0;
    text-transform: uppercase !important;
  }
  @media screen and (max-width: 425px) {
    align-items: center;
    & h3 {
      font-size: 10px;
    }

    & .img--wrapper {
      width: 30px;
      height: 30px;

      & img {
        width: 50px;
        height: 50px;
      }
    }
  }
`;
