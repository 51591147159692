import styled from 'styled-components';
export const StyleWrapper = styled.div`
  .container {
    background: #f5f8fe;
    padding: 20px;
    border-radius: 5px;
    height: fit-content;
  }
  /* existing styles for the BillingWrapper here */
`;
