import React, { useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import ClipLoader from 'react-spinners/ClipLoader';
import request, { headers } from '@request';
import SubmitSuccess from '@components/SubmitSuccess';
import Checkbox from '@components/formInputs/checkbox';
import Input from '@components/formInputs/FormInputComponent';
import dateIcon from '@assets/images/date-icon.svg';
import Button from '@components/Button/Button';
import { useFetchActiveCountries } from '@hooks/queries';
import { whiteBackground } from '@styles/react-select-custom';
import Select from 'react-select';
import { useFetchTHCStaff } from '../../../hooks/queries';

const phoneRegex = /^\d*(\+\d+)?$/;
// const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  email: Yup.string().email('*Invalid email format').required('Required'),
  phone_number: Yup.string()
    .required('Required')
    .matches(phoneRegex, '*Invalid phone number'),
  country: Yup.object().required('Required'),
});

const AddNewStaff = () => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isSuperuser, setIsSuperuser] = useState(false);
  const { refetch } = useFetchTHCStaff();
  const { data } = useFetchActiveCountries();
  const activeCountries = data?.data?.data?.data?.map((item) => ({
    label: item.country,
    value: item.country_code,
  })) || [{ label: 'Select country', value: '' }];

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    country: null,
  };

  const token = localStorage.getItem('token');
  const onSubmit = async (values) => {
    values.is_active = isVerified;
    values.is_verified = isActive;
    values.is_superuser = isSuperuser;
    values.country = values.country.value;

    setLoading(true);
    try {
      // eslint-disable-next-line
      const res = await request.post(
        '/main/auth/create-thc-admin/',
        values,
        headers(token)
      );

      if (res.status === 201) {
        refetch();
        setSubmitted(true);
      }
    } catch (error) {
      toast.error('Network Error');
      console.log('errr', error.response);
    } finally {
      setLoading(false);
    }
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  return (
    <ScheduleFormWrapper>
      {submitted ? (
        <SubmitSuccess
          message={`An email has been sent ${formik.values.first_name} to verify his/email`}
        />
      ) : (
        <div>
          <form onSubmit={formik.handleSubmit}>
            <div className="form--content">
              <div className="form-input-wrapper">
                <div className="form-group">
                  <Input
                    className="form-control text-capitalize"
                    type="text"
                    name="first_name"
                    placeholder="First Name"
                    labelColor="#000"
                    borderColor="#2254D3"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.first_name && formik.errors.first_name ? (
                    <div className="error-msg">{formik.errors.first_name}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <Input
                    className="form-control text-capitalize"
                    type="text"
                    placeholder="Last Name"
                    labelColor="#000"
                    borderColor="#2254D3"
                    name="last_name"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.last_name && formik.errors.last_name ? (
                    <div className="error-msg">{formik.errors.last_name}</div>
                  ) : null}
                </div>
              </div>
              <div className="form-input-wrapper">
                <div className="form-group">
                  <Input
                    type="text"
                    placeholder="Email"
                    labelColor="#000"
                    borderColor="#2254D3"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="error-msg">{formik.errors.email}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <Input
                    type="text"
                    placeholder="Phone Number"
                    labelColor="#000"
                    borderColor="#2254D3"
                    name="phone_number"
                    value={formik.values.phone_number}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.phone_number && formik.errors.phone_number ? (
                    <div className="error-msg">
                      {formik.errors.phone_number}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="form-input-wrapper">
                <div className="form-group">
                  <Select
                    options={activeCountries}
                    placeholder="Country"
                    style={whiteBackground}
                    value={formik.values.country}
                    onChange={(e) => formik.setFieldValue('country', e[0])}
                    name="country"
                  />
                  {formik.touched.country && formik.errors.country ? (
                    <div className="error-msg">{formik.errors.country}</div>
                  ) : null}
                </div>
              </div>

              <div className="">
                <Checkbox
                  isChecked={isActive}
                  onChangeFunction={() => setIsActive(!isActive)}
                  label="Activate account"
                  color="#2254d3"
                />
                <Checkbox
                  isChecked={isSuperuser}
                  onChangeFunction={() => setIsSuperuser(!isSuperuser)}
                  color="#2254d3"
                  label="Make user a superuser user (User will have access to general control platform)"
                />
                <Checkbox
                  isChecked={isVerified}
                  onChangeFunction={() => setIsVerified(!isVerified)}
                  label="Set user to verify (user cannot login if not verified)"
                  color="#2254d3"
                />
                <div className="form--button--wrapper mt-2">
                  <Button>
                    {loading ? (
                      <ClipLoader
                        type="ThreeDots"
                        color="#00BFFF"
                        height={20}
                        width={20}
                      />
                    ) : (
                      'Add User/Staff'
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </ScheduleFormWrapper>
  );
};

export default AddNewStaff;

const ScheduleFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 10px;

  & .form--heading {
    & p {
      font-family: 'Sofia Pro';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      padding: 0;
      margin: 0;
      /* Blue */

      color: #2254d3;
    }
  }
  & input[type='date']::-webkit-calendar-picker-indicator {
    opacity: 1;
    display: block;
    background: url(${dateIcon}) no-repeat;
    width: 20px;
    height: 20px;
    border-width: thin;
  }
  & .form--content {
    display: flex;
    flex-direction: column;
    padding: 10px;

    & .form-input-wrapper {
      grid-template-columns: 1fr 1fr;
      margin-bottom: -10px;
    }

    & .input--text {
      grid-template-columns: 1fr;
      color: #2254d3;

      & textarea {
        width: 100%;
        height: 120px;

        background: #ffffff;
        border-radius: 10px;
        border: 1px solid #dfe8fc;
        outline: none;
        box-sizing: border-box;
        padding: 20px 10px;
        font-size: 13px;
        line-height: 19px;
      }
    }
  }
  & .form-input-wrapper,
  & .input--text {
    display: grid;
    column-gap: 5px;
  }

  .error-msg {
    color: red;
    font-size: 12px;
  }
  label {
    font-size: 13px;
    margin-bottom: 0px;
  }
  .checkbox-area {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 2px;
  }
`;
