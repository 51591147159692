import React from 'react';
import styled from 'styled-components';
import StaffManagement from '../../generalPages/users/StaffManagement';
import Layout from '../layout';
import Header from '../../common/Header';
// import PageTop from '../../common/PageTops';

const index = () => {
  return (
    <Layout>
      <SettingsNavWrapper>
        <Header heading="users | " />
        <div>
          <StaffManagement />
        </div>
      </SettingsNavWrapper>
    </Layout>
  );
};

const SettingsNavWrapper = styled.div`
  /* padding: 40px 30px; */
  box-sizing: border-box;
`;

export default index;
