import React, { useState, useEffect, useRef } from 'react';

import CustomSearch from '@components/formInputs/CustomSearch';
// import Select from 'react-select';
// import { whiteBackground } from '@styles/react-select-custom';
import Button from '@components/Button/Button';
import styled from 'styled-components';
import CreateResultTemplateForm from './CreateResultTemplateForm';
import { useFetchInvestigationResultParameter } from '@hooks/investigations';
import Checkbox from '@components/formInputs/checkbox';

const index = () => {
  const [searchKeyWord, setSearchKeyWord] = useState('');
  const [createNewResultParam, setCreateNewResultParam] = useState(false);
  const { data } = useFetchInvestigationResultParameter();
  const investigationParameter = data?.data?.data;
  const ref = useRef(null);

  // console.log('result param', investigationParameter);
  const [idOfTable, setIdOfTable] = useState(-1);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIdOfTable(-1);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const [currData, setCurrData] = useState(null);
  console.log('curr data', currData);

  const showModal = (item) => {
    setCurrData(item);
    setIdOfTable(item.id);
  };

  const [selectedParams, setSelectedParams] = useState([]);
  console.log(
    'selected params',
    selectedParams?.map((param) => {
      return {
        result_params: param?.result_params,
        name: param?.investigation.name,
        specimen: param.specimen,
        description: param.description,
      };
    })
  );

  const onChecked = (id) => {
    const patientInfo = Array.isArray(selectedParams) ? selectedParams : [];
    let parameter = selectedParams?.find((item) => item?.id === id);
    if (parameter) {
      let filtered = selectedParams?.filter((pa) => pa.id !== id);
      setSelectedParams(filtered);
    } else {
      const newEntry = investigationParameter?.find((p) => p.id === id);
      setSelectedParams([...patientInfo, newEntry]);
    }
  };

  const selectAll = () => {
    const allParamInCurrentPage = Array.isArray(investigationParameter)
      ? investigationParameter
      : [];
    const selectedParam = Array.isArray(selectedParams) ? selectedParams : [];

    // Check if all patients are already selected
    if (allParamInCurrentPage?.length === selectedParam?.length) {
      setSelectedParams([]);
    } else {
      setSelectedParams(allParamInCurrentPage);
    }
  };

  return (
    <StyledWrapper>
      <div className="top--header mb-2 mt-2">
        <CustomSearch
          placeholder="Search..."
          handleChange={(e) => setSearchKeyWord(e.target.value)}
          value={searchKeyWord}
        />

        <Button
          onClick={() => setCreateNewResultParam(!createNewResultParam)}
          backgroundColor="black"
          disabled={selectedParams?.length === 0}
        >
          copy to proudction
        </Button>

        <Button
          onClick={() => setCreateNewResultParam(!createNewResultParam)}
          backgroundColor="black"
        >
          Create New Result Param
        </Button>
      </div>
      <div className="mt-4">
        {data && (
          <div>
            <table>
              <thead>
                <tr>
                  <td>
                    <Checkbox
                      isChecked={
                        selectedParams?.length ===
                        investigationParameter?.length
                          ? true
                          : false
                      }
                      checked={
                        selectedParams?.length ===
                        investigationParameter?.length
                          ? true
                          : false
                      }
                      onChangeFunction={selectAll}
                    />
                  </td>

                  <th>Test Name</th>
                  <th>Test Category</th>
                  <th>Template set</th>
                  {/* <th>Analyzer</th> */}
                  <th>Specimen</th>
                  <th>Actions</th>
                </tr>
              </thead>
              {investigationParameter?.length > 0 ? (
                investigationParameter?.map((item) => (
                  <tbody key={item?.id}>
                    <tr>
                      <td>
                        <Checkbox
                          isChecked={
                            selectedParams?.find((p) => p?.id === item.id)
                              ? true
                              : false
                          }
                          checked={
                            selectedParams?.find((p) => p?.id === item.id)
                              ? true
                              : false
                          }
                          name={item?.id}
                          onChangeFunction={() => onChecked(item?.id)}
                        />
                      </td>

                      <td>{item?.investigation?.name} </td>
                      <td>{item?.investigation?.investigation_category}</td>
                      <td>
                        {item?.result_params?.map((item, i) => (
                          <p key={i}>{item?.name}</p>
                        ))}
                      </td>
                      {/* <td>{analyzer}</td> */}
                      <td>{item?.specimen}</td>

                      <td
                        id={item?.id}
                        className="three-dots"
                        onClick={() => showModal(item)}
                      >
                        ...
                        {item?.id === idOfTable ? (
                          <div ref={ref} className="popup">
                            <p>View template</p>
                            <p>Edit template</p>
                            <p>Copy template to production</p>
                            <p className="text-danger">
                              Delete result template
                            </p>
                          </div>
                        ) : null}
                      </td>
                    </tr>
                  </tbody>
                ))
              ) : (
                <div className="no-data">No data</div>
              )}
            </table>
          </div>
        )}
      </div>
      {createNewResultParam && (
        <CreateResultTemplateForm
          closeModal={() => setCreateNewResultParam(!createNewResultParam)}
        />
      )}
    </StyledWrapper>
  );
};

export default index;

const StyledWrapper = styled.div`
  .top--header {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    gap: 1rem;
  }
`;
