import { LOGIN_SUCCESS, LOGIN_FAILED, IS_LOGIN_IN } from '../type';
import request from '@request';
import { toast } from 'react-toastify';

const isLogging = () => ({
  type: IS_LOGIN_IN,
});

const loginSuccess = (payload) => ({
  type: LOGIN_SUCCESS,
  payload,
});

const loginFail = (payload) => ({
  type: LOGIN_FAILED,
  payload,
});

const login = (payload) => async (dispatch) => {
  dispatch(isLogging());
  try {
    const res = await request.post('/main/auth/thcadmin-login/', payload);
    return dispatch(loginSuccess(res));
  } catch (err) {
    toast.error('Invalid Login Credentials/unverified device', err);
    return dispatch(loginFail(err));
  }
};

export default login;
