import React from 'react';
import Layout from '../layout';
import Header from '../../common/Header';
import PageTop from '../../common/PageTops';
import { FacilityTable } from '../facilityTable';

function index() {
  return (
    <Layout>
      <Header />
      <div>
        <PageTop />
        <div>
          <FacilityTable facilityType="NGO" />
        </div>
      </div>
    </Layout>
  );
}

export default index;
