import { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import MyLoader from '../../utils/loader';
import ErrorBoundary from '../../components/ErrorBoundary';
import Login from '../../thcmgt/home/Index';
import ChangePassword from '../../thcmgt/home/ChangePassword';
import ResetPassword from '../../thcmgt/home/ResetPassword';
import StaffAccess from '../../thcmgt/home/Staffid';
import EmailVerified from '../../thcmgt/home/VerifyEmail';
import ConfirmEmail from '../../thcmgt/home/ConfirmEmail';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/scrollbar.css';

const HospitalRoutes = () => (
  <ErrorBoundary>
    <Suspense
      fallback={
        <div>
          <MyLoader />
        </div>
      }
    >
      <>
        <ToastContainer limit={1} style={{ fontSize: '16px' }} />

        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/change-password" component={ChangePassword} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/email-verify" component={EmailVerified} />
          <Route exact path="/confirm-email" component={ConfirmEmail} />
          <Route exact path="/staff-verification" component={StaffAccess} />
        </Switch>
      </>
    </Suspense>
  </ErrorBoundary>
);

export default HospitalRoutes;
