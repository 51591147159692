import React from 'react';
import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
`;

const TableRow = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const TableCell = styled.div`
  flex: 1;
  height: 20px;
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #eeeeee 0%,
    #dddddd 20%,
    #eeeeee 40%,
    #eeeeee 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;
  animation: ${shimmer} 1.2s infinite linear;
`;

const TableLoader = ({ rows = 5, columns = 5 }) => {
  return (
    <LoaderWrapper>
      {Array.from({ length: rows }).map((_, rowIndex) => (
        <TableRow key={rowIndex}>
          {Array.from({ length: columns }).map((_, colIndex) => (
            <TableCell key={colIndex}></TableCell>
          ))}
        </TableRow>
      ))}
    </LoaderWrapper>
  );
};

export default TableLoader;
