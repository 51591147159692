import {
  FETCH_COUNTRY_SUCCESS,
  LOADING,
  FETCH_COUNTRY_FAILED,
} from '../../actions/type';

const initialState = {
  errors: '',
  loading: false,
  data: [],
  banks: [],
};

const fetchFacilityReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOADING:
      return {
        ...state,
        loading: payload,
      };
    case FETCH_COUNTRY_SUCCESS:
      return {
        ...state,
        data: payload.data,
        errors: '',
        loading: false,
      };

    case FETCH_COUNTRY_FAILED:
      return {
        ...state,
        data: null,
        errors: payload.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default fetchFacilityReducer;
