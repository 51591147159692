import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import request, { headers } from '@request';
import SubmitSuccess from '@components/SubmitSuccess';
import FormTitleSection from '@components/modalComponents/FormTitleSection';
import Button from '@components/Button/Button';
import Input from '@components/formInputs/FormInputComponent';
import TextAreaInput from '@components/formInputs/TextAreaInput';
import ModalWrapper from '@components/modalComponents/NewModal';
import SelectWrapper from '@components/formInputs/selectWrapper';
import Select from 'react-select';
import { useFetchInvestigationCategory } from '@hooks/investigations';
import { whiteBackground } from '@styles/react-select-custom';

export default function Refund({
  currentData,
  updateCat,
  setUpdateCat,
  refetch,
}) {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const investigationCategoryOptions = useFetchInvestigationCategory();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    investigation_category: Yup.string().required('Required'),
  });

  const initialValues = {
    name: '',
    description: '',
    investigation_category: '',
  };
  // const { serviceNameValue } = useSpecialization();
  const token = localStorage.getItem('token');
  const onSubmit = async (values) => {
    setLoading(true);
    const requestMethod = currentData?.id ? 'patch' : 'post';
    try {
      const res = await request[requestMethod](
        currentData?.id
          ? `utils/investigation/${currentData?.id}/`
          : 'utils/investigation/create/',
        values,
        headers(token)
      );
      toast.success('Test updated');
      if (res.status === 201) {
        refetch();
        setSubmitted(true);
      }
    } catch (error) {
      console.log('error', error.response);
      toast.error('Error');
    } finally {
      setLoading(false);
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const onLoad = () => {
    if (currentData) {
      formik.setFieldValue('name', currentData.name, false);
      formik.setFieldValue('description', currentData.description, false);
      formik.setFieldValue(
        'investigation_category',
        currentData.investigation_category,
        false
      );
    }
  };

  useEffect(() => {
    onLoad();
  }, [currentData]);

  return (
    <ModalWrapper>
      <ContentWrapper>
        <FormTitleSection
          //   name={forName}
          title="Create New Investigation"
          onClick={() => setUpdateCat(!updateCat)}
        />
        {submitted ? (
          <SubmitSuccess message={`New investigation saved`} />
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <div className="row-1">
              <div className="huidp">
                <Input
                  label="Test Name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  // disabled={currentData && currentData?.id}
                />
              </div>
            </div>
            <div className="">
              <SelectWrapper
                error={formik.errors.investigation_category}
                label="Investigation category"
                name="investigation_category"
              >
                <Select
                  options={investigationCategoryOptions}
                  styles={whiteBackground}
                  placeholder="Select category"
                  name="investigation_category"
                  onChange={(e) =>
                    formik.setFieldValue('investigation_category', e.value)
                  }
                  value={investigationCategoryOptions?.find(
                    (option) =>
                      option.value === formik.values.investigation_category
                  )}
                />
              </SelectWrapper>
            </div>
            <div className="">
              <SelectWrapper error={formik.errors.note}>
                <TextAreaInput
                  placeholder="Enter note of refund here"
                  rows="4"
                  name="description"
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  error={formik.errors.description}
                />
              </SelectWrapper>
            </div>
            <div className="d-flex flex-row-reverse">
              <Button
                className="mt-3"
                type="submit"
                onClick={formik.handleSubmit}
                loading={loading}
                width="150px"
              >
                Save
              </Button>
            </div>
          </form>
        )}
      </ContentWrapper>
    </ModalWrapper>
  );
}
const ContentWrapper = styled.div`
  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
  }

  .row-1 {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  .huidp {
    width: 100%;
  }
`;
