import React, { useState } from 'react';
import { whiteBackground } from '@styles/react-select-custom';
import Select from 'react-select';
import ModalWrapper from '@components/modalComponents/NewModal';
import FormTitleSection from '@components/modalComponents/FormTitleSection';
import { useFetchAllCountries, useFetchActiveCountries } from '@hooks/queries';
import Button from '@components/Button/Button';
import { toast } from 'react-toastify';
import request, { headers } from '../../../apiUrls/request';

export const ActivateCountry = ({ activateCountry, setActivateCountry }) => {
  const { refetch } = useFetchActiveCountries();
  const { data: allCountries } = useFetchAllCountries();
  //   console.log('currencies', allCountries?.data?.data?.data);
  const countryOptions = allCountries?.data?.data?.data?.map((item) => ({
    label: item.country,
    value: item.id,
  })) || [{ label: 'Select country', value: '' }];

  const token = localStorage.getItem('token');
  const [selectedCountry, setSelectedCountry] = useState(null);

  const [submitLoading, setSubmitLoading] = useState(false);
  const submit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    try {
      const res = await request.patch(
        `utils/countries/${selectedCountry.value}/update/`,
        { is_active: true },
        headers(token)
      );
      if (res.status === 200) {
        refetch();
        setActivateCountry(false);
        toast.success(`${selectedCountry.label} is now active`);
      } else {
        toast.error(`Error activating ${selectedCountry.label}`);
      }
    } catch (error) {
      toast.error(`Error activating ${selectedCountry.label}`);
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <ModalWrapper>
      <FormTitleSection
        title="Activate a country"
        onClick={() => setActivateCountry(!activateCountry)}
      />
      <form className="">
        <div className="mb-3">
          <Select
            options={countryOptions}
            placeholder="select country"
            style={whiteBackground}
            value={selectedCountry}
            onChange={(e) => setSelectedCountry(e[0])}
          />
        </div>
        <div className="mt-3">
          <Button onClick={submit} type="submit" title={submitLoading}>
            Save
          </Button>
        </div>
      </form>
    </ModalWrapper>
  );
};
