import {
  FETCH_BILLS_SUCCESS,
  LOADING,
  FETCH_BILLS_FAILED,
  GET_BANKS_SUCCESS,
  VALIDATED,
} from '../../actions/type';

const initialState = {
  errors: '',
  loading: false,
  data: [],
  banks: [],
};

const fetchBillReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOADING:
      return {
        ...state,
        loading: payload,
      };
    case FETCH_BILLS_SUCCESS:
      return {
        ...state,
        data: payload.data,
        errors: '',
        loading: false,
      };

    case FETCH_BILLS_FAILED:
      return {
        ...state,
        data: null,
        errors: payload.error,
        loading: false,
      };
    case GET_BANKS_SUCCESS:
      return {
        ...state,
        banks: payload,
      };
    case VALIDATED:
      return {
        ...state,
        validated: payload,
      };
    default:
      return state;
  }
};

export default fetchBillReducer;
