import axios from 'axios';
import { toast } from 'react-toastify';

// let URL = 'https://thc-development-969f3269619b.herokuapp.com/api/v1/';
// export let URL = 'http://127.0.0.1:8000/api/v1/';
export let URL = 'https://api.totalhealthcare360.com/api/v1/';
const getAccessToken = async () => {
  const user = localStorage?.getItem('refreshToken');
  try {
    const response = await fetch(`${URL}/main/auth/token/refresh/`, {
      method: 'POST',
      body: user,
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
    const res = await response.json();

    if (response.status !== 200) {
      throw new Error(response.status);
    }
    if (response.status === 401) {
      window.location.href = '/';
    }
    return res.data.token;
  } catch (err) {
    console.log(err);
  }
};

const request = axios.create({
  baseURL: URL,
  cache: {
    readOnError: (error) => {
      return error.response.status >= 400 && error.response.status < 600;
    },
    clearOnStale: false,
  },
});

request.interceptors.response.use(null, async (error) => {
  const originalRequest = error.config;

  if (error.response && error.response.status === 401) {
    const token = await getAccessToken();

    if (token) {
      localStorage.setItem('token', token);
      originalRequest.headers['Authorization'] = `Bearer ${token}`;
      return axios(originalRequest);
    }
    window.location.href = '/';
    // } else if (error.response && error.response.status === 403) {
    //   // Handle 403 Forbidden (optional)
    //   // toast.error('You do not have the access level privilege');
    //   toast.clearWaitingQueue();
    //   return Promise.reject(error);
    // }
    // else {
    // Handle other errors
    // if (error?.response?.data) {
    //   const responseData = error.response.data;

    //   if (responseData.subErrors) {
    //     // Display the first sub-error message, if available
    //     toast.error(responseData.subErrors[0].message);
    //   } else {
    //     // Display the main error message or a generic error message
    //     toast.error(
    //       responseData.message ||
    //         responseData.error ||
    //         'An error occurred. Please contact the admin.'
    //     );
    //   }
    // }
    // else if (error.response && error.response.status === 400) {
    //   // Handle specific 400 Bad Request errors (optional)
    //   toast.error(
    //     error.response.data.data.error ||
    //       'Error processing request. Please check your input and try again.'
    //   );
    // } else {
    //   // Handle other network or server errors
    //   toast.error('An error occurred. Please try again later.');
    // }
  }
  toast.clearWaitingQueue();
  return Promise.reject(error);
});

request.interceptors.request.use((config) => {
  // Check if the user is online
  if (!navigator.onLine) {
    toast.error('Please connect to the internet and try again.');
    const error = new Error('Please connect to the internet and try again.');
    return Promise.reject(error);
  }
  return config;
});

export const headers = (token) => {
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
      'X-Country': 'NG',
    },
  };
};

export const headerCountry = () => {
  return {
    headers: {
      'Content-Type': 'application/json',
      'X-Country': 'NG',
    },
  };
};

export default request;
