import {
  FETCH_PAYMENT_MODE_SUCCESS,
  FETCH_PAYMENT_MODE_FAILED,
  UPDATE_PAYMENT_MODE_SUCCESS,
  UPDATE_PAYMENT_MODE_FAILED,
  FETCH_TRANSACTION_SUCCESS,
  FETCH_TRANSACTION_FAILED,
  UPDATE_TRANSACTION_SUCCESS,
  UPDATE_TRANSACTION_FAILED,
  LOADING,
} from '../../actions/type';

const initialState = {
  errors: '',
  loading: false,
  data: [],
};

const fetchPaymentModeReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PAYMENT_MODE_SUCCESS:
      return {
        ...state,
        data: payload.data,
        errors: '',
        loading: false,
      };

    case FETCH_PAYMENT_MODE_FAILED:
      return {
        ...state,
        data: null,
        errors: payload.error,
        loading: false,
      };
    case UPDATE_PAYMENT_MODE_SUCCESS:
      return {
        ...state,
        data: payload.data,
        errors: '',
        loading: false,
      };
    case UPDATE_PAYMENT_MODE_FAILED:
      return {
        ...state,
        data: null,
        errors: payload.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default fetchPaymentModeReducer;

export const transactionReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TRANSACTION_SUCCESS:
      return {
        ...state,
        data: payload.data,
        errors: '',
        loading: false,
      };

    case FETCH_TRANSACTION_FAILED:
      return {
        ...state,
        data: null,
        errors: payload.error,
        loading: false,
      };
    case UPDATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        data: payload.data,
        errors: '',
        loading: false,
      };
    case UPDATE_TRANSACTION_FAILED:
      return {
        ...state,
        data: null,
        errors: payload.error,
        loading: false,
      };
    default:
      return state;
  }
};
