import { LOGOUT } from '../../actions/type';

const logoutReducer = (state = {}, action) => {
  switch (action.type) {
    case LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('is_admin');
      localStorage.removeItem('is_hospital');
      localStorage.removeItem('refreshToken');
      window.location.href = `/`;
      return {
        ...state,
        token: null,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export default logoutReducer;
