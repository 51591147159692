import React from 'react';
// import { useState } from "react";
import styled from 'styled-components';

interface Props {
  background?: string;
  isChecked?: boolean;
  onChangeFunction?: () => any;
  checked?: boolean;
  name?: string;
  label?: string;
  labelStyle?: React.CSSProperties;
  disabled?: boolean;
  color?: string;
}
const Checkbox = ({
  background,
  isChecked,
  onChangeFunction,
  checked,
  name,
  label,
  labelStyle,
  disabled,
  color,
}: Props) => {
  return (
    <CustomCheckboxWrapper color={color} background={background}>
      <input
        type="checkbox"
        onChange={onChangeFunction}
        checked={checked}
        name={name}
        disabled={disabled}
      />
      <span
        className={`checkbox ${isChecked ? 'checkbox--active' : ''}`}
        // This element is purely decorative so
        // we hide it for screen readers
        aria-hidden="true"
      >
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.76415 10.4897L12.8921 4.36099L13.8355 5.30366L6.76415 12.375L2.52148 8.13233L3.46415 7.18966L6.76415 10.4897Z"
            fill={isChecked ? 'white' : ''}
          />
        </svg>
      </span>
      <div className="label" style={labelStyle}>
        {label}
      </div>
    </CustomCheckboxWrapper>
  );
};

interface CustomCheckboxWrapperProps {
  background?: string;
}
export const CustomCheckboxWrapper = styled.label<CustomCheckboxWrapperProps>`
  display: flex;
  align-items: center;
  flex-direction: row;
  input {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    /* height: 1px; */
    /* overflow: hidden; */
    position: absolute;
    /* white-space: nowrap; */
  }
  .label {
    font-size: 14px;
    color: ${({ color }) => (color ? color : '#000')};
    font-weight: 400;
  }
  .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18px;
    width: 18px;
    padding: 2px;
    background: #fff;
    border: 1px #adbee1 solid;
    border-radius: 5px;
    margin-right: 4px;
  }

  .checkbox--active {
    border-color: #dfe8fc;
    background: #2254d3;
  }
`;

export default Checkbox;
