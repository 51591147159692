import React, { useState } from 'react';
import styled from 'styled-components';
import MyButton from '../../components/Button/Button';
import { ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
// import { useSelector} from "react-redux";
import signup from '../../redux/actions/auth/signup.action';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import ClipLoader from 'react-spinners/ClipLoader';
import keyImage from '../../assets/images/key.svg';
import selectDropdown from '../../assets/images/select-dropdown.svg';
// import { useHistory } from "react-router";
// import {bindActionCreators} from "redux"; I wont be neeeding action creators since

const ConfirmEmail = () => {
  // const history = useHistory();
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object({
    practiceName: Yup.string()
      .min(9, 'code cannot be less than 9 numbers')
      .max(9, 'code cannot be more than 9 numbers')
      .required('Practice name is Required'),
  });

  const initialValues = {
    code: '',
  };

  // const state = useSelector((state) => sConfirmEmail);
  const dispatch = useDispatch();

  // const onSubmit = () => {
  //   setLoading(true);
  //   toast.success("Signup Successful");
  //   history.push("/admin-dashboard");
  // }

  const onSubmit = ({ code }) => {
    setLoading(true);
    let data = {
      code,
    };
    // let data = values.map((v) => ({ ...v, ...constant }));
    dispatch(signup(data));
    setLoading(false);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <ConfirmEmailWrapper>
      <div className="first--section">
        <div className="first--section--wrapper">
          <h2>Confirm your account</h2>
          <h3>Enter the confirmation code sent to your email address.</h3>
        </div>
      </div>
      <ConfirmFormWrapper>
        <ToastContainer style={{ zIndex: '10' }} position="top-right" />
        <div className="confirm--email--container">
          <form onSubmit={formik.handleSubmit}>
            <div className="heading">
              <div className="key--image">
                <img src={keyImage} alt="key" />
              </div>
              <div>
                <h4>Confirm your email</h4>
                <small>Enter your confirmation code</small>
              </div>
            </div>

            <div className="form--group">
              <label>Enter confirmation code</label>
              <input
                type="text"
                name="phone"
                onChange={formik.handleChange}
                value={formik.values.phone}
                className="form-control"
                placeholder="123456789"
              />
              {formik.errors.phone && formik.touched.phone ? (
                <div className="error">{formik.errors.phone}</div>
              ) : null}
            </div>
            <MyButton className="form-btn add-client-btn" type="submit" full>
              {loading ? (
                <ClipLoader color={'#fff'} size={'30px'} />
              ) : (
                'Confirm'
              )}
            </MyButton>
          </form>
          <div className="account">
            <p>Resend confirmation code</p>
          </div>
        </div>
      </ConfirmFormWrapper>
    </ConfirmEmailWrapper>
  );
};

export default ConfirmEmail;

const ConfirmEmailWrapper = styled.div`
  font-family: 'Sofia Pro';
  font-style: normal;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
  box-sizing: border-box;
  padding: 35px 70px;
  background: #f5f7fa;
  overflow-y: scroll;

  & .account {
    display: flex;
    gap: 5px;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-top: 20px;

    & p {
      font-weight: 400;
      font-size: 12px;
      padding: 0;
      margin: 0;
      text-align: center;
      color: #2254d3;
    }

    & a {
      font-size: 12px;
    }
  }

  & .first--section {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 20px;

    & .first--section--wrapper {
      display: flex;
      flex-direction: column;
      gap: 2px;
      width: 100%;

      & h2 {
        font-family: 'Sofia Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 64px;

        /* Black */

        color: #071232;
      }

      & h3 {
        font-family: 'Sofia Pro';
        font-style: normal;
        font-weight: 500;
        font-size: 33px;
        line-height: 104.6%;
        /* or 42px */

        /* Blue */

        color: #2254d3;
      }

      @media screen and (max-width: 525px) {
        & h2 {
          font-size: 25px;
        }

        & h3 {
          font-size: 20px;
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 525px) {
    padding: 10px;
  }
`;

const ConfirmFormWrapper = styled.div`
  font-family: 'Sofia Pro';
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;

  .error {
    color: red;
  }
  .confirm--email--container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: #ffffff;
    border: 1px solid #dfe8fc;
    box-sizing: border-box;
    border-radius: 0.9em;
    height: 377.38px;
    width: 580px;
    box-shadow: 0px 8px 19px rgba(34, 84, 211, 0.15);
    border-radius: 20px;

    & form {
      display: flex;
      flex-direction: column;
      gap: 20px;

      & .heading {
        display: flex;
        gap: 10px;

        & .key--image {
          width: 50px;
          height: 50px;
          left: 760px;
          top: 104px;

          /* Card border */

          background: #dfe8fc;
          border-radius: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        & div:last-of-type {
          display: flex;
          flex-direction: column;
          justify-content: center;

          & h4 {
            padding: 0;
            margin: 0;
            font-size: 20px;
          }

          & small {
            font-weight: 500;
            font-size: 10px;
            /* identical to box height */

            /* Label & subheading */
            padding: 0;
            margin: 0;
            color: #8e919c;
          }
        }
      }

      & .inputs--wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;

        & .form--group {
          display: flex;
          flex-direction: column;
          gap: 2px;

          & .password--wrapper {
            position: relative;

            & img {
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
            }
          }

          & select {
            /* background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAh0lEQVQ4T93TMQrCUAzG8V9x8QziiYSuXdzFC7h4AcELOPQAdXYovZCHEATlgQV5GFTe1ozJlz/kS1IpjKqw3wQBVyy++JI0y1GTe7DCBbMAckeNIQKk/BanALBB+16LtnDELoMcsM/BESDlz2heDR3WePwKSLo5eoxz3z6NNcFD+vu3ij14Aqz/DxGbKB7CAAAAAElFTkSuQmCC") !important; */
            background-image: url(${selectDropdown}) !important;
            background-repeat: no-repeat !important;
            background-position: calc(100% - 5px) center !important;
            width: 100% !important;
            height: 50px;

            background: #ffffff;
            /* Card border */

            border: 1px solid #dfe8fc;
            box-sizing: border-box;
            border-radius: 5px;
            padding: 5px;
            position: relative;
            margin: 0 !important;
            font-family: 'Sofia Pro';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 120%;
            /* identical to box height, or 18px */

            /* Label & subheading */
            border: 1px solid #2254d3;
            color: #8e919c;
          }

          & input {
            width: 100%;
            height: 50px;
            background: #ffffff;
            /* Blue */
            border: 1px solid #2254d3;
            border-radius: 5px;
          }

          & input:focus {
            outline: none;
            border: 1px solid #2254d3;
            box-shadow: none;
          }
          & label {
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 19px;
            color: #8e919c;
          }
        }

        & h3 {
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 34px;
          color: #071232;
          margin-bottom: 0;
        }
      }
    }
    @media screen and (max-width: 1200px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 0px;
  }
`;
