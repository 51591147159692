import { SIGNUP_SUCCESS, SIGNUP_FAILED } from '../type';
import { retrieveErrMessage } from '../../../utils/helper';
import request from '../../../apiUrls/request';
import { toast } from 'react-toastify';

const signupSuccess = (payload) => ({
  type: SIGNUP_SUCCESS,
  payload,
});

const signupFailed = (payload) => ({
  type: SIGNUP_FAILED,
  payload,
});

const signup = (payload) => async (dispatch) => {
  try {
    const res = await request.post('/main/auth/register/', payload);
    toast.success('Signup successful, go to your email to activate account');

    return dispatch(signupSuccess(res.message));
  } catch (error) {
    // Check if network failed
    if (!navigator.onLine) {
      toast.error('Network Error');
      return dispatch(signupFailed(error));
    }
    // check if the use already exist
    if (error.response.status === 400 || error.response.status === 409) {
      toast.error('User Already Exist');
      return dispatch(signupFailed(error));
    }
    if (error.statusCode >= 500) {
      toast.error('There is problem with our server. Please try again later');
      return dispatch(signupFailed(error));
    }
    toast.error(retrieveErrMessage(error));
    return dispatch(signupFailed(error));
  }
};

export default signup;
