import Package from '../Package';

export default function Index() {
  return (
    <Package
      url="plan/mobile-plans"
      createUrl="utils/create-health-plan-service-for-a-facility-type"
      editUrl="utils/plan/mobile-plan/retrieve-update-destroy"
      deleteUrl="mobile-plan"
      facilityType="MOBILE_APP"
    />
  );
}
