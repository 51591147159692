import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

const FormUploadComponent = ({
  label,
  error,
  setSelectedFile,
  selectedFile,
  pictureType,
  docType,
  acceptTypes,
  receiptType,
  show,
  formStyle,
  labelColor,
}) => {
  const [, setImageUrl] = useState('');

  const handleFile = (file) => {
    setSelectedFile(file);
  };

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  // const deleteFile = () => {
  //   setSelectedFile({});
  //   setImageUrl("");
  // };

  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFile(files[0]);
    }
  };
  const fileInputRef = useRef();

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };

  const setPreview = () => {
    const file = fileInputRef.current.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImageUrl(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
      setImageUrl(reader.result);
    } else {
      setImageUrl('');
    }
  };

  // console.log('selected file', selectedFile);

  const checkType = () => {
    if (fileInputRef.current.files[0]?.name) {
      const value = fileInputRef.current.files[0]?.name.split('.');
      const fileType = value[value.length - 1];
      // console.log('file type', fileType);

      if (
        pictureType &&
        pictureType.length > 0 &&
        (fileType === 'jpg' ||
          fileType === 'jpeg' ||
          fileType === 'gif' ||
          fileType === 'png')
      ) {
        return fileType;
        // return pictureType.find((x) => x === fileType);
      } else if (
        docType &&
        docType.length > 0 &&
        (fileType === 'xlsx' ||
          fileType === 'xls' ||
          fileType === 'pdf' ||
          fileType === 'docx' ||
          fileType === 'csv' ||
          fileType === 'doc')
      ) {
        return fileType;
        // return docType.find((x) => x === fileType);
      } else if (
        receiptType &&
        receiptType.length > 0 &&
        (fileType === 'png' ||
          fileType === 'jpg' ||
          fileType === 'jpeg' ||
          fileType === 'gif' ||
          fileType === 'pdf')
      ) {
        return fileType;
        // return receiptType.find((x) => x === fileType);
      } else {
        return undefined;
      }
    }
  };

  const fileSelected = () => {
    console.log('file input ref', fileInputRef.current.files[0]?.name);
    if (fileInputRef.current.files[0]?.name) {
      console.log('.......', checkType());
      const approvedFileType = checkType();
      // console.log('type', approvedFileType);

      if (approvedFileType !== undefined) {
        if (fileInputRef.current.files[0].size / (1024 * 1024) < 5) {
          handleFile(fileInputRef.current.files[0]);
          setPreview();
        } else {
          toast.error('File size is greater than 5mb');
        }
      } else {
        toast.error('File type is not accepted');
      }
    } else {
      toast.error('No file selected');
    }
  };

  const handleTouch = () => {};

  return (
    <StyledFormUploadComponent labelColor={labelColor}>
      {label && <label className="form-font form-label">{label}</label>}
      <div
        className="drop-container"
        style={formStyle}
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
        onClick={fileInputClicked}
        onBlur={handleTouch}
      >
        <div className="form-font drop-message">
          {/* {imageUrl ? <img src={imageUrl} className="img-preview" alt="preview" /> : */}
          <>
            {' '}
            <input
              ref={fileInputRef}
              className="file-input"
              type="file"
              multiple
              onChange={fileSelected}
            />
            <div className="upload-icon">
              {/* <p className="form-label">{label}</p> */}
              <p className="form-type">{acceptTypes}</p>
              {selectedFile.name && show !== 'no image' && (
                <div className="flex file-status-bar">
                  <div className="flex file-display">
                    <div className="file-type-logo"></div>
                    <span className="form-label file-name">
                      {selectedFile.name}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </>
        </div>
      </div>

      {error && <div className="form-error file-error-message">{error}</div>}
    </StyledFormUploadComponent>
  );
};

const StyledFormUploadComponent = styled.div`
  font-size: 12px;
  align-items: center;
  justify-content: center;
  label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 2px;
    color: ${(props) => (props.labelColor ? props.labelColor : '#2254d3;')};
  }
  .drop-container {
    padding-top: 1rem;
    width: 100%;
    height: 2.5rem;
    background: #ffffff;
    border: 1px solid #dddddf;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 12px;
  }
  .upload-icon {
    /* /* width: 2.685rem; */
    height: 40px;
    width: 100%;
    background: url(images/upload-icon.svg) no-repeat center center;
    background-size: 100%;
    text-align: center;
    margin: 0 auto;
    /* padding-top: 30px; */
  }
  .underline {
    text-decoration: underline;
    display: block;
  }
  .underline:hover {
    text-decoration: none;
  }
  .no-underline {
    display: block;
    margin-top: 1rem;
  }
  .drop-message {
    margin: 0 auto;
    margin-top: -10px;
    font-weight: normal;
    width: 100%;
    font-size: 12px;
    line-height: 1.8rem;
    text-align: center;
  }
  .img-preview {
    width: 40%;
    border: 1px dashed rgba(33, 51, 79, 0.1);
  }
  .flex {
    display: flex;
    flex-direction: row;
  }
  .file-status-bar {
    justify-content: space-between;
  }
  .file-display {
    align-items: center;
  }
  .file-type-logo {
    display: inline-block;
    width: 4rem;
    height: 4rem;
    background: url(images/file-icon.svg) no-repeat center center;
    background-size: 100%;
  }
  .file-name {
    margin-top: -7rem;
    margin-left: 0.9rem;
    display: inline;
    max-width: 250px;
  }
  .file-error-message {
    display: inline;
  }
  .file-remove {
    cursor: pointer;
    font-size: 1.6rem;
  }
  .file-input {
    display: none;
  }
  @media only screen and (max-width: 1200px) {
    .drop-container {
      width: 100%;
    }
  }
`;

export default FormUploadComponent;
