import React, { useState } from 'react';
import styled from 'styled-components';
import MenuItem from '@components/MenuItem';
import { AiOutlineHome, AiFillSchedule } from 'react-icons/ai';
import { GiDoctorFace } from 'react-icons/gi';
import { BsPersonFill } from 'react-icons/bs';
import { MdOutlineLogout } from 'react-icons/md';
import Logo from '@components/Logo';

const SidebarDemo = () => {
  const [CountriesActive] = useState(true);
  const [forumActive] = useState(false);
  const [optionListActive] = useState(false);
  const [userActive] = useState(false);

  const logout = () => {
    localStorage.clear();
    window.location.href = `/`;
    return;
  };
  return (
    <MenuStyle>
      <div className="menu--wrapper">
        <div className="logo-container">
          <Logo />
        </div>
        <div>
          <MenuItem
            label="Countries"
            Icon={AiOutlineHome}
            to={`/thc-admin-general/countries`}
            active={CountriesActive}
          />
          <MenuItem
            label="Forums"
            Icon={GiDoctorFace}
            to={`/thc-admin-general/forums`}
            active={forumActive}
          />
          <MenuItem
            label="Investigations"
            Icon={BsPersonFill}
            to={`/thc-admin-general/result-parameters`}
            active={optionListActive}
          />
          <MenuItem
            label={'Users'}
            Icon={AiFillSchedule}
            to={`/thc-admin-general/users`}
            active={userActive}
          />
          <MenuItem
            label={'Countries activation'}
            Icon={AiFillSchedule}
            to={`/thc-admin-general/countries-activation`}
            active={userActive}
          />
          <div className="logout">
            <MdOutlineLogout />
            <p onClick={() => logout()}>Logout</p>
          </div>
        </div>
      </div>
    </MenuStyle>
  );
};

export default SidebarDemo;

const MenuStyle = styled.div`
  /* width: 15rem ; */
  height: 100vh;
  padding: 5px;
  /* box-shadow: 0px 0px 10px #e6e6e6; */
  /* z-index: 1; */
  width: 200px;
  background: url('https://res.cloudinary.com/dwbfq30yz/image/upload/v1650097185/DBbackground_down_slmy3x.png')
      center bottom no-repeat,
    url('https://res.cloudinary.com/dwbfq30yz/image/upload/v1650097185/DBbackground_down_slmy3x.png')
      center top no-repeat;
  background-size: contain;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  .menu--wrapper {
    padding-top: 20px;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .facility-logo {
    width: 100px;
    height: 55px;
    /* border-radius: 50%; */
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
  }
  .setting {
    display: flex;
    padding-left: 10px;
    color: #8e919c;
  }
  .top-pattern {
    position: absolute;
    top: -6rem;
    left: -10rem;
    z-index: -1;
  }
  .top-image {
    height: 20rem;
    width: 22.5rem;
  }
  .bottom-pattern {
    position: absolute;
    bottom: 4rem;
    left: 1rem;
    z-index: -1;
  }
  .bottom-image {
    height: 10rem;
    width: 11.5rem;
  }
  .logo-container {
    /* background:red ; */
    margin-bottom: 1rem;
    margin-left: -0.8rem;
  }

  .logout {
    display: flex;
    gap: 10px;
    color: red;
    gap: 15px;
    align-items: center;
    padding: 15px 10px;
    /* margin-top: 15px; */
    cursor: pointer;
    & p {
      padding: 0;
      margin: 0;
    }
  }

  @media (max-width: 768px) {
    /* width: 5rem; */
    padding: 1rem;
  }
`;
