export const drugCategoryOptions = [
  { value: '', label: 'Select Drug Category' },
  { value: 'Acne', label: 'Acne' },
  { value: 'Acute Diabetic', label: 'Acute Diabetic' },
  { value: 'Allergy Medications', label: 'Allergy Medications' },
  { value: 'Alternative Medicines', label: 'Alternative Medicines' },
  { value: 'Alzheimer', label: 'Alzheimer' },
  { value: 'Analgesics', label: 'Analgesics' },
  { value: 'Anti Diabetics', label: 'Anti Diabetics' },
  { value: 'Anti Infectives', label: 'Anti Infectives' },
  { value: 'Anti Inflammatory', label: 'Anti Inflammatory' },
  { value: 'Antiretroviral', label: 'Antiretroviral' },
  { value: 'Antibiotics', label: 'Antibiotics' },
  { value: 'Anticoagulants', label: 'Anticoagulants' },
  { value: 'Antidepressants', label: 'Antidepressants' },
  { value: 'Antimalarial', label: 'Antimalarial' },
  { value: 'Antiplatelets', label: 'Antiplatelets' },
  { value: 'Antipsychotics', label: 'Antipsychotics' },
  { value: 'Antiviral', label: 'Antiviral' },
  { value: 'Asthma Medications', label: 'Asthma Medications' },
  { value: 'Baby & Child Health', label: 'Baby & Child Health' },
  { value: 'Biologicals', label: 'Biologicals' },
  { value: 'Blockers', label: 'Blockers' },
  { value: 'Blood Thinners', label: 'Blood Thinners' },
  { value: 'Cancer', label: 'Cancer' },
  { value: 'Cardiovascular ', label: 'Cardiovascular ' },
  { value: 'Central Nervous System', label: 'Central Nervous System' },
  { value: 'Cold & Cough', label: 'Cold & Cough' },
  { value: 'Cytostatics', label: 'Cytostatics' },
  { value: 'Dental & Oral Care', label: 'Dental & Oral Care' },
  { value: 'Dermatological ', label: 'Dermatological ' },
  { value: 'Diabetic', label: 'Diabetic' },
  { value: 'Diet & Detox', label: 'Diet & Detox' },
  { value: 'Digestive System', label: 'Digestive System' },
  { value: 'Diuretics', label: 'Diuretics' },
  { value: 'Ear care', label: 'Ear care' },
  { value: 'Endocrinology', label: 'Endocrinology' },
  { value: 'Epilepsy', label: 'Epilepsy' },
  { value: 'Eye care', label: 'Eye care' },
  { value: 'First Aid', label: 'First Aid' },
  { value: 'Foot Care', label: 'Foot Care' },
  { value: 'Gastrointestinal ', label: 'Gastrointestinal ' },
  { value: 'Genitourinary ', label: 'Genitourinary ' },
  { value: 'Hair Care', label: 'Hair Care' },
  { value: 'Home Medical Equipment', label: 'Home Medical Equipment' },
  { value: 'Hormonal ', label: 'Hormonal ' },
  { value: 'Immune Suppresants', label: 'Immune Suppresants' },
  { value: 'Mobility Aids', label: 'Mobility Aids' },
  { value: 'Inhibitors', label: 'Inhibitors' },
  { value: 'Investigational drugs', label: 'Investigational drugs' },
  { value: 'Laxatives', label: 'Laxatives' },
  { value: 'Medical Supports', label: 'Medical Supports' },
  { value: 'Musculo Skeletal ', label: 'Musculo Skeletal ' },
  { value: 'Neoplastic Medicines', label: 'Neoplastic Medicines' },
  { value: 'Nervous System ', label: 'Nervous System ' },
  { value: 'Neurologic', label: 'Neurologic' },
  { value: 'Nutrition & Blood', label: 'Nutrition & Blood' },
  { value: 'Obstetrics and Gynecology', label: 'Obstetrics and Gynecology' },
  { value: 'Ophthalmic ', label: 'Ophthalmic ' },
  { value: 'Osteoporosis', label: 'Osteoporosis' },
  { value: 'Other', label: 'Other' },
  { value: 'Pain Relievers', label: 'Pain Relievers' },
  {
    value: 'Pregnancy and Contraception',
    label: 'Pregnancy and Contraception',
  },
  { value: 'Psychaitric', label: 'Psychaitric' },
  { value: 'Psychotherapeutic ', label: 'Psychotherapeutic ' },
  { value: 'Radiologic ', label: 'Radiologic ' },
  { value: 'Renal ', label: 'Renal ' },
  { value: 'Reproductive', label: 'Reproductive' },
  { value: 'Respiratory ', label: 'Respiratory ' },
  { value: 'Rheumatologic', label: 'Rheumatologic' },
  { value: 'Sexual Health', label: 'Sexual Health' },
  { value: 'Skin Care', label: 'Skin Care' },
  { value: 'Smoking Cessation', label: 'Smoking Cessation' },
  { value: 'Special Drugs', label: 'Special Drugs' },
  { value: 'Sports & Fitness', label: 'Sports & Fitness' },
  { value: 'Statins', label: 'Statins' },
  { value: 'Steroids', label: 'Steroids' },
  { value: 'Supplements', label: 'Supplements' },
  { value: 'Ulcer & Digestion', label: 'Ulcer & Digestion' },
  { value: 'Urinary Tract ', label: 'Urinary Tract ' },
  { value: 'Urologic', label: 'Urologic' },
  { value: 'Vitamins & Multivitamins', label: 'Vitamins & Multivitamins' },
  { value: 'Other', label: 'Other' },
];

export const drugModeOptions = [
  { value: '', label: 'Select Drug Mode' },
  { value: 'Tablet', label: 'Tablet' },
  { value: 'Capsules', label: 'Capsules' },
  { value: 'Injection', label: 'Injection' },
  { value: 'Suspension', label: 'Suspension' },
  { value: 'Pessaries', label: 'Pessaries' },
  { value: 'Drops', label: 'Drops' },
  { value: 'Ointment', label: 'Ointment' },
  { label: 'Vial', value: 'Vial' },
  { label: 'Ampoules', value: 'Ampoules' },
  { label: 'Bottle', value: 'Bottle' },
  { label: 'Aerosol', value: 'Aerosol' },
  { label: 'Gel', value: 'Gel' },
  { label: 'Granules', value: 'Granules' },
  { label: 'Cream', value: 'Cream' },
  { label: 'Dry Powder', value: 'Dry Powder' },
  { label: 'Dispersible Tablet', value: 'Dispersible Tablet' },
  { label: 'Liquid', value: 'Liquid' },
  { label: 'Solid', value: 'Solid' },
  { label: 'Lotion', value: 'Lotion' },
  { label: 'Powder', value: 'Powder' },
  { label: 'Solution', value: 'Solution' },
  { label: 'Syrup', value: 'Syrup' },
  { label: 'Spray', value: 'Spray' },
  { label: 'Respiratory', value: 'Respiratory' },
];

export const drugRouteOptions = [
  { value: '', label: 'Select' },
  { value: 'Inhalation', label: 'Inhalation' },
  { value: 'Oral', label: 'Oral' },
  { value: 'Intramuscular', label: 'Intramuscular' },
  { value: 'Intravenous', label: 'Intravenous' },
  { value: 'Subcutaenous', label: 'Subcutaenous' },
  { value: 'Intradermal', label: 'Intradermal' },
  { value: 'Topical', label: 'Topical' },
  { value: 'Intrarticular', label: 'Intrarticular' },
  { value: 'Intraosseous', label: 'Intraosseous' },
  { value: 'Intralessional', label: 'Intralessional' },
  { value: 'Intrathecal', label: 'Intrathecal' },
  { value: 'Intraperitoneal', label: 'Intraperitoneal' },
  { value: 'Sublingual', label: 'Sublingual' },
  { value: 'Vaginal', label: 'Vaginal' },
  { value: 'Rectal', label: 'Rectal' },
  { value: 'Ophthalmic', label: 'Ophthalmic' },
  { value: 'Local', label: 'Local' },
  { value: 'Locoregional', label: 'Locoregional' },
  { value: 'Nasal', label: 'Nasal' },
];

export const drugFrequencyOptions = [
  { value: '', label: 'Select Frequency' },
  { value: '12H - Every 12 HOURS', label: '12H - Every 12 HOURS' },
  { value: '14D - Every 14 DAYS', label: '14D - Every 14 DAYS' },
  { value: '1H - Every HOUR', label: '1H - Every HOUR' },
  { value: '1X07 - ONCE Daily at 7am', label: '1X07 - ONCE Daily at 7am' },
  {
    value: '1X12 - ONCE Daily at Midday',
    label: '1X12 - ONCE Daily at Midday',
  },
  { value: '1X16 - ONCE Daily at 4pm', label: '1X16 - ONCE Daily at 4pm' },
  { value: '1X20 - ONCE Daily at 8pm', label: '1X20 - ONCE Daily at 8pm' },
  { value: '1X22 - ONCE Daily at 10pm', label: '1X22 - ONCE Daily at 10pm' },
  { value: '21D - Every 21 DAYS', label: '21D - Every 21 DAYS' },
  { value: '24H - Every 24 HOURS', label: '24H - Every 24 HOURS' },
  { value: '28D - Every 28 DAYS', label: '28D - Every 28 DAYS' },
  { value: '2H - Every 2 HOURS', label: '2H - Every 2 HOURS' },
  { value: '4H - Every 4 HOURS', label: '4H - Every 4 HOURS' },
  { value: '2MONTH - Every 2 MONTHS', label: '2MONTH - Every 2 MONTHS' },
  {
    value: '2W FRI/MON - TWICE Weekly Friday & Monday',
    label: '2W FRI/MON - TWICE Weekly Friday & Monday',
  },
  {
    value: '2W MON/THU - TWICE Weekly Monday & Thursday',
    label: '2W MON/THU - TWICE Weekly Monday & Thursday',
  },
  {
    value: '2W SAT/SUN - TWICE Weekly Saturday & Sunday at 12 Midday',
    label: '2W SAT/SUN - TWICE Weekly Saturday & Sunday at 12 Midday',
  },
  {
    value: '2W SAT/TUE - TWICE Weekly Saturday & Tuesday',
    label: '2W SAT/TUE - TWICE Weekly Saturday & Tuesday',
  },
  {
    value: '2W SUN/WED - TWICE Weekly Sunday & Wednesday',
    label: '2W SUN/WED - TWICE Weekly Sunday & Wednesday',
  },
  {
    value: '2W THU/SUN - TWICE Weekly Thursday & Sunday',
    label: '2W THU/SUN - TWICE Weekly Thursday & Sunday',
  },
  {
    value: '2W TUE/FRI - TWICE Weekly Tuesday & Friday',
    label: '2W TUE/FRI - TWICE Weekly Tuesday & Friday',
  },
  {
    value: '2W WED/SAT - TWICE Weekly Wednesday & Saturday',
    label: '2W WED/SAT - TWICE Weekly Wednesday & Saturday',
  },
  {
    value: '2WBD F/SAT - TWICE A DAY - TWICE Weekly Friday & Saturday',
    label: '2WBD F/SAT - TWICE A DAY - TWICE Weekly Friday & Saturday',
  },
  {
    value: '2X7/22 - TWICE Daily at 7am and 10pm',
    label: '2X7/22 - TWICE Daily at 7am and 10pm',
  },
  {
    value: '2X8/20 - TWICE Daily at 8am and 8pm',
    label: '2X8/20 - TWICE Daily at 8am and 8pm',
  },
  {
    value: '2X10/22 - TWICE Daily at 10am and 10pm',
    label: '2X10/22 - TWICE Daily at 10am and 10pm',
  },
  {
    value: '2X12/16 - TWICE Daily at Midday and 4pm',
    label: '2X12/16 - TWICE Daily at Midday and 4pm',
  },
  {
    value: '2X12/22 - TWICE Daily at Midday and 10pm',
    label: '2X12/22 - TWICE Daily at Midday and 10pm',
  },
  {
    value: '2X16/22 - TWICE Daily at 4pm and 10pm',
    label: '2X16/22 - TWICE Daily at 4pm and 10pm',
  },
  {
    value: '2X6/18 - TWICE Daily at 6am and 6pm',
    label: '2X6/18 - TWICE Daily at 6am and 6pm',
  },
  { value: '5H - Every FIVE hours', label: '5H - Every FIVE hours' },
  {
    value: '5WPM - Monday to Friday at 10pm',
    label: '5WPM - Monday to Friday at 10pm',
  },
  { value: '5X - FIVE times daily', label: '5X - FIVE times daily' },
  { value: '6H - Every SIX hours', label: '6H - Every SIX hours' },
  {
    value: '6X - SIX times day 4am, 8am, Noon, 4pm, 8pm, Midnight',
    label: '6X - SIX times day 4am, 8am, Noon, 4pm, 8pm, Midnight',
  },
  {
    value: '72H 10PM - Every 3rd DAY at 10pm',
    label: '72H 10PM - Every 3rd DAY at 10pm',
  },
  {
    value: '72H 10AM - Every 3rd DAY at 10am',
    label: '72H 10AM - Every 3rd DAY at 10am',
  },
  { value: '7D - Every 7 DAYS', label: '7D - Every 7 DAYS' },
  { value: '8H - Every EIGHT hours', label: '8H - Every EIGHT hours' },
  {
    value: 'AD 10PM - Alternate days at 10pm',
    label: 'AD 10PM - Alternate days at 10pm',
  },
  {
    value: 'AD 10AM - Alternate days at 10am',
    label: 'AD 10AM - Alternate days at 10am',
  },
  {
    value: 'AXFRI - EVERY DAY AT 7am EXCEPT Friday',
    label: 'AXFRI - EVERY DAY AT 7am EXCEPT Friday',
  },
  {
    value: 'AXMON - EVERY DAY AT 7am EXCEPT Monday',
    label: 'AXMON - EVERY DAY AT 7am EXCEPT Monday',
  },
  {
    value: 'AXTUE - EVERY DAY AT 7am EXCEPT Tuesday',
    label: 'AXTUE - EVERY DAY AT 7am EXCEPT Tuesday',
  },
  {
    value: 'AXWED - EVERY DAY AT 7am EXCEPT Wednesday',
    label: 'AXWED - EVERY DAY AT 7am EXCEPT Wednesday',
  },
  {
    value: 'AXTHU - EVERY DAY AT 7am EXCEPT Thursday',
    label: 'AXTHU - EVERY DAY AT 7am EXCEPT Thursday',
  },
  {
    value: 'AXSAT - EVERY DAY AT 7am EXCEPT Saturday',
    label: 'AXSAT - EVERY DAY AT 7am EXCEPT Saturday',
  },
  {
    value: 'AXSUN - EVERY DAY AT 7am EXCEPT Sunday',
    label: 'AXSUN - EVERY DAY AT 7am EXCEPT Sunday',
  },
  { value: 'CONTINOUS - Over 24 hours', label: 'CONTINOUS - Over 24 hours' },
  {
    value: 'FRMOON - TWICE Weeekly Friday Monday at NIGHT',
    label: 'FRMOON - TWICE Weeekly Friday Monday at NIGHT',
  },
  {
    value: 'G21/S7 - ONCE daily for 21 days then miss 7 days',
    label: 'G21/S7 - ONCE daily for 21 days then miss 7 days',
  },
  { value: 'MONTH - Every Month', label: 'MONTH - Every Month' },
  {
    value: 'WF - ONCE weekly - Friday at 7am',
    label: 'WF - ONCE weekly - Friday at 7am',
  },
  {
    value: 'WM - ONCE weekly - Monday at 7am',
    label: 'WM - ONCE weekly - Monday at 7am',
  },
  {
    value: 'WT - ONCE weekly - Tuesday at 7am',
    label: 'WT - ONCE weekly - Tuesday at 7am',
  },
  {
    value: 'WW - ONCE weekly - Wednesday at 7am',
    label: 'WW - ONCE weekly - Wednesday at 7am',
  },
  {
    value: 'WTH - ONCE weekly - Thursday at 7am',
    label: 'WTH - ONCE weekly - Thursday at 7am',
  },
  {
    value: 'WS - ONCE weekly - Saturday at 7am',
    label: 'WS - ONCE weekly - Saturday at 7am',
  },
  {
    value: 'WSU- ONCE weekly - Sunday at 7am',
    label: 'WSU- ONCE weekly - Sunday at 7am',
  },
  {
    value: 'WWPM - Every Wednesday at 10pm',
    label: 'WWPM - Every Wednesday at 10pm',
  },
  {
    value: '72H 10PM - Every 3rd DAY at 10pm',
    label: '72H 10PM - Every 3rd DAY at 10pm',
  },
  {
    value: '72H 10AM - Every 3rd DAY at 10am',
    label: '72H 10AM - Every 3rd DAY at 10am',
  },
  { value: '7D - Every 7 DAYS', label: '7D - Every 7 DAYS' },
  { value: '8H - Every EIGHT hours', label: '8H - Every EIGHT hours' },
  {
    value: 'AD 10PM - Alternate days at 10pm',
    label: 'AD 10PM - Alternate days at 10pm',
  },
  {
    value: 'AD 10AM - Alternate days at 10am',
    label: 'AD 10AM - Alternate days at 10am',
  },
  {
    value: 'AXFRI - EVERY DAY AT 7am EXCEPT Friday',
    label: 'AXFRI - EVERY DAY AT 7am EXCEPT Friday',
  },
  {
    value: 'AXMON - EVERY DAY AT 7am EXCEPT Monday',
    label: 'AXMON - EVERY DAY AT 7am EXCEPT Monday',
  },
  {
    value: 'AXTUE - EVERY DAY AT 7am EXCEPT Tuesday',
    label: 'AXTUE - EVERY DAY AT 7am EXCEPT Tuesday',
  },
  {
    value: 'AXWED - EVERY DAY AT 7am EXCEPT Wednesday',
    label: 'AXWED - EVERY DAY AT 7am EXCEPT Wednesday',
  },
  {
    value: 'AXTHU - EVERY DAY AT 7am EXCEPT Thursday',
    label: 'AXTHU - EVERY DAY AT 7am EXCEPT Thursday',
  },
  {
    value: 'AXSAT - EVERY DAY AT 7am EXCEPT Saturday',
    label: 'AXSAT - EVERY DAY AT 7am EXCEPT Saturday',
  },
  {
    value: 'AXSUN - EVERY DAY AT 7am EXCEPT Sunday',
    label: 'AXSUN - EVERY DAY AT 7am EXCEPT Sunday',
  },
  { value: 'CONTINOUS - Over 24 hours', label: 'CONTINOUS - Over 24 hours' },
  {
    value: 'FRMOON - TWICE Weeekly Friday Monday at NIGHT',
    label: 'FRMOON - TWICE Weeekly Friday Monday at NIGHT',
  },
  {
    value: 'G21/S7 - ONCE daily for 21 days then miss 7 days',
    label: 'G21/S7 - ONCE daily for 21 days then miss 7 days',
  },
  { value: 'MONTH - Every Month', label: 'MONTH - Every Month' },
  {
    value: 'WF - ONCE weekly - Friday at 7am',
    label: 'WF - ONCE weekly - Friday at 7am',
  },
  {
    value: 'WM - ONCE weekly - Monday at 7am',
    label: 'WM - ONCE weekly - Monday at 7am',
  },
  {
    value: 'WT - ONCE weekly - Tuesday at 7am',
    label: 'WT - ONCE weekly - Tuesday at 7am',
  },
  {
    value: 'WW - ONCE weekly - Wednesday at 7am',
    label: 'WW - ONCE weekly - Wednesday at 7am',
  },
  {
    value: 'WTH - ONCE weekly - Thursday at 7am',
    label: 'WTH - ONCE weekly - Thursday at 7am',
  },
  {
    value: 'WS - ONCE weekly - Saturday at 7am',
    label: 'WS - ONCE weekly - Saturday at 7am',
  },
  {
    value: 'WSU- ONCE weekly - Sunday at 7am',
    label: 'WSU- ONCE weekly - Sunday at 7am',
  },
  {
    value: 'WWPM - Every Wednesday at 10pm',
    label: 'WWPM - Every Wednesday at 10pm',
  },
];

export const drugMode = [
  { value: 'Inhalation', label: 'Inhalation' },
  { value: 'Oral', label: 'Oral' },
  { value: 'Intramuscular', label: 'Intramuscular' },
  { value: 'Intravenous', label: 'Intravenous' },
  { value: 'Subcutaenous', label: 'Subcutaenous' },
  { value: 'Intradermal', label: 'Intradermal' },
  { value: 'Topical', label: 'Topical' },
  { value: 'Intrarticular', label: 'Intrarticular' },
  { value: 'Intraosseous', label: 'Intraosseous' },
  { value: 'Intralessional', label: 'Intralessional' },
  { value: 'Intrathecal', label: 'Intrathecal' },
  { value: 'Intraperitoneal', label: 'Intraperitoneal' },
  { value: 'Sublingual', label: 'Sublingual' },
  { value: 'Vaginal', label: 'Vaginal' },
  { value: 'Rectal', label: 'Rectal' },
  { value: 'Ophthalmic', label: 'Ophthalmic' },
];

export const drugUnitOptions = [
  { value: '', label: 'Unit' },
  { value: 'mg', label: 'mg' },
  { value: 'g', label: 'g' },
  { value: 'kg', label: 'kg' },
  { value: 'ml', label: 'ml' },
  { value: 'L', label: 'L' },
  { value: 'cl', label: 'cl' },
  { value: 'spoon', label: 'spoon' },
  { value: 'mcg', label: 'mcg' },
  { value: '%', label: '%' },
  { value: 'ug', label: 'ug' },
  { value: 'mL', label: 'mL' },
  { value: 'IU', label: 'IU' },
  { value: 'Vector genomes', label: 'Vector genomes' },
  { value: 'units', label: 'units' },
  { value: 'mg per mL', label: 'mg per mL' },
];

export const drugTypeOptions = [
  { value: '', label: 'Select Drug Type' },
  { value: 'Regular', label: 'Regular' },
  { value: 'Prescription', label: 'Prescription' },
];

export const drugGenericNameOptions = [
  { value: '', label: 'Select Drug Generic Name' },
  { value: 'Abilify', label: 'Abilify' },
  { value: 'Acetaminophen', label: 'Acetaminophen' },
  {
    value: 'Acetaminophen and Hydrocodone',
    label: 'Acetaminophen and Hydrocodone',
  },
  { value: 'Actos', label: 'Actos' },
  { value: 'Acyclovir', label: 'Acyclovir' },
  { value: 'Adalimumab', label: 'Adalimumab' },
  { value: 'Adapalene', label: 'Adapalene' },
  { value: 'Adderall', label: 'Adderall' },
  { value: 'Advair Diskus', label: 'Advair Diskus' },
  { value: 'Advil', label: 'Advil' },
  { value: 'Albuterol', label: 'Albuterol' },
  {
    value: 'Albuterol Inhalation Solution',
    label: 'Albuterol Inhalation Solution',
  },
  { value: 'Albuterol Sulfate', label: 'Albuterol Sulfate' },
  {
    value: 'Alclometasone Dipropionate Cream',
    label: 'Alclometasone Dipropionate Cream',
  },
  { value: 'Aldactone', label: 'Aldactone' },
  { value: 'Alendronate', label: 'Alendronate' },
  { value: 'Aleve', label: 'Aleve' },
  { value: 'Alfuzosin', label: 'Alfuzosin' },
  { value: 'Alfuzosin Hcl', label: 'Alfuzosin Hcl' },
  { value: 'Alitretinoin', label: 'Alitretinoin' },
  { value: 'Allopurinol', label: 'Allopurinol' },
  { value: 'Alprazolam', label: 'Alprazolam' },
  { value: 'Altretamine', label: 'Altretamine' },
  { value: 'Amantadine', label: 'Amantadine' },
  { value: 'Ambien', label: 'Ambien' },
  { value: 'Amiodarone', label: 'Amiodarone' },
  { value: 'Amitiza', label: 'Amitiza' },
  { value: 'Amitriptyline', label: 'Amitriptyline' },
  { value: 'Amlodipine', label: 'Amlodipine' },
  { value: 'Amlodipine Besylate', label: 'Amlodipine Besylate' },
  {
    value: 'Amlodipine Hydrochlorothiazide',
    label: 'Amlodipine Hydrochlorothiazide',
  },
  { value: 'Amlodipine Valsartan', label: 'Amlodipine Valsartan' },
  { value: 'Amodiaquine', label: 'Amodiaquine' },
  { value: 'Amoxapine ', label: 'Amoxapine ' },
  { value: 'Amoxicillin', label: 'Amoxicillin' },
  {
    value: 'Amoxicillin and Clavulanate',
    label: 'Amoxicillin and Clavulanate',
  },
  { value: 'Amphetamine', label: 'Amphetamine' },
  { value: 'Anastrozole', label: 'Anastrozole' },
  { value: 'Anoro Ellipta', label: 'Anoro Ellipta' },
  {
    value: 'Antihypertensive Combinations',
    label: 'Antihypertensive Combinations',
  },
  { value: 'Antimalarial', label: 'Antimalarial' },
  { value: 'Apixaban', label: 'Apixaban' },
  { value: 'Aricept', label: 'Aricept' },
  { value: 'Aripiprazole', label: 'Aripiprazole' },
  { value: 'Artemether', label: 'Artemether' },
  { value: 'Artemisinin', label: 'Artemisinin' },
  { value: 'Artesunate', label: 'Artesunate' },
  { value: 'Artificial Tears  ', label: 'Artificial Tears  ' },
  { value: 'Ascorbic acid', label: 'Ascorbic acid' },
  { value: 'Aspir 81', label: 'Aspir 81' },
  { value: 'Aspirin', label: 'Aspirin' },
  { value: 'Aspirin Ec  ', label: 'Aspirin Ec  ' },
  { value: 'Atarax', label: 'Atarax' },
  { value: 'Atenolol', label: 'Atenolol' },
  { value: 'Atenolol/Chlorthalidone ', label: 'Atenolol/Chlorthalidone ' },
  { value: 'Ativan', label: 'Ativan' },
  { value: 'Atomoxetine', label: 'Atomoxetine' },
  { value: 'Atorvastatin', label: 'Atorvastatin' },
  { value: 'Atorvastatin Calcium', label: 'Atorvastatin Calcium' },
  { value: 'Atovaquone/Proguanil ', label: 'Atovaquone/Proguanil ' },
  { value: 'Atropine', label: 'Atropine' },
  { value: 'Atropine Sulfate', label: 'Atropine Sulfate' },
  {
    value: 'Augmented Betamethasone Dipropionate',
    label: 'Augmented Betamethasone Dipropionate',
  },
  { value: 'Augmentin', label: 'Augmentin' },
  { value: 'Azathioprine', label: 'Azathioprine' },
  { value: 'Azelastine', label: 'Azelastine' },
  { value: 'Azelastine Nasal Spray', label: 'Azelastine Nasal Spray' },
  { value: 'Azithromycin', label: 'Azithromycin' },
  { value: 'Bacitracin', label: 'Bacitracin' },
  { value: 'Baclofen', label: 'Baclofen' },
  { value: 'Bactrim', label: 'Bactrim' },
  { value: 'Bactrim DS', label: 'Bactrim DS' },
  { value: 'Bactroban', label: 'Bactroban' },
  { value: 'Basaglar', label: 'Basaglar' },
  { value: 'Belbuca', label: 'Belbuca' },
  { value: 'Belladonna Alkaloids ', label: 'Belladonna Alkaloids ' },
  { value: 'Belsomra', label: 'Belsomra' },
  { value: 'Benadryl', label: 'Benadryl' },
  { value: 'Benazepril', label: 'Benazepril' },
  { value: 'Benazepril Hctz ', label: 'Benazepril Hctz ' },
  { value: 'Benicar', label: 'Benicar' },
  { value: 'Bentyl', label: 'Bentyl' },
  { value: 'Benzonatate', label: 'Benzonatate' },
  { value: 'Benztropine', label: 'Benztropine' },
  { value: 'Betamethasone', label: 'Betamethasone' },
  { value: 'Bethanechol ', label: 'Bethanechol ' },
  { value: 'Biaxin', label: 'Biaxin' },
  { value: 'Bicalutamide', label: 'Bicalutamide' },
  { value: 'Biktarvy', label: 'Biktarvy' },
  { value: 'Bimatoprost', label: 'Bimatoprost' },
  { value: 'Biotin', label: 'Biotin' },
  { value: 'Bisacodyl', label: 'Bisacodyl' },
  { value: 'Bismuth subsalicylate', label: 'Bismuth subsalicylate' },
  { value: 'Bisoprolol', label: 'Bisoprolol' },
  { value: 'Bisoprolol/Hctz ', label: 'Bisoprolol/Hctz ' },
  { value: 'Boniva', label: 'Boniva' },
  { value: 'Botox', label: 'Botox' },
  { value: 'Breo Ellipta', label: 'Breo Ellipta' },
  { value: 'Brilinta', label: 'Brilinta' },
  { value: 'Brimonidine', label: 'Brimonidine' },
  { value: 'Brimonidine Tartrate', label: 'Brimonidine Tartrate' },
  { value: 'Bromocriptine', label: 'Bromocriptine' },
  { value: 'Budesonide', label: 'Budesonide' },
  { value: 'Budesonide and Formoterol', label: 'Budesonide and Formoterol' },
  {
    value: 'Budesonide Inhalation Suspension',
    label: 'Budesonide Inhalation Suspension',
  },
  { value: 'Bumetanide', label: 'Bumetanide' },
  { value: 'Bumex', label: 'Bumex' },
  { value: 'Buprenorphine', label: 'Buprenorphine' },
  { value: 'Buprenorphine and Naloxone', label: 'Buprenorphine and Naloxone' },
  { value: 'Bupropion', label: 'Bupropion' },
  { value: 'Bupropion Hcl ', label: 'Bupropion Hcl ' },
  { value: 'Buspar', label: 'Buspar' },
  { value: 'Buspirone', label: 'Buspirone' },
  { value: 'Buspirone ', label: 'Buspirone ' },
  { value: 'Butalbital', label: 'Butalbital' },
  { value: 'Butalbital Compound', label: 'Butalbital Compound' },
  { value: 'Bydureon', label: 'Bydureon' },
  { value: 'Bystolic', label: 'Bystolic' },
  { value: 'Calcitonin-Salmon ', label: 'Calcitonin-Salmon ' },
  { value: 'Calcitriol', label: 'Calcitriol' },
  { value: 'Calcitriol ', label: 'Calcitriol ' },
  { value: 'Calcium Acetate', label: 'Calcium Acetate' },
  { value: 'Calcium carbonate', label: 'Calcium carbonate' },
  { value: 'Canagliflozin', label: 'Canagliflozin' },
  {
    value: 'Candesartan Cilexetil-Hydrochlorothiazide',
    label: 'Candesartan Cilexetil-Hydrochlorothiazide',
  },
  { value: 'Candesartan Cilextil', label: 'Candesartan Cilextil' },
  { value: 'Capsaicin Cream, 0.035% ', label: 'Capsaicin Cream, 0.035% ' },
  { value: 'Captopril', label: 'Captopril' },
  { value: 'Carafate', label: 'Carafate' },
  { value: 'Carbamazepine', label: 'Carbamazepine' },
  { value: 'Carbidopa and levodopa', label: 'Carbidopa and levodopa' },
  { value: 'Carbidopa/Levo ', label: 'Carbidopa/Levo ' },
  { value: 'Carbidopa/Levo Sr ', label: 'Carbidopa/Levo Sr ' },
  { value: 'Cardizem', label: 'Cardizem' },
  { value: 'Carisoprodol', label: 'Carisoprodol' },
  { value: 'Carvedilol', label: 'Carvedilol' },
  { value: 'Cefdinir', label: 'Cefdinir' },
  { value: 'Ceftriaxone', label: 'Ceftriaxone' },
  { value: 'Cefuroxime', label: 'Cefuroxime' },
  { value: 'Celebrex', label: 'Celebrex' },
  { value: 'Celecoxib', label: 'Celecoxib' },
  { value: 'Celexa', label: 'Celexa' },
  { value: 'Cephalexin', label: 'Cephalexin' },
  { value: 'Cetirizine', label: 'Cetirizine' },
  { value: 'Cetirizine Hcl  - Otc', label: 'Cetirizine Hcl  - Otc' },
  { value: 'Cevimeline Hcl', label: 'Cevimeline Hcl' },
  { value: 'Chlordiazepoxide ', label: 'Chlordiazepoxide ' },
  { value: 'Chloroquine', label: 'Chloroquine' },
  { value: 'Chlorpheniramine ', label: 'Chlorpheniramine ' },
  { value: 'Chlorpromazine Hcl', label: 'Chlorpromazine Hcl' },
  { value: 'Chlorthalidone', label: 'Chlorthalidone' },
  { value: 'Cholecalciferol', label: 'Cholecalciferol' },
  { value: 'Cholestyramine', label: 'Cholestyramine' },
  { value: 'Cialis', label: 'Cialis' },
  { value: 'Cilostazol', label: 'Cilostazol' },
  { value: 'Cinacalcet', label: 'Cinacalcet' },
  { value: 'Cipro', label: 'Cipro' },
  { value: 'Ciprofloxacin', label: 'Ciprofloxacin' },
  { value: 'Citalopram', label: 'Citalopram' },
  { value: 'Clarithromycin', label: 'Clarithromycin' },
  { value: 'Claritin', label: 'Claritin' },
  { value: 'Clavulanate ', label: 'Clavulanate ' },
  { value: 'Clindamycin', label: 'Clindamycin' },
  { value: 'Clindamycin Phosphate', label: 'Clindamycin Phosphate' },
  { value: 'Clobetasol Topical', label: 'Clobetasol Topical' },
  { value: 'Clonazepam', label: 'Clonazepam' },
  { value: 'Clonidine', label: 'Clonidine' },
  { value: 'Clonidine Hcl ', label: 'Clonidine Hcl ' },
  { value: 'Clonidine Patch', label: 'Clonidine Patch' },
  { value: 'Clopidogrel', label: 'Clopidogrel' },
  { value: 'Clopidogrel Bisulfate ', label: 'Clopidogrel Bisulfate ' },
  { value: 'Clotrimazole', label: 'Clotrimazole' },
  {
    value: 'Clotrimazole/Betamethasone Cream',
    label: 'Clotrimazole/Betamethasone Cream',
  },
  { value: 'Coartem', label: 'Coartem' },
  { value: 'Codeine', label: 'Codeine' },
  { value: 'Colace', label: 'Colace' },
  { value: 'Colchicine', label: 'Colchicine' },
  { value: 'Colesevelam', label: 'Colesevelam' },
  { value: 'Colestipol Hcl', label: 'Colestipol Hcl' },
  { value: 'Conjugated Estrogens', label: 'Conjugated Estrogens' },
  { value: 'Coreg', label: 'Coreg' },
  { value: 'Coumadin', label: 'Coumadin' },
  { value: 'Cozaar', label: 'Cozaar' },
  { value: 'Crestor', label: 'Crestor' },
  { value: 'Cyanocobalamin', label: 'Cyanocobalamin' },
  { value: 'Cyclobenzaprine', label: 'Cyclobenzaprine' },
  { value: 'Cyclosporine', label: 'Cyclosporine' },
  { value: 'Cymbalta', label: 'Cymbalta' },
  { value: 'Dabigatran', label: 'Dabigatran' },
  { value: 'Darbepoetin', label: 'Darbepoetin' },
  { value: 'Darifenacin', label: 'Darifenacin' },
  { value: 'Decadron', label: 'Decadron' },
  { value: 'Decitabine', label: 'Decitabine' },
  { value: 'Demerol', label: 'Demerol' },
  { value: 'Denosumab', label: 'Denosumab' },
  { value: 'Depakote', label: 'Depakote' },
  { value: 'Desloratadine', label: 'Desloratadine' },
  { value: 'Desmopressin', label: 'Desmopressin' },
  { value: 'Desvenlafaxine', label: 'Desvenlafaxine' },
  { value: 'Desyrel', label: 'Desyrel' },
  { value: 'Dexamethasone', label: 'Dexamethasone' },
  { value: 'Dexamethasone Intensol', label: 'Dexamethasone Intensol' },
  { value: 'Dexilant', label: 'Dexilant' },
  { value: 'Dexlansoprazole', label: 'Dexlansoprazole' },
  { value: 'Dexmethylphenidate Hcl ', label: 'Dexmethylphenidate Hcl ' },
  { value: 'Dextroamphetamine', label: 'Dextroamphetamine' },
  {
    value: 'Dextroamphetamine Amphetamine ',
    label: 'Dextroamphetamine Amphetamine ',
  },
  {
    value: 'Dextroamphetamine Amphetamine Er ',
    label: 'Dextroamphetamine Amphetamine Er ',
  },
  {
    value: 'Dextroamphetamine Sulfate Er ',
    label: 'Dextroamphetamine Sulfate Er ',
  },
  { value: 'Dextromethorphan', label: 'Dextromethorphan' },
  { value: 'Dextrose', label: 'Dextrose' },
  { value: 'Diazepam', label: 'Diazepam' },
  { value: 'Diclofenac', label: 'Diclofenac' },
  { value: 'Diclofenac Ec ', label: 'Diclofenac Ec ' },
  { value: 'Diclofenac Er ', label: 'Diclofenac Er ' },
  { value: 'Dicyclomine', label: 'Dicyclomine' },
  { value: 'Diflucan', label: 'Diflucan' },
  { value: 'Digoxin', label: 'Digoxin' },
  { value: 'Digoxin S', label: 'Digoxin S' },
  { value: 'Dihydroartemisinin', label: 'Dihydroartemisinin' },
  { value: 'Dilantin', label: 'Dilantin' },
  { value: 'Dilaudid', label: 'Dilaudid' },
  { value: 'Diltiazem', label: 'Diltiazem' },
  { value: 'Diltiazem Er ', label: 'Diltiazem Er ' },
  { value: 'Diltiazem Hcl Cd', label: 'Diltiazem Hcl Cd' },
  { value: 'Diovan', label: 'Diovan' },
  { value: 'Diphenhydramine', label: 'Diphenhydramine' },
  { value: 'Diphenoxylate', label: 'Diphenoxylate' },
  {
    value: 'Diphenoxylate Hydrochloride/Atropine Sulfate',
    label: 'Diphenoxylate Hydrochloride/Atropine Sulfate',
  },
  { value: 'Diphenoxylate/Atropine ', label: 'Diphenoxylate/Atropine ' },
  { value: 'Dipyridamole ', label: 'Dipyridamole ' },
  { value: 'Ditropan', label: 'Ditropan' },
  { value: 'Divalproex', label: 'Divalproex' },
  { value: 'Divalproex Dr ', label: 'Divalproex Dr ' },
  { value: 'Divalproex sodium', label: 'Divalproex sodium' },
  { value: 'Docusate', label: 'Docusate' },
  { value: 'Docusate Sodium', label: 'Docusate Sodium' },
  { value: 'Donepezil', label: 'Donepezil' },
  { value: 'Donepezil Hci', label: 'Donepezil Hci' },
  { value: 'Dorzolamide', label: 'Dorzolamide' },
  { value: 'Dorzolamide Hcl', label: 'Dorzolamide Hcl' },
  { value: 'Dorzolamide ophthalmic', label: 'Dorzolamide ophthalmic' },
  { value: 'Doxazosin', label: 'Doxazosin' },
  { value: 'Doxazosin Mesylate', label: 'Doxazosin Mesylate' },
  { value: 'Doxepin', label: 'Doxepin' },
  { value: 'Doxycycline', label: 'Doxycycline' },
  { value: 'Drospirenone', label: 'Drospirenone' },
  { value: 'Dulcolax', label: 'Dulcolax' },
  { value: 'Dulera', label: 'Dulera' },
  { value: 'Duloxetine', label: 'Duloxetine' },
  { value: 'DuoNeb', label: 'DuoNeb' },
  { value: 'Dupixent', label: 'Dupixent' },
  { value: 'Dutasteride', label: 'Dutasteride' },
  { value: 'Dyazide', label: 'Dyazide' },
  { value: 'Echinacea', label: 'Echinacea' },
  { value: 'Ecotrin', label: 'Ecotrin' },
  { value: 'Efavirenz', label: 'Efavirenz' },
  { value: 'Effexor', label: 'Effexor' },
  { value: 'Effexor XR', label: 'Effexor XR' },
  { value: 'Effient', label: 'Effient' },
  { value: 'Elavil', label: 'Elavil' },
  { value: 'Eletriptan', label: 'Eletriptan' },
  { value: 'Eligard', label: 'Eligard' },
  { value: 'Eliquis', label: 'Eliquis' },
  { value: 'Elmiron', label: 'Elmiron' },
  { value: 'Emgality', label: 'Emgality' },
  { value: 'Empagliflozin', label: 'Empagliflozin' },
  { value: 'Emtricitabine', label: 'Emtricitabine' },
  { value: 'Enalapril', label: 'Enalapril' },
  { value: 'Enalapril Maleate ', label: 'Enalapril Maleate ' },
  {
    value: 'Enalapril Maleate-Hydrochlorothiazide S',
    label: 'Enalapril Maleate-Hydrochlorothiazide S',
  },
  { value: 'Enbrel', label: 'Enbrel' },
  { value: 'Enoxaparin', label: 'Enoxaparin' },
  { value: 'Entresto', label: 'Entresto' },
  { value: 'Entyvio', label: 'Entyvio' },
  { value: 'Epclusa', label: 'Epclusa' },
  { value: 'Epinephrine', label: 'Epinephrine' },
  { value: 'Epinephrine Auto Injector', label: 'Epinephrine Auto Injector' },
  { value: 'EpiPen', label: 'EpiPen' },
  { value: 'Eplerenone', label: 'Eplerenone' },
  { value: 'Epoetin alfa', label: 'Epoetin alfa' },
  { value: 'Ergocalciferol', label: 'Ergocalciferol' },
  { value: 'Ertapenem', label: 'Ertapenem' },
  { value: 'Erythromycin', label: 'Erythromycin' },
  { value: 'Escitalopram', label: 'Escitalopram' },
  { value: 'Escitalopram Oxalate', label: 'Escitalopram Oxalate' },
  { value: 'Esomeprazole', label: 'Esomeprazole' },
  { value: 'Esomeprazole Dr ', label: 'Esomeprazole Dr ' },
  { value: 'Estrace', label: 'Estrace' },
  { value: 'Estradiol', label: 'Estradiol' },
  { value: 'Estradiol ', label: 'Estradiol ' },
  { value: 'Estropipate ', label: 'Estropipate ' },
  { value: 'Eszopiclone', label: 'Eszopiclone' },
  { value: 'Etanercept', label: 'Etanercept' },
  { value: 'Ethambutol', label: 'Ethambutol' },
  { value: 'Ethinyl Estradiol ', label: 'Ethinyl Estradiol ' },
  { value: 'Etodolac', label: 'Etodolac' },
  { value: 'Etonogestrel', label: 'Etonogestrel' },
  { value: 'Eucrisa', label: 'Eucrisa' },
  { value: 'Euthyrox', label: 'Euthyrox' },
  { value: 'Evista', label: 'Evista' },
  { value: 'Excedrin', label: 'Excedrin' },
  { value: 'Exelon', label: 'Exelon' },
  { value: 'Exemestane', label: 'Exemestane' },
  { value: 'Exenatide', label: 'Exenatide' },
  { value: 'Ezetimibe', label: 'Ezetimibe' },
  { value: 'Famciclovir', label: 'Famciclovir' },
  { value: 'Famotidine', label: 'Famotidine' },
  { value: 'Farxiga', label: 'Farxiga' },
  { value: 'Febuxostat', label: 'Febuxostat' },
  { value: 'Felodipine', label: 'Felodipine' },
  { value: 'Felodipine Er ', label: 'Felodipine Er ' },
  { value: 'Femara', label: 'Femara' },
  { value: 'Fenofibrate', label: 'Fenofibrate' },
  { value: 'Fenofibrate, Micronized ', label: 'Fenofibrate, Micronized ' },
  { value: 'Fenofibric Acid Dr ', label: 'Fenofibric Acid Dr ' },
  { value: 'Fentanyl', label: 'Fentanyl' },
  { value: 'Ferrous fumarate', label: 'Ferrous fumarate' },
  { value: 'Ferrous gluconate', label: 'Ferrous gluconate' },
  { value: 'Ferrous Sulfate', label: 'Ferrous Sulfate' },
  { value: 'Ferrous Sulfate Ec ', label: 'Ferrous Sulfate Ec ' },
  { value: 'Fexofenadine', label: 'Fexofenadine' },
  { value: 'Finasteride', label: 'Finasteride' },
  { value: 'Fioricet', label: 'Fioricet' },
  { value: 'Fish Oil', label: 'Fish Oil' },
  { value: 'Flagyl', label: 'Flagyl' },
  { value: 'Flecainide', label: 'Flecainide' },
  { value: 'Flecainide Acetate ', label: 'Flecainide Acetate ' },
  { value: 'Flexeril', label: 'Flexeril' },
  { value: 'Flomax', label: 'Flomax' },
  { value: 'Flonase', label: 'Flonase' },
  { value: 'Florastor', label: 'Florastor' },
  { value: 'Flovent', label: 'Flovent' },
  { value: 'Flovent HFA', label: 'Flovent HFA' },
  { value: 'Fluconazole', label: 'Fluconazole' },
  { value: 'Fludrocortisone', label: 'Fludrocortisone' },
  { value: 'Fluocinonide', label: 'Fluocinonide' },
  { value: 'Fluoxetine', label: 'Fluoxetine' },
  { value: 'Fluphenazine', label: 'Fluphenazine' },
  { value: 'Fluticasone', label: 'Fluticasone' },
  { value: 'Fluticasone and Salmeterol', label: 'Fluticasone and Salmeterol' },
  { value: 'Fluticasone Nasal Spray ', label: 'Fluticasone Nasal Spray ' },
  { value: 'Fluvoxamine', label: 'Fluvoxamine' },
  { value: 'Fluvoxamine Maleate ', label: 'Fluvoxamine Maleate ' },
  { value: 'Fluzone', label: 'Fluzone' },
  { value: 'Focalin', label: 'Focalin' },
  { value: 'Folic Acid', label: 'Folic Acid' },
  { value: 'Folic Acid 2.2 ', label: 'Folic Acid 2.2 ' },
  { value: 'Formoterol', label: 'Formoterol' },
  { value: 'Forteo', label: 'Forteo' },
  { value: 'Fosamax', label: 'Fosamax' },
  { value: 'Fosfomycin', label: 'Fosfomycin' },
  { value: 'Fosinopril', label: 'Fosinopril' },
  { value: 'Furosemide', label: 'Furosemide' },
  { value: 'Gabapentin', label: 'Gabapentin' },
  { value: 'Galantamine', label: 'Galantamine' },
  { value: 'Garlic', label: 'Garlic' },
  { value: 'Gas-X', label: 'Gas-X' },
  { value: 'Gaviscon', label: 'Gaviscon' },
  { value: 'Gemcitabine', label: 'Gemcitabine' },
  { value: 'Gemfibrozil', label: 'Gemfibrozil' },
  { value: 'Gemzar', label: 'Gemzar' },
  { value: 'Gentamicin', label: 'Gentamicin' },
  { value: 'Genvoya', label: 'Genvoya' },
  { value: 'Geodon', label: 'Geodon' },
  { value: 'Gilenya', label: 'Gilenya' },
  { value: 'Ginger', label: 'Ginger' },
  { value: 'Ginkgo', label: 'Ginkgo' },
  { value: 'Ginkgo Biloba', label: 'Ginkgo Biloba' },
  { value: 'Ginseng', label: 'Ginseng' },
  { value: 'Glatiramer', label: 'Glatiramer' },
  { value: 'Gleevec', label: 'Gleevec' },
  { value: 'Glimepiride', label: 'Glimepiride' },
  { value: 'Glipizide', label: 'Glipizide' },
  { value: 'Glipizide and metformin', label: 'Glipizide and metformin' },
  { value: 'Glipizide Extended Release', label: 'Glipizide Extended Release' },
  { value: 'Glucagon', label: 'Glucagon' },
  { value: 'Glucophage', label: 'Glucophage' },
  { value: 'Glucophage XR', label: 'Glucophage XR' },
  { value: 'Glucosamine', label: 'Glucosamine' },
  {
    value: 'Glucosamine & Chondroitin with MSM',
    label: 'Glucosamine & Chondroitin with MSM',
  },
  { value: 'Glucotrol', label: 'Glucotrol' },
  { value: 'Glyburide', label: 'Glyburide' },
  { value: 'Glyburide/Metformin ', label: 'Glyburide/Metformin ' },
  { value: 'Glycerin', label: 'Glycerin' },
  { value: 'GlycoLax', label: 'GlycoLax' },
  { value: 'Glycopyrrolate', label: 'Glycopyrrolate' },
  { value: 'Glyxambi', label: 'Glyxambi' },
  { value: 'GoLYTELY', label: 'GoLYTELY' },
  { value: 'Gralise', label: 'Gralise' },
  { value: 'Granisetron', label: 'Granisetron' },
  { value: 'Grapefruit', label: 'Grapefruit' },
  { value: 'Green tea', label: 'Green tea' },
  { value: 'Griseofulvin', label: 'Griseofulvin' },
  { value: 'Guaifenesin', label: 'Guaifenesin' },
  { value: 'Guanfacine', label: 'Guanfacine' },
  { value: 'Halcion', label: 'Halcion' },
  { value: 'Haldol', label: 'Haldol' },
  {
    value: 'Halobetasol Prop Ointment, 0.05%',
    label: 'Halobetasol Prop Ointment, 0.05%',
  },
  { value: 'Halobetasol topical', label: 'Halobetasol topical' },
  { value: 'Halofantrine', label: 'Halofantrine' },
  { value: 'Haloperidol', label: 'Haloperidol' },
  { value: 'Harvoni', label: 'Harvoni' },
  { value: 'Heparin', label: 'Heparin' },
  { value: 'Hepatitis B adult vaccine', label: 'Hepatitis B adult vaccine' },
  { value: 'Herceptin', label: 'Herceptin' },
  { value: 'Hiprex', label: 'Hiprex' },
  { value: 'Hizentra', label: 'Hizentra' },
  { value: 'Horizant', label: 'Horizant' },
  { value: 'Humalog', label: 'Humalog' },
  { value: 'Humira', label: 'Humira' },
  { value: 'Humulin 70/30', label: 'Humulin 70/30' },
  { value: 'Humulin N', label: 'Humulin N' },
  { value: 'Humulin R', label: 'Humulin R' },
  { value: 'Hycodan', label: 'Hycodan' },
  { value: 'Hydralazine', label: 'Hydralazine' },
  { value: 'Hydrea', label: 'Hydrea' },
  { value: 'Hydrochlorothiazide', label: 'Hydrochlorothiazide' },
  {
    value: 'Hydrochlorothiazide and irbesartan',
    label: 'Hydrochlorothiazide and irbesartan',
  },
  {
    value: 'Hydrochlorothiazide and lisinopril',
    label: 'Hydrochlorothiazide and lisinopril',
  },
  {
    value: 'Hydrochlorothiazide and losartan',
    label: 'Hydrochlorothiazide and losartan',
  },
  {
    value: 'Hydrochlorothiazide and triamterene',
    label: 'Hydrochlorothiazide and triamterene',
  },
  {
    value: 'Hydrochlorothiazide and valsartan',
    label: 'Hydrochlorothiazide and valsartan',
  },
  { value: 'Hydrocodone', label: 'Hydrocodone' },
  {
    value: 'Hydrocodone and acetaminophen',
    label: 'Hydrocodone and acetaminophen',
  },
  { value: 'Hydrocortisone', label: 'Hydrocortisone' },
  { value: 'Hydrocortisone topical', label: 'Hydrocortisone topical' },
  { value: 'Hydromet', label: 'Hydromet' },
  { value: 'Hydromorphone', label: 'Hydromorphone' },
  { value: 'Hydroquinone topical', label: 'Hydroquinone topical' },
  { value: 'Hydroxychloroquine', label: 'Hydroxychloroquine' },
  { value: 'Hydroxyurea', label: 'Hydroxyurea' },
  { value: 'Hydroxyzine', label: 'Hydroxyzine' },
  { value: 'Hydroxyzine Hcl ', label: 'Hydroxyzine Hcl ' },
  { value: 'Hydroxyzine Pamoate', label: 'Hydroxyzine Pamoate' },
  { value: 'Hyoscyamine', label: 'Hyoscyamine' },
  { value: 'Hyoscyamine Sulfate', label: 'Hyoscyamine Sulfate' },
  { value: 'Hysingla ER', label: 'Hysingla ER' },
  { value: 'Hytrin', label: 'Hytrin' },
  { value: 'Hyzaar', label: 'Hyzaar' },
  { value: 'Ibandronate', label: 'Ibandronate' },
  { value: 'Ibrance', label: 'Ibrance' },
  { value: 'Ibrutinib', label: 'Ibrutinib' },
  { value: 'Ibu', label: 'Ibu' },
  { value: 'Ibuprofen', label: 'Ibuprofen' },
  { value: 'Imatinib', label: 'Imatinib' },
  { value: 'Imbruvica', label: 'Imbruvica' },
  { value: 'Imdur', label: 'Imdur' },
  { value: 'Imipramine', label: 'Imipramine' },
  { value: 'Imiquimod', label: 'Imiquimod' },
  { value: 'Imitrex', label: 'Imitrex' },
  { value: 'Imodium', label: 'Imodium' },
  { value: 'Imodium A-D', label: 'Imodium A-D' },
  { value: 'Imuran', label: 'Imuran' },
  { value: 'Incruse Ellipta', label: 'Incruse Ellipta' },
  { value: 'Indapamide', label: 'Indapamide' },
  { value: 'Inderal', label: 'Inderal' },
  { value: 'Indocin', label: 'Indocin' },
  { value: 'Indomethacin', label: 'Indomethacin' },
  { value: 'Infliximab', label: 'Infliximab' },
  { value: 'Ingrezza', label: 'Ingrezza' },
  { value: 'Insulin', label: 'Insulin' },
  { value: 'Insulin aspart', label: 'Insulin aspart' },
  { value: 'Insulin glargine', label: 'Insulin glargine' },
  { value: 'Insulin Lispro', label: 'Insulin Lispro' },
  { value: 'Insulin regular', label: 'Insulin regular' },
  { value: 'InsulinDetemir', label: 'InsulinDetemir' },
  { value: 'InsulinNph', label: 'InsulinNph' },
  { value: 'Intuniv', label: 'Intuniv' },
  { value: 'Invega', label: 'Invega' },
  { value: 'Invega Sustenna', label: 'Invega Sustenna' },
  { value: 'Invokana', label: 'Invokana' },
  { value: 'Ipratropium', label: 'Ipratropium' },
  {
    value: 'Ipratropium/Albuterol Inhalation Solution',
    label: 'Ipratropium/Albuterol Inhalation Solution',
  },
  { value: 'Irbesartan', label: 'Irbesartan' },
  {
    value: 'Irbesartan-Hydrochlorothiazide',
    label: 'Irbesartan-Hydrochlorothiazide',
  },
  { value: 'Isoniazid', label: 'Isoniazid' },
  { value: 'Isoniazid ', label: 'Isoniazid ' },
  { value: 'Isosorbide', label: 'Isosorbide' },
  { value: 'Isosorbide dinitrate', label: 'Isosorbide dinitrate' },
  { value: 'Isosorbide mononitrate', label: 'Isosorbide mononitrate' },
  { value: 'Isotretinoin', label: 'Isotretinoin' },
  { value: 'Itraconazole', label: 'Itraconazole' },
  { value: 'Ivabradine', label: 'Ivabradine' },
  { value: 'Ivermectin', label: 'Ivermectin' },
  { value: 'Jadenu', label: 'Jadenu' },
  { value: 'Jaimiess', label: 'Jaimiess' },
  { value: 'Jakafi', label: 'Jakafi' },
  { value: 'Jalyn', label: 'Jalyn' },
  { value: 'Jantoven', label: 'Jantoven' },
  { value: 'Janumet', label: 'Janumet' },
  { value: 'Janumet XR', label: 'Janumet XR' },
  { value: 'Januvia', label: 'Januvia' },
  { value: 'Jardiance', label: 'Jardiance' },
  { value: 'Jasmiel', label: 'Jasmiel' },
  { value: 'Jatenzo', label: 'Jatenzo' },
  { value: 'Jelmyto', label: 'Jelmyto' },
  { value: 'Jencycla', label: 'Jencycla' },
  { value: 'Jentadueto', label: 'Jentadueto' },
  { value: 'Jentadueto XR', label: 'Jentadueto XR' },
  { value: 'Jevtana', label: 'Jevtana' },
  { value: 'Jinteli', label: 'Jinteli' },
  { value: 'JointFlex', label: 'JointFlex' },
  { value: 'Jolessa', label: 'Jolessa' },
  { value: 'Jolivette', label: 'Jolivette' },
  { value: 'Jornay PM', label: 'Jornay PM' },
  { value: 'Jublia', label: 'Jublia' },
  { value: 'Jujube', label: 'Jujube' },
  { value: 'Juleber', label: 'Juleber' },
  { value: 'Juluca', label: 'Juluca' },
  { value: 'Junel', label: 'Junel' },
  { value: 'Junel 1.5/30', label: 'Junel 1.5/30' },
  { value: 'Junel 1/20', label: 'Junel 1/20' },
  { value: 'Junel Fe 1.5/30', label: 'Junel Fe 1.5/30' },
  { value: 'Junel Fe 1/20', label: 'Junel Fe 1/20' },
  { value: 'Junel Fe 24', label: 'Junel Fe 24' },
  { value: 'Juniper', label: 'Juniper' },
  { value: 'Juvederm', label: 'Juvederm' },
  { value: 'Juxtapid', label: 'Juxtapid' },
  { value: 'Jynarque', label: 'Jynarque' },
  { value: 'Kadcyla', label: 'Kadcyla' },
  { value: 'Kadian', label: 'Kadian' },
  { value: 'Kaletra', label: 'Kaletra' },
  { value: 'Kaopectate', label: 'Kaopectate' },
  { value: 'Kapvay', label: 'Kapvay' },
  { value: 'Kariva', label: 'Kariva' },
  { value: 'Kava', label: 'Kava' },
  { value: 'Kayexalate', label: 'Kayexalate' },
  { value: 'K-Dur', label: 'K-Dur' },
  { value: 'Keflex', label: 'Keflex' },
  { value: 'Kenalog', label: 'Kenalog' },
  { value: 'Kenalog-40', label: 'Kenalog-40' },
  { value: 'Keppra', label: 'Keppra' },
  { value: 'Keppra XR', label: 'Keppra XR' },
  { value: 'Kesimpta', label: 'Kesimpta' },
  { value: 'Ketamine', label: 'Ketamine' },
  { value: 'Ketoconazole', label: 'Ketoconazole' },
  { value: 'Ketoconazole Shampoo', label: 'Ketoconazole Shampoo' },
  { value: 'Ketoconazole topical', label: 'Ketoconazole topical' },
  { value: 'Ketoprofen', label: 'Ketoprofen' },
  { value: 'Ketorolac', label: 'Ketorolac' },
  { value: 'Ketorolac ophthalmic', label: 'Ketorolac ophthalmic' },
  { value: 'Ketorolac Tromethamine', label: 'Ketorolac Tromethamine' },
  { value: 'Ketotifen', label: 'Ketotifen' },
  {
    value: 'Ketotifen Fumerate Otc Solution',
    label: 'Ketotifen Fumerate Otc Solution',
  },
  { value: 'Ketotifen ophthalmic', label: 'Ketotifen ophthalmic' },
  { value: 'Kevzara', label: 'Kevzara' },
  { value: 'Keytruda', label: 'Keytruda' },
  { value: 'Kineret', label: 'Kineret' },
  { value: 'Kisqali', label: 'Kisqali' },
  { value: 'Klonopin', label: 'Klonopin' },
  { value: 'Klor-Con', label: 'Klor-Con' },
  { value: 'Klor-Con/EF', label: 'Klor-Con/EF' },
  { value: 'Kombiglyze XR', label: 'Kombiglyze XR' },
  { value: 'Kratom', label: 'Kratom' },
  { value: 'Krill Oil', label: 'Krill Oil' },
  { value: 'K-Tab', label: 'K-Tab' },
  { value: 'Kuvan', label: 'Kuvan' },
  { value: 'Kyleena', label: 'Kyleena' },
  { value: 'Kyprolis', label: 'Kyprolis' },
  { value: 'Kytril', label: 'Kytril' },
  { value: 'Labetalol', label: 'Labetalol' },
  { value: 'Labetalol Hcl ', label: 'Labetalol Hcl ' },
  { value: 'Lactulose', label: 'Lactulose' },
  { value: 'Lactulose Oral Solution', label: 'Lactulose Oral Solution' },
  { value: 'Lamictal', label: 'Lamictal' },
  { value: 'Lamotrigine', label: 'Lamotrigine' },
  { value: 'Lansoprazole', label: 'Lansoprazole' },
  { value: 'Lansoprazole Dr ', label: 'Lansoprazole Dr ' },
  { value: 'Lantus', label: 'Lantus' },
  { value: 'Lasix', label: 'Lasix' },
  { value: 'Latanoprost', label: 'Latanoprost' },
  { value: 'Latanoprost Solution', label: 'Latanoprost Solution' },
  { value: 'Latuda', label: 'Latuda' },
  { value: 'Leflunomide', label: 'Leflunomide' },
  { value: 'Letrozole', label: 'Letrozole' },
  { value: 'Levalbuterol', label: 'Levalbuterol' },
  { value: 'Levalbuterol Hcl', label: 'Levalbuterol Hcl' },
  { value: 'Levaquin', label: 'Levaquin' },
  { value: 'Levemir', label: 'Levemir' },
  { value: 'Levetiracetam', label: 'Levetiracetam' },
  { value: 'Levetiracetam Er', label: 'Levetiracetam Er' },
  { value: 'Levocetirizine', label: 'Levocetirizine' },
  {
    value: 'Levocetirizine Dihydrochloride',
    label: 'Levocetirizine Dihydrochloride',
  },
  { value: 'Levofloxacin', label: 'Levofloxacin' },
  { value: 'Levothyroxine', label: 'Levothyroxine' },
  { value: 'Levoxyl', label: 'Levoxyl' },
  { value: 'Lexapro', label: 'Lexapro' },
  { value: 'Lidocaine', label: 'Lidocaine' },
  {
    value: 'Lidocaine 2% Viscous Solution',
    label: 'Lidocaine 2% Viscous Solution',
  },
  { value: 'Lidocaine Patch', label: 'Lidocaine Patch' },
  { value: 'Lidocaine Patch 5%', label: 'Lidocaine Patch 5%' },
  { value: 'Lidoderm', label: 'Lidoderm' },
  { value: 'Linezolid', label: 'Linezolid' },
  { value: 'Linzess', label: 'Linzess' },
  { value: 'Liothyronine Sodium', label: 'Liothyronine Sodium' },
  { value: 'Lipitor', label: 'Lipitor' },
  { value: 'Lisdexamfetamine', label: 'Lisdexamfetamine' },
  { value: 'Lisinopril', label: 'Lisinopril' },
  { value: 'Lisinopril/Hctz ', label: 'Lisinopril/Hctz ' },
  { value: 'Lithium', label: 'Lithium' },
  { value: 'Lithium Carbonate ', label: 'Lithium Carbonate ' },
  { value: 'Lomotil', label: 'Lomotil' },
  { value: 'Loperamide', label: 'Loperamide' },
  { value: 'Lopressor', label: 'Lopressor' },
  { value: 'Loratadine', label: 'Loratadine' },
  { value: 'Loratadine Otc ', label: 'Loratadine Otc ' },
  { value: 'Lorazepam', label: 'Lorazepam' },
  { value: 'Losartan', label: 'Losartan' },
  { value: 'Losartan Potassium', label: 'Losartan Potassium' },
  { value: 'Lovastatin', label: 'Lovastatin' },
  { value: 'Lovaza', label: 'Lovaza' },
  { value: 'Lovenox', label: 'Lovenox' },
  { value: 'Loxapine ', label: 'Loxapine ' },
  { value: 'Lumefantrine', label: 'Lumefantrine' },
  { value: 'Lumigan', label: 'Lumigan' },
  { value: 'Lunesta', label: 'Lunesta' },
  { value: 'Lupron', label: 'Lupron' },
  { value: 'Lutein', label: 'Lutein' },
  { value: 'Lyrica', label: 'Lyrica' },
  { value: 'Macrobid', label: 'Macrobid' },
  { value: 'Magnesium citrate', label: 'Magnesium citrate' },
  { value: 'Magnesium oxide', label: 'Magnesium oxide' },
  { value: 'Malarone', label: 'Malarone' },
  { value: 'Meclizine', label: 'Meclizine' },
  { value: 'Meclizine Hci', label: 'Meclizine Hci' },
  { value: 'Medrol', label: 'Medrol' },
  {
    value: 'Medroxyprogesterone Acetate',
    label: 'Medroxyprogesterone Acetate',
  },
  { value: 'Mefloquine', label: 'Mefloquine' },
  { value: 'Melatonin', label: 'Melatonin' },
  { value: 'Meloxicam', label: 'Meloxicam' },
  { value: 'Memantine', label: 'Memantine' },
  { value: 'Memantine Hcl', label: 'Memantine Hcl' },
  { value: 'Meropenem', label: 'Meropenem' },
  { value: 'Mesalamine', label: 'Mesalamine' },
  { value: 'Metamucil', label: 'Metamucil' },
  { value: 'Metformin', label: 'Metformin' },
  {
    value: 'Metformin Hcl, Extended Release',
    label: 'Metformin Hcl, Extended Release',
  },
  { value: 'Methadone', label: 'Methadone' },
  { value: 'Methimazole', label: 'Methimazole' },
  { value: 'Methimazole S', label: 'Methimazole S' },
  { value: 'Methocarbamol', label: 'Methocarbamol' },
  { value: 'Methotrexate', label: 'Methotrexate' },
  { value: 'Methylphenidate', label: 'Methylphenidate' },
  { value: 'Methylphenidate Cd ', label: 'Methylphenidate Cd ' },
  { value: 'Methylphenidate Hcl', label: 'Methylphenidate Hcl' },
  { value: 'Methylprednisolone', label: 'Methylprednisolone' },
  { value: 'Metoclopramide', label: 'Metoclopramide' },
  { value: 'Metoclopramide Hcl ', label: 'Metoclopramide Hcl ' },
  { value: 'Metolazone', label: 'Metolazone' },
  { value: 'Metoprolol', label: 'Metoprolol' },
  { value: 'Metoprolol Succinate/Hctz', label: 'Metoprolol Succinate/Hctz' },
  { value: 'Metoprolol Tartrate ', label: 'Metoprolol Tartrate ' },
  { value: 'MetoprololSuccinate', label: 'MetoprololSuccinate' },
  { value: 'MetoprololTartrate', label: 'MetoprololTartrate' },
  { value: 'Metronidazole', label: 'Metronidazole' },
  { value: 'Micronized Glyburide', label: 'Micronized Glyburide' },
  { value: 'Midazolam', label: 'Midazolam' },
  { value: 'Midodrine', label: 'Midodrine' },
  { value: 'Midodrine Hcl ', label: 'Midodrine Hcl ' },
  { value: 'Minocycline ', label: 'Minocycline ' },
  { value: 'Minocycline Hcl ', label: 'Minocycline Hcl ' },
  { value: 'Mirabegron', label: 'Mirabegron' },
  { value: 'MiraLAX', label: 'MiraLAX' },
  { value: 'Mirapex', label: 'Mirapex' },
  { value: 'Mirtazapine', label: 'Mirtazapine' },
  { value: 'Misoprostol', label: 'Misoprostol' },
  { value: 'Mobic', label: 'Mobic' },
  { value: 'Modafinil', label: 'Modafinil' },
  { value: 'Mometasone', label: 'Mometasone' },
  { value: 'Mometasone Furoate', label: 'Mometasone Furoate' },
  { value: 'Montelukast', label: 'Montelukast' },
  { value: 'Montelukast Sodium', label: 'Montelukast Sodium' },
  { value: 'Morphine', label: 'Morphine' },
  { value: 'Motrin', label: 'Motrin' },
  { value: 'Moxifloxacin', label: 'Moxifloxacin' },
  { value: 'Mucinex', label: 'Mucinex' },
  { value: 'Mupirocin topical', label: 'Mupirocin topical' },
  { value: 'Mycophenolate mofetil', label: 'Mycophenolate mofetil' },
  { value: 'Mylanta', label: 'Mylanta' },
  { value: 'Myrbetriq', label: 'Myrbetriq' },
  { value: 'Nabumetone', label: 'Nabumetone' },
  { value: 'Nadolol', label: 'Nadolol' },
  { value: 'Naloxone', label: 'Naloxone' },
  { value: 'Naltrexone', label: 'Naltrexone' },
  { value: 'Namenda', label: 'Namenda' },
  { value: 'Namzaric', label: 'Namzaric' },
  { value: 'Naprosyn', label: 'Naprosyn' },
  { value: 'Naproxen', label: 'Naproxen' },
  { value: 'Naproxen Sodium ', label: 'Naproxen Sodium ' },
  { value: 'Naratriptan', label: 'Naratriptan' },
  { value: 'Narcan', label: 'Narcan' },
  { value: 'Nasacort', label: 'Nasacort' },
  { value: 'Nasonex', label: 'Nasonex' },
  { value: 'Nateglinide', label: 'Nateglinide' },
  { value: 'Nebivolol', label: 'Nebivolol' },
  { value: 'Neomycin', label: 'Neomycin' },
  { value: 'Neosporin', label: 'Neosporin' },
  { value: 'Neurontin', label: 'Neurontin' },
  { value: 'Nexium', label: 'Nexium' },
  { value: 'Nexplanon', label: 'Nexplanon' },
  { value: 'Niacin', label: 'Niacin' },
  { value: 'Niacin Extented-Release S', label: 'Niacin Extented-Release S' },
  { value: 'Niacin Sa  ', label: 'Niacin Sa  ' },
  { value: 'Niaspan', label: 'Niaspan' },
  { value: 'Nicotine', label: 'Nicotine' },
  { value: 'NicotinePatch', label: 'NicotinePatch' },
  { value: 'Nifedipine', label: 'Nifedipine' },
  { value: 'Nifedipine Cr ', label: 'Nifedipine Cr ' },
  { value: 'NifedipineXl', label: 'NifedipineXl' },
  { value: 'Nilutamide', label: 'Nilutamide' },
  { value: 'Nitrofurantoin', label: 'Nitrofurantoin' },
  { value: 'Nitroglycerin', label: 'Nitroglycerin' },
  { value: 'Nitroglycerin Sa ', label: 'Nitroglycerin Sa ' },
  { value: 'Nitrostat', label: 'Nitrostat' },
  { value: 'Nivolumab', label: 'Nivolumab' },
  { value: 'Norco', label: 'Norco' },
  { value: 'Norelgestromin', label: 'Norelgestromin' },
  { value: 'Norepinephrine', label: 'Norepinephrine' },
  { value: 'Norethindrone', label: 'Norethindrone' },
  {
    value: 'Norethindrone/Ethinyl Estradiol ',
    label: 'Norethindrone/Ethinyl Estradiol ',
  },
  { value: 'Norflex', label: 'Norflex' },
  { value: 'Norgestimate', label: 'Norgestimate' },
  { value: 'Nortriptyline', label: 'Nortriptyline' },
  { value: 'Nortriptyline Hcl ', label: 'Nortriptyline Hcl ' },
  { value: 'Norvasc', label: 'Norvasc' },
  { value: 'Novolin N', label: 'Novolin N' },
  { value: 'NovoLog', label: 'NovoLog' },
  { value: 'Nucynta', label: 'Nucynta' },
  { value: 'Nurtec ODT', label: 'Nurtec ODT' },
  { value: 'NuvaRing', label: 'NuvaRing' },
  { value: 'Nuvigil', label: 'Nuvigil' },
  { value: 'Nyamyc Topical Powder', label: 'Nyamyc Topical Powder' },
  { value: 'Nystatin', label: 'Nystatin' },
  { value: 'Ocrevus', label: 'Ocrevus' },
  { value: 'Octreotide', label: 'Octreotide' },
  { value: 'Ocuvite', label: 'Ocuvite' },
  { value: 'Ofev', label: 'Ofev' },
  { value: 'Ofloxacin', label: 'Ofloxacin' },
  { value: 'Ofloxacin ophthalmic', label: 'Ofloxacin ophthalmic' },
  { value: 'Olanzapine', label: 'Olanzapine' },
  { value: 'Olaparib', label: 'Olaparib' },
  { value: 'Olmesartan', label: 'Olmesartan' },
  { value: 'Olopatadine', label: 'Olopatadine' },
  { value: 'Olopatadine nasal', label: 'Olopatadine nasal' },
  { value: 'Olopatadine ophthalmic', label: 'Olopatadine ophthalmic' },
  { value: 'Omalizumab', label: 'Omalizumab' },
  {
    value: 'Omega-3 polyunsaturated fatty acids',
    label: 'Omega-3 polyunsaturated fatty acids',
  },
  { value: 'Omega-3-Acid Ethyl Esters', label: 'Omega-3-Acid Ethyl Esters' },
  { value: 'Omega-AcidEthylEsters', label: 'Omega-AcidEthylEsters' },
  { value: 'Omeprazole', label: 'Omeprazole' },
  { value: 'Omnicef', label: 'Omnicef' },
  { value: 'Ondansetron', label: 'Ondansetron' },
  { value: 'Ondansetron Odt ', label: 'Ondansetron Odt ' },
  { value: 'Onfi', label: 'Onfi' },
  { value: 'Onglyza', label: 'Onglyza' },
  { value: 'Opdivo', label: 'Opdivo' },
  { value: 'Opsumit', label: 'Opsumit' },
  { value: 'Orencia', label: 'Orencia' },
  { value: 'Orlistat', label: 'Orlistat' },
  { value: 'Orphenadrine', label: 'Orphenadrine' },
  { value: 'Ortho Tri-Cyclen', label: 'Ortho Tri-Cyclen' },
  { value: 'Oseltamivir', label: 'Oseltamivir' },
  { value: 'Osteo Bi-Flex', label: 'Osteo Bi-Flex' },
  { value: 'Otezla', label: 'Otezla' },
  { value: 'Oxaliplatin', label: 'Oxaliplatin' },
  { value: 'Oxandrolone ', label: 'Oxandrolone ' },
  { value: 'Oxazepam', label: 'Oxazepam' },
  { value: 'Oxcarbazepine', label: 'Oxcarbazepine' },
  { value: 'Oxybutynin', label: 'Oxybutynin' },
  { value: 'Oxycodone', label: 'Oxycodone' },
  { value: 'Oxycontin', label: 'Oxycontin' },
  { value: 'Oxygen', label: 'Oxygen' },
  { value: 'Oxymetazoline', label: 'Oxymetazoline' },
  { value: 'Oxymetazoline nasal', label: 'Oxymetazoline nasal' },
  { value: 'Oxymorphone', label: 'Oxymorphone' },
  { value: 'Oxytocin', label: 'Oxytocin' },
  { value: 'Ozempic', label: 'Ozempic' },
  { value: 'Pantoprazole', label: 'Pantoprazole' },
  { value: 'Pantoprazole Sodium', label: 'Pantoprazole Sodium' },
  { value: 'Paracetamol', label: 'Paracetamol' },
  { value: 'Paroxetine', label: 'Paroxetine' },
  { value: 'Paroxetine Hcl ', label: 'Paroxetine Hcl ' },
  { value: 'Paxil', label: 'Paxil' },
  { value: 'Peg', label: 'Peg' },
  { value: 'PegWithElectrolytes', label: 'PegWithElectrolytes' },
  { value: 'Pentoxifylline Er ', label: 'Pentoxifylline Er ' },
  { value: 'Pepcid', label: 'Pepcid' },
  { value: 'Percocet', label: 'Percocet' },
  { value: 'Perphenazine ', label: 'Perphenazine ' },
  { value: 'Phenazopyridine', label: 'Phenazopyridine' },
  { value: 'Phenergan', label: 'Phenergan' },
  { value: 'Phenobarbital', label: 'Phenobarbital' },
  { value: 'Phenoxybenzamine', label: 'Phenoxybenzamine' },
  { value: 'Phentermine', label: 'Phentermine' },
  { value: 'Phenytoin', label: 'Phenytoin' },
  { value: 'Phenytoin Sodium ', label: 'Phenytoin Sodium ' },
  { value: 'Pilocarpine', label: 'Pilocarpine' },
  { value: 'Pioglitazone', label: 'Pioglitazone' },
  { value: 'Pioglitazone Hydrochloride', label: 'Pioglitazone Hydrochloride' },
  { value: 'Piperaquine', label: 'Piperaquine' },
  { value: 'Plaquenil', label: 'Plaquenil' },
  { value: 'Plavix', label: 'Plavix' },
  {
    value: 'Pnv Prenatal Multivitamin Plus Iron',
    label: 'Pnv Prenatal Multivitamin Plus Iron',
  },
  { value: 'Polyethylene glycol 3350', label: 'Polyethylene glycol 3350' },
  { value: 'Potassium Chloride', label: 'Potassium Chloride' },
  { value: 'Potassium Chloride Er ', label: 'Potassium Chloride Er ' },
  { value: 'Potassium Iodide', label: 'Potassium Iodide' },
  { value: 'Pradaxa', label: 'Pradaxa' },
  { value: 'Pramipexole', label: 'Pramipexole' },
  {
    value: 'Pramipexole Dihydrochloride S',
    label: 'Pramipexole Dihydrochloride S',
  },
  { value: 'Prasugrel', label: 'Prasugrel' },
  { value: 'Pravachol', label: 'Pravachol' },
  { value: 'Pravastatin ', label: 'Pravastatin ' },
  { value: 'Prazosin', label: 'Prazosin' },
  { value: 'Prednisolone', label: 'Prednisolone' },
  { value: 'Prednisone', label: 'Prednisone' },
  { value: 'Pregabalin', label: 'Pregabalin' },
  { value: 'Premarin', label: 'Premarin' },
  { value: 'Prevacid', label: 'Prevacid' },
  { value: 'Prilosec', label: 'Prilosec' },
  { value: 'Primaquine', label: 'Primaquine' },
  { value: 'Primidone', label: 'Primidone' },
  { value: 'Pristiq', label: 'Pristiq' },
  { value: 'ProAir HFA', label: 'ProAir HFA' },
  { value: 'Prochlorperazine', label: 'Prochlorperazine' },
  { value: 'Progesterone ', label: 'Progesterone ' },
  { value: 'Prolia', label: 'Prolia' },
  { value: 'Promethazine', label: 'Promethazine' },
  { value: 'Propafenone Hydrochloride', label: 'Propafenone Hydrochloride' },
  { value: 'Propranolol', label: 'Propranolol' },
  { value: 'Propranolol Hcl Er', label: 'Propranolol Hcl Er' },
  { value: 'Propylthiouracil ', label: 'Propylthiouracil ' },
  { value: 'Proscar', label: 'Proscar' },
  { value: 'Protonix', label: 'Protonix' },
  { value: 'Prozac', label: 'Prozac' },
  { value: 'Pseudoephedrine', label: 'Pseudoephedrine' },
  { value: 'Psyllium', label: 'Psyllium' },
  { value: 'Pyridium', label: 'Pyridium' },
  { value: 'Pyridostigmine Bromide', label: 'Pyridostigmine Bromide' },
  { value: 'Qbrelis', label: 'Qbrelis' },
  { value: 'Qbrexza', label: 'Qbrexza' },
  { value: 'Qdolo', label: 'Qdolo' },
  { value: 'Qinlock', label: 'Qinlock' },
  { value: 'QNASL', label: 'QNASL' },
  { value: 'Q-Pap', label: 'Q-Pap' },
  { value: 'Qsymia', label: 'Qsymia' },
  { value: 'Qtern', label: 'Qtern' },
  { value: 'Qualaquin', label: 'Qualaquin' },
  { value: 'Quartette', label: 'Quartette' },
  { value: 'Quasense', label: 'Quasense' },
  { value: 'Quassia', label: 'Quassia' },
  { value: 'Quazepam', label: 'Quazepam' },
  { value: 'Qudexy XR', label: 'Qudexy XR' },
  { value: 'Quelicin', label: 'Quelicin' },
  { value: 'Quercetin', label: 'Quercetin' },
  { value: 'Questran', label: 'Questran' },
  { value: 'Quetiapine', label: 'Quetiapine' },
  { value: 'Quetiapine Fumarate', label: 'Quetiapine Fumarate' },
  {
    value: 'Quetiapine Fumarate Tablets',
    label: 'Quetiapine Fumarate Tablets',
  },
  { value: 'Quibron-T/SR', label: 'Quibron-T/SR' },
  { value: 'Quillaja', label: 'Quillaja' },
  { value: 'QuilliChew ER', label: 'QuilliChew ER' },
  { value: 'Quillivant XR', label: 'Quillivant XR' },
  { value: 'Quinamm', label: 'Quinamm' },
  { value: 'Quinapril', label: 'Quinapril' },
  { value: 'Quinapril Hci', label: 'Quinapril Hci' },
  { value: 'Quinapril/Hctz ', label: 'Quinapril/Hctz ' },
  { value: 'Quinaretic', label: 'Quinaretic' },
  { value: 'Quinidine', label: 'Quinidine' },
  { value: 'Quinine', label: 'Quinine' },
  { value: 'Qutenza', label: 'Qutenza' },
  { value: 'Quzyttir', label: 'Quzyttir' },
  { value: 'Qvar', label: 'Qvar' },
  { value: 'Qvar Redihaler', label: 'Qvar Redihaler' },
  { value: 'Rabeprazole', label: 'Rabeprazole' },
  { value: 'Rabeprazole Sodium', label: 'Rabeprazole Sodium' },
  { value: 'Raloxifene', label: 'Raloxifene' },
  { value: 'Ramelteon', label: 'Ramelteon' },
  { value: 'Ramipril', label: 'Ramipril' },
  { value: 'Ranexa', label: 'Ranexa' },
  { value: 'Ranitidine', label: 'Ranitidine' },
  { value: 'Ranolazine', label: 'Ranolazine' },
  {
    value: 'Ranolazine Extended-Release S',
    label: 'Ranolazine Extended-Release S',
  },
  { value: 'Rapaflo', label: 'Rapaflo' },
  { value: 'Rasagiline S', label: 'Rasagiline S' },
  { value: 'Reclast', label: 'Reclast' },
  { value: 'Reglan', label: 'Reglan' },
  { value: 'Relafen', label: 'Relafen' },
  { value: 'Remdesivir', label: 'Remdesivir' },
  { value: 'Remeron', label: 'Remeron' },
  { value: 'Remicade', label: 'Remicade' },
  { value: 'Renvela', label: 'Renvela' },
  { value: 'Repaglinide', label: 'Repaglinide' },
  { value: 'Repatha', label: 'Repatha' },
  { value: 'Requip', label: 'Requip' },
  { value: 'Restasis', label: 'Restasis' },
  { value: 'Restoril', label: 'Restoril' },
  { value: 'Resveratrol', label: 'Resveratrol' },
  { value: 'Revlimid', label: 'Revlimid' },
  { value: 'Rexulti', label: 'Rexulti' },
  { value: 'Rifampin', label: 'Rifampin' },
  { value: 'Rifaximin', label: 'Rifaximin' },
  { value: 'Rilpivirine ', label: 'Rilpivirine ' },
  { value: 'Risedronate', label: 'Risedronate' },
  { value: 'Risedronate Sodium ', label: 'Risedronate Sodium ' },
  { value: 'Risperdal', label: 'Risperdal' },
  { value: 'Risperidone', label: 'Risperidone' },
  { value: 'Ritalin', label: 'Ritalin' },
  { value: 'Rituxan', label: 'Rituxan' },
  { value: 'Rituximab', label: 'Rituximab' },
  { value: 'Rivaroxaban', label: 'Rivaroxaban' },
  { value: 'Rivastigmine', label: 'Rivastigmine' },
  { value: 'Rivastigmine Tartrate ', label: 'Rivastigmine Tartrate ' },
  { value: 'Rizatriptan', label: 'Rizatriptan' },
  { value: 'Robaxin', label: 'Robaxin' },
  { value: 'Rocephin', label: 'Rocephin' },
  { value: 'Ropinirole', label: 'Ropinirole' },
  { value: 'Ropinirole Hydrochloride', label: 'Ropinirole Hydrochloride' },
  { value: 'Rosiglitazone', label: 'Rosiglitazone' },
  { value: 'Rosuvastatin', label: 'Rosuvastatin' },
  { value: 'Rosuvastatin Calcium', label: 'Rosuvastatin Calcium' },
  { value: 'Roxicodone', label: 'Roxicodone' },
  { value: 'Rybelsus', label: 'Rybelsus' },
  { value: 'Salbutamol', label: 'Salbutamol' },
  { value: 'Salmeterol', label: 'Salmeterol' },
  { value: 'Saw Palmetto', label: 'Saw Palmetto' },
  { value: 'Saxenda', label: 'Saxenda' },
  { value: 'Scopolamine', label: 'Scopolamine' },
  { value: 'Scopolamine Hydrobromide', label: 'Scopolamine Hydrobromide' },
  { value: 'Senna', label: 'Senna' },
  { value: 'Senokot', label: 'Senokot' },
  { value: 'Sensipar', label: 'Sensipar' },
  { value: 'Seroquel', label: 'Seroquel' },
  { value: 'Sertraline', label: 'Sertraline' },
  { value: 'Sertraline Hci', label: 'Sertraline Hci' },
  { value: 'Sevelamer', label: 'Sevelamer' },
  { value: 'SevelamerCarbonate', label: 'SevelamerCarbonate' },
  { value: 'Shingrix', label: 'Shingrix' },
  { value: 'Sildenafil', label: 'Sildenafil' },
  { value: 'Sildenafil Citrate ', label: 'Sildenafil Citrate ' },
  { value: 'Simethicone', label: 'Simethicone' },
  { value: 'Simvastatin', label: 'Simvastatin' },
  { value: 'Sinemet', label: 'Sinemet' },
  { value: 'Singulair', label: 'Singulair' },
  { value: 'Sitagliptin', label: 'Sitagliptin' },
  { value: 'Skelaxin', label: 'Skelaxin' },
  { value: 'Sodium bicarbonate', label: 'Sodium bicarbonate' },
  { value: 'Sodium chloride', label: 'Sodium chloride' },
  { value: 'Solifenacin', label: 'Solifenacin' },
  { value: 'Solu-Medrol', label: 'Solu-Medrol' },
  { value: 'Soma', label: 'Soma' },
  { value: 'Sotalol', label: 'Sotalol' },
  { value: 'Spiriva', label: 'Spiriva' },
  { value: 'Spironolactone', label: 'Spironolactone' },
  {
    value: 'Spironolactone and Hydrochlorothiazide',
    label: 'Spironolactone and Hydrochlorothiazide',
  },
  { value: 'Sprintec ', label: 'Sprintec ' },
  { value: 'Sski Oral Solution', label: 'Sski Oral Solution' },
  { value: 'Stelara', label: 'Stelara' },
  { value: 'Stiolto Respimat', label: 'Stiolto Respimat' },
  { value: 'Strattera', label: 'Strattera' },
  { value: 'Suboxone', label: 'Suboxone' },
  { value: 'Subutex', label: 'Subutex' },
  { value: 'Sucralfate', label: 'Sucralfate' },
  { value: 'Sudafed', label: 'Sudafed' },
  { value: 'Sulfacetamide ophthalmic', label: 'Sulfacetamide ophthalmic' },
  { value: 'Sulfamethoxazole', label: 'Sulfamethoxazole' },
  {
    value: 'Sulfamethoxazole and trimethoprim',
    label: 'Sulfamethoxazole and trimethoprim',
  },
  {
    value: 'Sulfamethoxazole/Trimethoprim Ds ',
    label: 'Sulfamethoxazole/Trimethoprim Ds ',
  },
  { value: 'Sulfasalazine', label: 'Sulfasalazine' },
  { value: 'Sulfasalazine, Usp', label: 'Sulfasalazine, Usp' },
  { value: 'Sulindac', label: 'Sulindac' },
  { value: 'Sumatriptan', label: 'Sumatriptan' },
  { value: 'Sumatriptan Succinate', label: 'Sumatriptan Succinate' },
  { value: 'Symbicort', label: 'Symbicort' },
  { value: 'Synthroid', label: 'Synthroid' },
  { value: 'Tacrolimus', label: 'Tacrolimus' },
  { value: 'Tacrolimus ', label: 'Tacrolimus ' },
  { value: 'Tadalafil', label: 'Tadalafil' },
  { value: 'Tafenoquine (Arakodatm)', label: 'Tafenoquine (Arakodatm)' },
  { value: 'Tamiflu', label: 'Tamiflu' },
  { value: 'Tamoxifen', label: 'Tamoxifen' },
  { value: 'Tamoxifen Citrate ', label: 'Tamoxifen Citrate ' },
  { value: 'Tamsulosin', label: 'Tamsulosin' },
  { value: 'Tamsulosin Hydrochloride', label: 'Tamsulosin Hydrochloride' },
  { value: 'Tegretol', label: 'Tegretol' },
  { value: 'Telmisartan', label: 'Telmisartan' },
  { value: 'Temazepam', label: 'Temazepam' },
  { value: 'Temazepam ', label: 'Temazepam ' },
  { value: 'Tenofovir', label: 'Tenofovir' },
  { value: 'Terazosin', label: 'Terazosin' },
  { value: 'Terbinafine', label: 'Terbinafine' },
  { value: 'Tessalon Perles', label: 'Tessalon Perles' },
  { value: 'Testosterone', label: 'Testosterone' },
  { value: 'Testosterone Cypionate', label: 'Testosterone Cypionate' },
  { value: 'Testosterone Gel', label: 'Testosterone Gel' },
  { value: 'Tetracycline', label: 'Tetracycline' },
  { value: 'Theophylline', label: 'Theophylline' },
  { value: 'Thiamine', label: 'Thiamine' },
  { value: 'Ticagrelor', label: 'Ticagrelor' },
  { value: 'Timolol', label: 'Timolol' },
  { value: 'Tiotropium', label: 'Tiotropium' },
  { value: 'Tirosint', label: 'Tirosint' },
  { value: 'Tizanidine', label: 'Tizanidine' },
  { value: 'Tolterodine', label: 'Tolterodine' },
  { value: 'Tolterodine Tartrate ', label: 'Tolterodine Tartrate ' },
  { value: 'Topamax', label: 'Topamax' },
  { value: 'Topiramate', label: 'Topiramate' },
  { value: 'Topiramate Er', label: 'Topiramate Er' },
  { value: 'Toprol XL', label: 'Toprol XL' },
  { value: 'Toradol', label: 'Toradol' },
  { value: 'Torsemide', label: 'Torsemide' },
  { value: 'Tradjenta', label: 'Tradjenta' },
  { value: 'Tramadol', label: 'Tramadol' },
  { value: 'Tramadol Hydrochloride', label: 'Tramadol Hydrochloride' },
  { value: 'Trandolapril ', label: 'Trandolapril ' },
  { value: 'Tranylcypromine', label: 'Tranylcypromine' },
  { value: 'Travoprost', label: 'Travoprost' },
  { value: 'Trazodone', label: 'Trazodone' },
  { value: 'Trelegy Ellipta', label: 'Trelegy Ellipta' },
  { value: 'Tresiba', label: 'Tresiba' },
  { value: 'Tretinoin', label: 'Tretinoin' },
  { value: 'Triamcinolone', label: 'Triamcinolone' },
  { value: 'Triamcinolone Cream, 0.1%', label: 'Triamcinolone Cream, 0.1%' },
  {
    value: 'Triamcinolone Ointment, 0.1%',
    label: 'Triamcinolone Ointment, 0.1%',
  },
  { value: 'Triamterene', label: 'Triamterene' },
  { value: 'Triamterene/Hctz ', label: 'Triamterene/Hctz ' },
  { value: 'Trifluoperazine Hcl ', label: 'Trifluoperazine Hcl ' },
  { value: 'Trileptal', label: 'Trileptal' },
  { value: 'Trimethoprim', label: 'Trimethoprim' },
  { value: 'Trintellix', label: 'Trintellix' },
  { value: 'Tri-Previfem ', label: 'Tri-Previfem ' },
  { value: 'Tri-Sprintec ', label: 'Tri-Sprintec ' },
  { value: 'Trospium Chloride ', label: 'Trospium Chloride ' },
  { value: 'Trulicity', label: 'Trulicity' },
  { value: 'Turmeric', label: 'Turmeric' },
  { value: 'Tylenol', label: 'Tylenol' },
  { value: 'Tylenol with Codeine #3', label: 'Tylenol with Codeine #3' },
  { value: 'Ubiquinone', label: 'Ubiquinone' },
  { value: 'Ubrelvy', label: 'Ubrelvy' },
  { value: 'Ubrogepant', label: 'Ubrogepant' },
  { value: 'Uceris', label: 'Uceris' },
  { value: 'Udenyca', label: 'Udenyca' },
  { value: 'Ulipristal', label: 'Ulipristal' },
  { value: 'Uloric', label: 'Uloric' },
  { value: 'Ultomiris', label: 'Ultomiris' },
  { value: 'Ultracet', label: 'Ultracet' },
  { value: 'Ultram', label: 'Ultram' },
  { value: 'Ultram ER', label: 'Ultram ER' },
  { value: 'Ultravate', label: 'Ultravate' },
  { value: 'Umeclidinium', label: 'Umeclidinium' },
  {
    value: 'Umeclidinium and vilanterol',
    label: 'Umeclidinium and vilanterol',
  },
  { value: 'Unasyn', label: 'Unasyn' },
  { value: 'Unisom', label: 'Unisom' },
  { value: 'Unisom Sleep Gels', label: 'Unisom Sleep Gels' },
  { value: 'Unithroid', label: 'Unithroid' },
  { value: 'Univasc', label: 'Univasc' },
  { value: 'Upadacitinib', label: 'Upadacitinib' },
  { value: 'Uplizna', label: 'Uplizna' },
  { value: 'Upneeq', label: 'Upneeq' },
  { value: 'Uptravi', label: 'Uptravi' },
  { value: 'Urea Cream', label: 'Urea Cream' },
  { value: 'Urea topical', label: 'Urea topical' },
  { value: 'Urecholine', label: 'Urecholine' },
  { value: 'Ure-Na', label: 'Ure-Na' },
  { value: 'Uribel', label: 'Uribel' },
  { value: 'Urispas', label: 'Urispas' },
  { value: 'Urocit-K', label: 'Urocit-K' },
  { value: 'Urofollitropin', label: 'Urofollitropin' },
  { value: 'Urogesic-Blue', label: 'Urogesic-Blue' },
  { value: 'Urokinase', label: 'Urokinase' },
  { value: 'Uro-Mag', label: 'Uro-Mag' },
  { value: 'Uro-MP', label: 'Uro-MP' },
  { value: 'Uroxatral', label: 'Uroxatral' },
  { value: 'Urso', label: 'Urso' },
  { value: 'Ursodiol', label: 'Ursodiol' },
  { value: 'Ustekinumab', label: 'Ustekinumab' },
  { value: 'Uva Ursi', label: 'Uva Ursi' },
  { value: 'Valacyclovir', label: 'Valacyclovir' },
  { value: 'Valacyclovir Hcl ', label: 'Valacyclovir Hcl ' },
  { value: 'Valium', label: 'Valium' },
  { value: 'Valproic acid', label: 'Valproic acid' },
  { value: 'Valsartan', label: 'Valsartan' },
  { value: 'Valsartan Hydrochloride', label: 'Valsartan Hydrochloride' },
  {
    value: 'Valsartan Hydrochlorothiazide',
    label: 'Valsartan Hydrochlorothiazide',
  },
  { value: 'Valtrex', label: 'Valtrex' },
  { value: 'Vancomycin', label: 'Vancomycin' },
  { value: 'Vardenafil', label: 'Vardenafil' },
  { value: 'Varenicline', label: 'Varenicline' },
  { value: 'Vascepa', label: 'Vascepa' },
  { value: 'Vasopressin', label: 'Vasopressin' },
  { value: 'Vasotec', label: 'Vasotec' },
  { value: 'Venlafaxine', label: 'Venlafaxine' },
  { value: 'Venofer', label: 'Venofer' },
  { value: 'Ventolin', label: 'Ventolin' },
  { value: 'Ventolin HFA', label: 'Ventolin HFA' },
  { value: 'Verapamil', label: 'Verapamil' },
  { value: 'Verapamil Hcl', label: 'Verapamil Hcl' },
  { value: 'Verapamil Sr ', label: 'Verapamil Sr ' },
  { value: 'Versed', label: 'Versed' },
  { value: 'VESIcare', label: 'VESIcare' },
  { value: 'Viagra', label: 'Viagra' },
  { value: 'Vibramycin', label: 'Vibramycin' },
  { value: 'Vicodin', label: 'Vicodin' },
  { value: 'Victoza', label: 'Victoza' },
  { value: 'Viibryd', label: 'Viibryd' },
  { value: 'Vilazodone', label: 'Vilazodone' },
  { value: 'Vimpat', label: 'Vimpat' },
  { value: 'Vistaril', label: 'Vistaril' },
  { value: 'Vitamin A', label: 'Vitamin A' },
  { value: 'Vitamin B 12', label: 'Vitamin B 12' },
  { value: 'Vitamin B Complex  ', label: 'Vitamin B Complex  ' },
  { value: 'Vitamin B1', label: 'Vitamin B1' },
  { value: 'Vitamin B6', label: 'Vitamin B6' },
  { value: 'Vitamin C', label: 'Vitamin C' },
  { value: 'Vitamin D', label: 'Vitamin D' },
  { value: 'Vitamin D2', label: 'Vitamin D2' },
  { value: 'Vitamin D3', label: 'Vitamin D3' },
  { value: 'Vitamin E', label: 'Vitamin E' },
  { value: 'Voltaren', label: 'Voltaren' },
  { value: 'Voltaren Gel', label: 'Voltaren Gel' },
  { value: 'Vortioxetine', label: 'Vortioxetine' },
  { value: 'Vraylar', label: 'Vraylar' },
  { value: 'Vytorin', label: 'Vytorin' },
  { value: 'Vyvanse', label: 'Vyvanse' },
  { value: 'Wakix', label: 'Wakix' },
  { value: 'Wal-itin', label: 'Wal-itin' },
  { value: 'Warfarin', label: 'Warfarin' },
  { value: 'Warfarin Sodium', label: 'Warfarin Sodium' },
  { value: 'Wart Remover', label: 'Wart Remover' },
  { value: 'Water for Injection', label: 'Water for Injection' },
  { value: 'Welchol', label: 'Welchol' },
  { value: 'Wellbutrin', label: 'Wellbutrin' },
  { value: 'Wellbutrin SR', label: 'Wellbutrin SR' },
  { value: 'Wellbutrin XL', label: 'Wellbutrin XL' },
  { value: 'Westcort', label: 'Westcort' },
  { value: 'Westhroid', label: 'Westhroid' },
  { value: 'Wilate', label: 'Wilate' },
  { value: 'Wine', label: 'Wine' },
  { value: 'Winlevi', label: 'Winlevi' },
  { value: 'Witch Hazel', label: 'Witch Hazel' },
  { value: 'Witch hazel topical', label: 'Witch hazel topical' },
  { value: 'Wixela Inhub', label: 'Wixela Inhub' },
  { value: 'Wormwood', label: 'Wormwood' },
  { value: 'WP Thyroid', label: 'WP Thyroid' },
  { value: 'Xadago', label: 'Xadago' },
  { value: 'Xalatan', label: 'Xalatan' },
  { value: 'Xanax', label: 'Xanax' },
  { value: 'Xanax XR', label: 'Xanax XR' },
  { value: 'Xaracoll', label: 'Xaracoll' },
  { value: 'Xarelto', label: 'Xarelto' },
  { value: 'Xcopri', label: 'Xcopri' },
  { value: 'Xeljanz', label: 'Xeljanz' },
  { value: 'Xeljanz XR', label: 'Xeljanz XR' },
  { value: 'Xeloda', label: 'Xeloda' },
  { value: 'Xelpros', label: 'Xelpros' },
  { value: 'Xenical', label: 'Xenical' },
  { value: 'Xenleta', label: 'Xenleta' },
  { value: 'Xeomin', label: 'Xeomin' },
  { value: 'Xgeva', label: 'Xgeva' },
  { value: 'Xhance', label: 'Xhance' },
  { value: 'Xiaflex', label: 'Xiaflex' },
  { value: 'Xifaxan', label: 'Xifaxan' },
  { value: 'Xigduo XR', label: 'Xigduo XR' },
  { value: 'Xiidra', label: 'Xiidra' },
  { value: 'Ximino', label: 'Ximino' },
  { value: 'Xofluza', label: 'Xofluza' },
  { value: 'Xolair', label: 'Xolair' },
  { value: 'Xopenex', label: 'Xopenex' },
  { value: 'Xopenex HFA', label: 'Xopenex HFA' },
  { value: 'Xospata', label: 'Xospata' },
  { value: 'Xpovio', label: 'Xpovio' },
  { value: 'Xtampza ER', label: 'Xtampza ER' },
  { value: 'Xtandi', label: 'Xtandi' },
  { value: 'Xulane', label: 'Xulane' },
  { value: 'Xultophy', label: 'Xultophy' },
  { value: 'Xylitol', label: 'Xylitol' },
  { value: 'Xylocaine', label: 'Xylocaine' },
  { value: 'Xylocaine Jelly', label: 'Xylocaine Jelly' },
  { value: 'Xylometazoline', label: 'Xylometazoline' },
  { value: 'Xylometazoline nasal', label: 'Xylometazoline nasal' },
  { value: 'Xyosted', label: 'Xyosted' },
  { value: 'Xyrem', label: 'Xyrem' },
  { value: 'Xywav', label: 'Xywav' },
  { value: 'Xyzal', label: 'Xyzal' },
  { value: 'Y-90 Zevalin', label: 'Y-90 Zevalin' },
  { value: 'Yasmin', label: 'Yasmin' },
  { value: 'Yaz', label: 'Yaz' },
  { value: 'Yervoy', label: 'Yervoy' },
  { value: 'Yescarta', label: 'Yescarta' },
  { value: 'YF-Vax', label: 'YF-Vax' },
  { value: 'Yohimbe', label: 'Yohimbe' },
  { value: 'Yohimbine', label: 'Yohimbine' },
  { value: 'Yondelis', label: 'Yondelis' },
  { value: 'Yonsa', label: 'Yonsa' },
  { value: 'Yupelri', label: 'Yupelri' },
  { value: 'Yuvafem', label: 'Yuvafem' },
  { value: 'Zaditor', label: 'Zaditor' },
  { value: 'Zafirlukast', label: 'Zafirlukast' },
  { value: 'Zaleplon', label: 'Zaleplon' },
  { value: 'Zanaflex', label: 'Zanaflex' },
  { value: 'Zantac', label: 'Zantac' },
  { value: 'Zaroxolyn', label: 'Zaroxolyn' },
  { value: 'Zarxio', label: 'Zarxio' },
  { value: 'Zebeta', label: 'Zebeta' },
  { value: 'Zegerid', label: 'Zegerid' },
  { value: 'Zenpep', label: 'Zenpep' },
  { value: 'Zestoretic', label: 'Zestoretic' },
  { value: 'Zestril', label: 'Zestril' },
  { value: 'Zetia', label: 'Zetia' },
  { value: 'Ziac', label: 'Ziac' },
  { value: 'Zidovudine', label: 'Zidovudine' },
  { value: 'Zidovudine ', label: 'Zidovudine ' },
  { value: 'Zinc', label: 'Zinc' },
  { value: 'Zinc gluconate', label: 'Zinc gluconate' },
  { value: 'Zinc oxide topical', label: 'Zinc oxide topical' },
  { value: 'Zinc sulfate', label: 'Zinc sulfate' },
  { value: 'Ziprasidone', label: 'Ziprasidone' },
  { value: 'Zithromax', label: 'Zithromax' },
  { value: 'Zocor', label: 'Zocor' },
  { value: 'Zofran', label: 'Zofran' },
  { value: 'Zofran ODT', label: 'Zofran ODT' },
  { value: 'Zoladex', label: 'Zoladex' },
  { value: 'Zoledronic acid', label: 'Zoledronic acid' },
  { value: 'Zolmitriptan', label: 'Zolmitriptan' },
  { value: 'Zoloft', label: 'Zoloft' },
  { value: 'Zolpidem', label: 'Zolpidem' },
  { value: 'Zometa', label: 'Zometa' },
  { value: 'Zomig', label: 'Zomig' },
  { value: 'Zonegran', label: 'Zonegran' },
  { value: 'Zonisamide', label: 'Zonisamide' },
  { value: 'Zostavax', label: 'Zostavax' },
  { value: 'Zosyn', label: 'Zosyn' },
  { value: 'Zovirax', label: 'Zovirax' },
  { value: 'Zyloprim', label: 'Zyloprim' },
  { value: 'Zyprexa', label: 'Zyprexa' },
  { value: 'Zyrtec', label: 'Zyrtec' },
  { value: 'Zytiga', label: 'Zytiga' },
  { value: 'Zyvox', label: 'Zyvox' },
];
