import React from 'react';
import styled from 'styled-components';

interface Props {
  label?: string;
  type?: string;
  name: string;
  value?: string;
  onChange?: () => void;
  onBlur?: () => void;
  error?: string;
  placeholder?: string;
  defaultValue?: string;
  max?: Date;
  disabled?: boolean;
  // height?: string;
  labelColor?: string;
  borderColor?: string;
  width?: string;
  backgroundColor?: string;
}

function FormInputComponent({
  label,
  type,
  name,
  value,
  onChange,
  onBlur,
  error,
  placeholder,
  defaultValue,
  disabled,
  // height,
  labelColor,
  borderColor,
  width,
  backgroundColor,
}: Props) {
  return (
    <StyledFormInputComponent
      borderColor={borderColor}
      labelColor={labelColor}
      // height={height}
      width={width}
      backgroundColor={backgroundColor}
    >
      {label && (
        <label htmlFor={name} className="form-font form-label">
          {label}
        </label>
      )}
      <input
        type={type}
        id={name}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value || defaultValue || ''}
        className="form-font custom-calendar"
        disabled={disabled}
      />
      {error && <div className="form-error">{error}</div>}
    </StyledFormInputComponent>
  );
}

interface StyledFormInputComponentProps {
  // height?: string;
  width?: string;
  labelColor?: string;
  borderColor?: string;
  backgroundColor?: string;
}

const StyledFormInputComponent = styled.div<StyledFormInputComponentProps>`
  font-family: 'Sofia Pro';
  label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 3px;
    color: ${(props) => (props.labelColor ? props.labelColor : '#2254d3;')};
  }
  input {
    display: inline-block;
    // font-size: 1.6rem;
    background: ${(props) =>
      props.backgroundColor ? props.backgroundColor : 'white'};
    -webkit-appearance: none;
    width: ${(props) => (props.width ? props.width : '100%')};
    border: none;
    height: 37px;
    padding-bottom: 0rem;
    padding-left: 1rem;
    padding-right: 1rem;
    /* border: 1px solid #dddddf !important; */
    border-color: ${({ borderColor }) =>
      borderColor ? borderColor : '#2254d3'};
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    font-size: 12px;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0; /* Optional - adjust as needed */
  }
  .custom-calendar::-webkit-calendar-picker-indicator,
  input[type='date']::-webkit-calendar-picker-indicator {
    background-image: url('images/date-icon.svg');
    background-repeat: no-repeat;
    background-size: contain;
    /* filter: invert(1); */
    cursor: pointer;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input:disabled {
    background: rgba(0, 0, 0, 0.1);
  }

  input:active {
    border: 1px solid green !important;
  }
  input:focus {
    border: 1px solid #34a853 !important;
  }
  .form-error {
    color: red;
    font-size: 12px;
  }
  @media only screen and (max-width: 405px) {
    input {
      min-width: 100%;
    }
  }
`;

export default FormInputComponent;
