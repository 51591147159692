import React, { useState, useEffect } from 'react';
import Button from '@components/Button/Button';

import { whiteBackground } from '@styles/react-select-custom';
import Select from 'react-select';
import ModalWrapper from '@components/modalComponents/NewModal';
import FormTitleSection from '@components/modalComponents/FormTitleSection';
import { toast } from 'react-toastify';
import request, { headers } from '@request';
import TextAreaInput from '@components/formInputs/TextAreaInput/';
import Input from '@components/formInputs/FormInputComponent/';
import SelectWrapper from '@components/formInputs/selectWrapper';
import { useFetchPlans } from '@hooks/plans';
export default function CreateNew({
  createService,
  setCreateService,
  planType,
  currentData,
  facilityType,
  editUrl,
}) {
  const deviceOptions = [
    {
      value: 'MOBILE',
      label: 'Mobile',
    },
    { value: 'OTHER', label: 'Others' },
  ];
  const { refetch } = useFetchPlans();
  const token = localStorage.getItem('token');
  const [device, setDevice] = useState(null);
  const [label, setLabel] = useState('');
  const [value, setValue] = useState('');
  const [description, setDescription] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);

  function createUniqueIdentifier(sentence) {
    // Remove punctuation marks using regular expression
    const cleanSentence = sentence.replace(/[^\w\s]/g, '');

    // Split the cleaned sentence into words
    const words = cleanSentence.split(/\s+/);

    // Combine words into a single string without spaces
    const combinedWords = words.join('');

    return combinedWords;
  }

  const submit = async (e) => {
    e.preventDefault();
    if (!device || !label || !description) {
      toast.error('All fields are require');
      return;
    }
    const editPayload = {
      label,
      description,
      value,
    };

    const createPayload = {
      ...editPayload,
      facility_type: facilityType,
      identifier: `${createUniqueIdentifier(label)}`,
      device: device,
      plan_type: planType,
    };
    const payload = currentData?.id ? editPayload : createPayload;
    setSubmitLoading(true);
    const requestMethod = currentData?.id ? 'patch' : 'post';
    try {
      const res = await request[requestMethod](
        currentData.id
          ? `${editUrl}/${currentData.id}/`
          : `utils/create-health-plan-service-for-a-facility-type/`,
        payload,
        headers(token)
      );
      console.log('response', res.status);
      if (res.status === 201 || res.status === 200) {
        refetch();
        setCreateService(false);
        toast.success(`Service added`);
      } else {
        toast.error(`Error creating service`);
      }
    } catch (error) {
      toast.error(`Error creating service`);
    } finally {
      setSubmitLoading(false);
    }
  };

  function onLoad() {
    if (currentData) {
      setDevice(currentData.device);
      setLabel(currentData.label);
      setDescription(currentData.description);
      setValue(currentData.value);
    }
  }

  useEffect(() => {
    onLoad();
  }, [currentData]);

  return (
    <ModalWrapper>
      <FormTitleSection
        title={currentData ? 'Edit Service' : 'Create New Service'}
        onClick={() => setCreateService(!createService)}
      />
      <form className="">
        <div className="mb-3">
          <SelectWrapper label="Mobile or Others">
            <Select
              options={deviceOptions}
              placeholder="Device options"
              styles={whiteBackground}
              value={deviceOptions?.find((item) => item.value === device)}
              onChange={(e) => setDevice(e.value)}
            />
          </SelectWrapper>
        </div>

        <div className="mb-3">
          <Input
            label="Service title"
            placeholder="Service title"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
          />
        </div>

        <div className="mb-3">
          <Input
            label="Value (if applicable)"
            placeholder="Service title"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>

        <div className="mb-3">
          <SelectWrapper label="Description">
            {' '}
            <TextAreaInput
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Service description"
              border="#2254D3"
            />
          </SelectWrapper>
        </div>
        <div className="mt-3">
          <Button onClick={submit} type="submit" loading={submitLoading}>
            Save
          </Button>
        </div>
      </form>
    </ModalWrapper>
  );
}
