import Package from '../Package';

export default function Index() {
  return (
    <Package
      url="plan/consultant-plans"
      createUrl="utils/create-health-plan-service-for-a-facility-type"
      editUrl="utils/plan/consultant-plan/retrieve-update-destroy"
      deleteUrl="consultant-plan"
      facilityType="CONSULTANT"
    />
  );
}
