import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import request from '@request';
import SubmitSuccess from '@components/SubmitSuccess';
import FormTitleSection from '@components/modalComponents/FormTitleSection';
import Button from '@components/Button/Button';
import Input from '@components/formInputs/FormInputComponent';
import TextAreaInput from '@components/formInputs/TextAreaInput';
import ModalWrapper from '@components/modalComponents/NewModal';
import SelectWrapper from '@components/formInputs/selectWrapper';
import FormUploadComponent from '@components/formInputs/FormUploadComponent';

export default function CreateForum({
  currentData,
  createForum,
  setCreateForum,
  refetch,
}) {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [profileImg, setProfileImg] = useState({});

  const [profileImgError] = useState('');
  const imgType = ['jpg', 'jpeg', 'png', 'PNG'];

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
  });

  const initialValues = {
    name: '',
    description: '',
  };

  const onSubmit = async (values) => {
    const formData = new FormData();
    const multipartConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    };
    for (const key in values) {
      formData.append(key, values[key]);
    }
    if (profileImg.name) {
      formData.append('image', profileImg);
    }

    setLoading(true);
    const requestMethod = currentData?.id ? 'patch' : 'post';

    try {
      const res = await request[requestMethod](
        currentData?.id
          ? `rtc/update-forum/${currentData?.id}/`
          : 'rtc/create-forum/',
        formData,
        multipartConfig
      );
      toast.success('Test updated');
      if (res.status === 201) {
        refetch();
        setSubmitted(true);
      }
    } catch (error) {
      console.log('error', error.response);
      toast.error('Error');
    } finally {
      setLoading(false);
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const onLoad = () => {
    if (currentData) {
      formik.setFieldValue('name', currentData.name, false);
      formik.setFieldValue('description', currentData.description, false);
      formik.setFieldValue(
        'investigation_category',
        currentData.investigation_category,
        false
      );
    }
  };

  useEffect(() => {
    onLoad();
  }, [currentData]);

  return (
    <ModalWrapper>
      <ContentWrapper>
        <FormTitleSection
          //   name={forName}
          title="Create New Forum"
          onClick={() => setCreateForum(!createForum)}
        />
        {submitted ? (
          <SubmitSuccess message={`New forum saved`} />
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <div className="img">
              <FormUploadComponent
                pictureType={imgType}
                type="file"
                error={profileImgError}
                selectedFile={profileImg}
                setSelectedFile={setProfileImg}
                acceptTypes={`jpg, jpeg, gif, png. Size Limit 2mb`}
                imgUrl={profileImg}
                pictureIcon={''}
                className="upload-icon"
              />
            </div>
            <div className="row-1">
              <div className="huidp">
                <Input
                  label="Test Name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  disabled={currentData && currentData?.id}
                />
              </div>
            </div>

            <div className="">
              <SelectWrapper error={formik.errors.note}>
                <TextAreaInput
                  placeholder="Enter note of refund here"
                  rows="4"
                  name="description"
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  error={formik.errors.description}
                />
              </SelectWrapper>
            </div>
            <div className="d-flex flex-row-reverse">
              <Button
                className="mt-3"
                type="submit"
                onClick={formik.handleSubmit}
                loading={loading}
                width="150px"
              >
                Save
              </Button>
            </div>
          </form>
        )}
      </ContentWrapper>
    </ModalWrapper>
  );
}
const ContentWrapper = styled.div`
  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
  }

  .row-1 {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  .huidp {
    width: 100%;
  }
`;
