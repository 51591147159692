export const tabs = [
  { id: 'BASIC', label: 'BASIC', content: '' },
  {
    id: 'BRONZE',
    label: 'BRONZE',
    content: '',
  },
  { id: 'SILVER', label: 'SILVER', content: '' },
  {
    id: 'GOLD',
    label: 'GOLD',
    content: '',
  },
  { id: 'PLATINUM', label: 'PLATINUM', content: '' },
];
