import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import request from '../../apiUrls/request';
import { toast } from 'react-toastify';
import { css } from '@emotion/react';
import BounceLoader from 'react-spinners/BounceLoader';
import Logo from '../../assets/logo.svg';

const VerifyEmail = () => {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    background: url(${Logo}) no-repeat center;
  `;

  const [verifyEmail, setVerifyEmail] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(
    () => {
      setTimeout(() => {
        FetchverifyEmail();
      }, 1000);
    }, // eslint-disable-next-line
    []
  );

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const payload = { token: token };

  const FetchverifyEmail = async () => {
    try {
      const response = await request.post('/main/email-verify/', payload);

      if (response.status === 200) {
        toast.success(response.data.message);
        setVerifyEmail(true);
        setLoading(false);
      } else {
        toast.error(response.data.message);
        setVerifyEmail(false);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <VerifyEmailContainer>
      {loading ? (
        <div className="sweet-loading">
          <BounceLoader css={override} background={Logo} size={250} />
        </div>
      ) : verifyEmail ? (
        <div className="verify-email-container">
          <div className="verify-email-header">
            <h1>Email verification Status</h1>
          </div>
          <div className="verify-email-body">
            <div className="verify-email-body-text">
              <p>
                Email has been verified. Please click{' '}
                <Link to="/login">here</Link> to login.
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="verify-email-body">
          <div className="verify-email-body-text">
            <p>Something went wrong. please refresh this page</p>
          </div>
        </div>
      )}
    </VerifyEmailContainer>
  );
};

export default VerifyEmail;

const VerifyEmailContainer = styled.div`
  padding: 20px;
  .sweet-loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

// http://localhost:3005/email-verify?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjYxMjcxNDc2LCJqdGkiOiJlZGRjM2E4NWVjOTg0M2UzODk5NmIxMmViOGVlMjMwZiIsInVzZXJfaWQiOjIwfQ.ZcwiigFB2HEo5AGr6feRE18I97AYpI-bDNgAalj56CI
