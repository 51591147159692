import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import ClipLoader from 'react-spinners/ClipLoader';
import keyImage from '../../assets/images/key.svg';
import passwordViewImg from '../../assets/images/view-password-icon.svg';
import passwordViewImgBlue from '../../assets/images/view-password-icon-blue.svg';
import request from '../../apiUrls/request';

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [isViewPassword, setIsViewPassword] = useState(false);
  const validationSchema = Yup.object({
    password: Yup.string()
      .required('*Required')
      .min(8, '*Password must be at least 8 characters'),
    // confirmPassword: Yup.string()
    //   .required("*Required")
    //   .min(8, "*Password must be at least 8 characters")
    //   .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');

  const initialValues = {
    password: '',
    // confirmPassword: "",
    token: token,
  };

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      await request.post('/main/auth/password-reset/', values);
      toast.success('Password has been reset, please proceed to login');
      history.push('/login');
      setLoading(false);
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
      console.log(err);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  // const history = useHistory();
  return (
    <LoginWrapper>
      <div className="first--section">
        <div className="first--section--wrapper">
          <h2>Change Password</h2>
          <div className="button--wrapper">
            <NavLink to="/login">
              <button>Back to login</button>
            </NavLink>
          </div>
        </div>
      </div>

      <LoginFormWrapper>
        <ToastContainer style={{ zIndex: '10' }} position="top-center" />
        <div className="login--container">
          <form onSubmit={formik.handleSubmit}>
            <div className="heading">
              <div className="key--image">
                <img src={keyImage} alt="key" />
              </div>
              <div>
                <h4>Change Password</h4>
              </div>
            </div>
            <div className="inputs--wrapper">
              {/* <div className="form--group">
                <label>Enter new password</label>
                <input
                  type="password"
                  name="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  className="form-control email"
                  placeholder=""
                />
                {formik.errors.password && formik.touched.password ? (
                  <div className="error">{formik.errors.password}</div>
                ) : null}
              </div> */}
              <div className="form--group">
                <label>Enter new password</label>
                <div className="password--wrapper">
                  <input
                    name="password"
                    value={formik.values.password}
                    type={isViewPassword ? 'text' : 'password'}
                    className="form-control"
                    placeholder=""
                    onChange={formik.handleChange}
                  />
                  <img
                    src={isViewPassword ? passwordViewImgBlue : passwordViewImg}
                    onClick={() => setIsViewPassword(!isViewPassword)}
                    alt="toggle view"
                  />
                </div>
                {formik.errors.password && formik.touched.password ? (
                  <div className="error">{formik.errors.password}</div>
                ) : null}
              </div>
            </div>
            <div className="main--button--wrapper">
              <button type="submit">
                {loading ? (
                  <ClipLoader color={'#fff'} size={'30px'} />
                ) : (
                  'Change Password'
                )}
              </button>
            </div>
          </form>
        </div>
      </LoginFormWrapper>
    </LoginWrapper>
  );
};

export default ChangePassword;

const LoginWrapper = styled.div`
  font-family: 'Sofia Pro';
  font-style: normal;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
  box-sizing: border-box;
  padding: 35px 70px;
  background: #f5f7fa;
  overflow-y: scroll;

  & .account {
    display: flex;
    gap: 5px;
    align-items: center;

    & p {
      font-weight: 400;
      font-size: 12px;
      padding: 0;
      margin: 0;
    }

    & a {
      font-size: 12px;
    }
  }

  & .first--section {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 20px;

    & .first--section--wrapper {
      display: flex;
      flex-direction: column;
      gap: 2px;
      width: 100%;
      box-sizing: border-box;
      position: relative;
      height: 400px;
      padding: 25px 0;

      & .button--wrapper {
        position: absolute;
        top: 25%;
        width: 330.06px;

        & button {
          width: 330.06px;
          height: 50px;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          /* Blue */
          background: #2254d3;
          border-radius: 10px;
          border: none;
          outline: none;
          color: #fff;

          @media screen and (max-width: 355px) {
            width: 100%;
          }
        }

        @media screen and (max-width: 355px) {
          width: 100%;
        }
      }

      & h2 {
        font-family: 'Sofia Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 64px;

        /* Black */

        color: #2254d3;
      }

      & h3 {
        font-family: 'Sofia Pro';
        font-style: normal;
        font-weight: 500;
        font-size: 33px;
        line-height: 104.6%;
        /* or 42px */

        /* Blue */

        color: #2254d3;
      }

      @media screen and (max-width: 991px) {
        height: 250px;
        & .button--wrapper {
          transform: translateY(0);
          top: 70%;
        }
      }

      @media screen and (max-width: 525px) {
        & h2 {
          font-size: 25px;
        }

        & h3 {
          font-size: 20px;
        }
      }
    }

    @media screen and (max-width: 991px) {
      align-items: flex-end;
    }
    @media screen and (max-width: 525px) {
      padding: 0px;
    }
  }

  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 525px) {
    padding: 10px;
  }
`;

const LoginFormWrapper = styled.div`
  font-family: 'Sofia Pro';
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  .error {
    color: red;
  }
  .login--container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: #ffffff;
    border: 1px solid #dfe8fc;
    box-sizing: border-box;
    width: 580px;
    height: 474.92px;
    /* Card border */
    box-shadow: 0px 8px 19px rgba(34, 84, 211, 0.15);
    border-radius: 10px;

    & form {
      display: flex;
      flex-direction: column;
      gap: 20px;
      position: relative;
      height: 100%;

      & .main--button--wrapper {
        position: absolute;
        bottom: 10px;
        width: 100%;

        & button {
          width: 100%;
          height: 50px;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          /* Blue */
          background: #2254d3;
          border-radius: 10px;
          border: none;
          outline: none;
          color: #fff;
        }
      }

      & .heading {
        display: flex;
        gap: 10px;

        & .key--image {
          width: 50px;
          height: 50px;
          left: 760px;
          top: 104px;

          /* Card border */

          background: #dfe8fc;
          border-radius: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        & div:last-of-type {
          display: flex;
          flex-direction: column;
          justify-content: center;

          & h4 {
            padding: 0;
            margin: 0;
            font-size: 20px;
          }

          & small {
            font-weight: 500;
            font-size: 10px;
            /* identical to box height */

            /* Label & subheading */
            padding: 0;
            margin: 0;
            color: #8e919c;
          }
        }
      }

      & .inputs--wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;

        & .form--group {
          display: flex;
          flex-direction: column;
          gap: 2px;

          & .password--wrapper {
            position: relative;

            & img {
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
            }
          }

          & input {
            width: 100%;
            height: 50px;
            background: #ffffff;
            /* Blue */

            border-radius: 5px;
          }

          & input.email {
            border: 1px solid #2254d3;
          }

          & input:focus {
            outline: none;
            border: 1px solid #2254d3;
            box-shadow: none;
          }
          & label {
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 19px;
            color: #8e919c;
          }
        }

        & h3 {
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 34px;
          color: #071232;
          margin-bottom: 0;
        }
      }
    }

    @media screen and (max-width: 1200px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 991px) {
    align-items: flex-start;
    padding: 0 20px;
  }
  @media screen and (max-width: 525px) {
    padding: 0px;
  }
`;
