import React from 'react';
import Logo from '@assets/logo.svg';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
// import hospitalProfileAction from '../../redux/actions/hospitalActions/hospitalProfileAction';
// import { useSelector, useDispatch } from 'react-redux';

const LogoDesign = () => {
  const history = useHistory();
  return (
    <LogoStyle onClick={() => history.push('/home')}>
      <img src={Logo} alt="logo" className="logo" />
      <p className="logo-text">
        <span className="logo-total">Total HealthCare</span>
      </p>
    </LogoStyle>
  );
};
export default LogoDesign;
const LogoStyle = styled.div`
  max-width: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* padding: 10px; */
  cursor: pointer;
  /* background:red ; */

  .logo {
    margin-top: -10px;
    width: 30px;
    height: 30px;
  }
  .logo-text {
    display: flex;
    flex-direction: column;
  }
  .logo-total {
    font-size: 18px;
    font-weight: 500;
    color: #071232;
    text-wrap: wrap;
  }
  .logo-care {
    font-size: 18px;
    font-weight: normal;
    color: #071232;
    margin-top: -10px;
  }
`;
