import { Redirect, Route } from 'react-router-dom';
// import jwt_decode from "jwt-decode";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem('token');

  // const decodedToken = jwt_decode(token)
  // const exp = decodedToken.exp*1000 < Date.now();

  return (
    <Route
      {...rest}
      render={(props) =>
        !token ? <Redirect to="/" /> : <Component {...props} />
      }
    />
  );
};

export default ProtectedRoute;
