import React, { useState, useEffect, useRef } from 'react';
import '@styles/Accordion.css';
import styled from 'styled-components';
import '@styles/table.style.css';
import ClipLoader from 'react-spinners/ClipLoader';
import request, { headers } from '@request';
import { useFetchPharmacyDataset } from '@hooks/queries';
import Pagination from '@utils/pagination';
import { PAGESIZE } from '@constant/pageSize';
import Checkbox from '@components/formInputs/checkbox';
import ImportPharmacyDataset from './ImportPharmacyDataset';
import Layout from './layout';
import Header from '../common/Header';
import { useParams } from 'react-router';
import Button from '@components/Button/Button';
import { useLocation } from 'react-router';
import { useHandleDialogueDelete } from '@hooks/useDialog';
import CreateDataset from './CreateDataset';

export default function FacilityDataset() {
  const ref = useRef(null);
  const [idOfTable, setIdOfTable] = useState(-1);
  const [currentPage, setCurrentPage] = useState(1);
  const [editDataset, setEditDataset] = useState(false);
  const [createDataset, setCreateDataset] = useState(false);
  const { state } = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { id } = useParams();
  const { data, isLoading, refetch } = useFetchPharmacyDataset(
    id,
    currentPage,
    ''
  );

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIdOfTable(-1);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  //   eslint-disable-next-line
  const [currData, setCurrData] = useState(null);

  const showModal = (item) => {
    setCurrData(item);
    setIdOfTable(item.id);
  };

  const deleteDataset = async () => {
    try {
      await request.delete(
        `main/pharmacy-inventory/retrieve-update-destroy-pharmacy-dataset/${currData.id}/`
      );
      refetch();
    } catch (err) {
      console.error('Error :', err.response);
    }
  };

  const clearDataset = async () => {
    try {
      await request.delete(
        `main/pharmacy-inventory/thc-admin-clear-facility-dataset/${state.id}/`,
        headers(localStorage.getItem('token'))
      );
      refetch();
    } catch (err) {
      console.error('Error :', err.response);
    }
  };

  return (
    <Layout>
      <Header inner heading={`${state?.name} dataset`} />
      <AccordionWrapper>
        <div className="d-flex gap-1">
          <Button
            type="button"
            onClick={() =>
              useHandleDialogueDelete(
                clearDataset,
                `Are you sure you want delete all the dataset for this facility?, Process cannot be reversed?`
              )
            }
            backgroundColor="red"
            width="200px"
          >
            Clear Dataset
          </Button>

          <Button
            type="button"
            onClick={() => setIsModalOpen(!isModalOpen)}
            width="200px"
          >
            Import Dataset
          </Button>
          <Button
            type="button"
            onClick={() => setCreateDataset(!createDataset)}
            width="200px"
          >
            Create Dataset
          </Button>
        </div>

        {isLoading ? (
          <div className="loader">
            <ClipLoader
              size={60}
              sizeUnit="px"
              color="#2254d3"
              loading={isLoading}
            />
          </div>
        ) : (
          <div className="">
            <table>
              <thead>
                <tr>
                  <th>
                    <Checkbox />
                  </th>
                  <th>SKU</th>
                  <th>Name</th>
                  <th>Generic name</th>
                  <th>Formulation</th>
                  <th>Strength</th>
                  <th>Mode</th>
                  <th>Category</th>
                  <th>Reorder limit</th>
                  <th>Drug type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.results?.length == 0 && <p>No dataset</p>}
                {data?.results?.length > 0 &&
                  data?.results?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <Checkbox />
                      </td>

                      <td>{item?.sku}</td>
                      <td>{item?.drug_name}</td>

                      <td>{item?.generic_name}</td>
                      <td>{item?.formulation}</td>
                      <td>{`${item?.strength}${item?.unit}`}</td>
                      <td>{item?.mode}</td>
                      <td>{item?.category}</td>
                      <td>{item?.reorder_quantity}</td>
                      <td>{item?.drug_type}</td>
                      <td
                        id={index}
                        className="three-dots"
                        onClick={() => showModal(item)}
                      >
                        ...
                        {item?.id === idOfTable ? (
                          <div ref={ref} className="popup">
                            <p onClick={() => setEditDataset(!editDataset)}>
                              View/Edit dataset
                            </p>

                            <p
                              onClick={() =>
                                useHandleDialogueDelete(
                                  deleteDataset,
                                  `Are you sure you want delete ${item.generic_name}, Process cannot be reversed?`
                                )
                              }
                              className="text-danger"
                            >
                              Delete
                            </p>
                          </div>
                        ) : null}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="pagination-container">
              {data && data?.results?.length > 0 && (
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={data?.count}
                  pageSize={PAGESIZE}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              )}
            </div>
          </div>
        )}
        {editDataset && (
          <CreateDataset
            currData={currData}
            onClick={() => setEditDataset(!editDataset)}
            facilityId={state?.id}
          />
        )}

        {createDataset && (
          <CreateDataset
            onClick={() => setCreateDataset(!createDataset)}
            facilityId={state?.id}
          />
        )}

        {isModalOpen && (
          <ImportPharmacyDataset
            setIsModalOpen={setIsModalOpen}
            isModalOpen={isModalOpen}
            facilityId={state?.id}
          />
        )}
      </AccordionWrapper>
    </Layout>
  );
}

const AccordionWrapper = styled.div`
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40vh;
  }
`;
