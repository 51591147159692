import React, { useState } from 'react';
import TabNavigation from '@components/TabNavigation';
import styled from 'styled-components';
import Header from '../../common/Header';
import Layout from '../dashboardSidebar/Layout';
import ResultParam from './ResultParam';
import Investigation from './Investigation';
import InvestigationCategories from './categories';

const BillingComponent = () => {
  const [activeTab, setActiveTab] = useState('investigation');
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  const tabs = [
    {
      id: 'investigation',
      label: 'Investigation',
      content: <Investigation />,
    },
    {
      id: 'resultparam',
      label: 'Result Param',
      content: <ResultParam />,
    },
    {
      id: 'categories',
      label: 'Categories',
      content: <InvestigationCategories />,
    },
  ];

  return (
    <Layout>
      <Header heading="options list |" />
      <BillingWrapper>
        <TabNavigation
          tabs={tabs}
          activeTab={activeTab}
          onTabClick={handleTabClick}
        />
      </BillingWrapper>
    </Layout>
  );
};

export default BillingComponent;

const BillingWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;
