import React, { useState } from 'react';
import styled from 'styled-components';
import TabNavigation from '@components/TabNavigation';
import StaffManagement from './StaffManagement';

const PlanWrapper = styled.div`
  .sub-title {
    color: #2254d3;
    font-size: 16px;
    font-weight: 500;
  }
  .sub-plan-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const UserTab = () => {
  const [activeTab, setActiveTab] = useState('thcadmin');

  const tabs = [
    { id: 'thcadmin', label: 'THC ADMIN', content: <StaffManagement /> },
    {
      id: 'facilityadmin',
      label: 'FACILITY ADMIN',
      content: <StaffManagement />,
    },
  ];

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <PlanWrapper isActive={activeTab}>
      <TabNavigation
        tabs={tabs}
        activeTab={activeTab}
        onTabClick={handleTabClick}
      />
    </PlanWrapper>
  );
};
