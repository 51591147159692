import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AuthRoutes from './main/authRoutes';
import store from '../redux/store';
import ThcMgtRoutes from './main/thcmgtRoute';
import 'react-toastify/dist/ReactToastify.css';

import { QueryClient, QueryClientProvider } from 'react-query';
const queryClient = new QueryClient({});
const Root = () => (
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <Provider store={store}>
        <BrowserRouter>
          <ToastContainer
            autoClose={1000}
            limit={1}
            style={{ fontSize: '16px' }}
          />
          <AuthRoutes />
          <ThcMgtRoutes />
        </BrowserRouter>
      </Provider>
    </Provider>
  </QueryClientProvider>
);

export default Root;
