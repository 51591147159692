import Package from '../Package';

export default function Index() {
  return (
    <Package
      url="plan/diagnostic-plans"
      createUrl="utils/create-health-plan-service-for-a-facility-type"
      editUrl="utils/plan/diagnostic-plan/retrieve-update-destroy"
      deleteUrl="diagnostic-plan"
      facilityType="DIAGNOSTIC"
    />
  );
}
