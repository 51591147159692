import React, { useState, useRef, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Pagination from '@utils/pagination';
import AddNewStaff from './AddNewStaff';
import UpdateStaffRole from './UpdateStaffRole';
import CustomSelect from '@components/Select/Select';
import { SearchStyle } from '@components/formInputs/CustomSearch';
import request, { headers } from '@request';
import '@styles/table.style.css';
import { toast } from 'react-toastify';
import useDialog from '@hooks/useDialog';
import { serialNum, pageSummaryEnhanced } from '@utils/pageSummary';
import FormModal from '@components/modalComponents/NewModal';
import ModalTitle from '@components/modalComponents/FormTitleSection';
import { useFetchTHCStaff } from '../../../hooks/queries';

const pageSize = 10;
export default function StaffManagement({ isActive }) {
  const [openModal, setOpenModal] = useState(false);
  const [staffRoleModal, setStaffRoleModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [idOfTable, setIdOfTable] = useState(-1);
  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIdOfTable(-1);
    }
  };
  const [currItem, setCurrItem] = useState(null);
  const showModal = (item) => {
    setCurrItem(item);
    setIdOfTable(item.id);
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });
  const { deleteStaff } = useDialog();
  const { data, refetch } = useFetchTHCStaff();
  const staffData = data?.data?.data?.data;

  let startSeriaNum = serialNum(currentPage, pageSize);

  // const handSendInvitation = async () => {
  //   let token = localStorage.getItem('token');
  //   try {
  //     await request.patch(`/main/send-email/${idOfTable}/`, {}, headers(token));
  //     toast.success('invitation sent successfully');
  //     refetch();
  //   } catch (err) {
  //     toast.error('invitation not sent');
  //   }
  // };

  const handDeleteStaff = async () => {
    let token = localStorage.getItem('token');
    try {
      await request.delete(
        `utils/thc-admins/${idOfTable}/delete/`,
        headers(token)
      );
      refetch();
      toast.success('Staff deleted successfully');
    } catch (err) {
      toast.error('Cannot delete staff at this time');
    }
  };
  const handleUpdateStaff = () => {
    setStaffRoleModal(!staffRoleModal);
    setIdOfTable(idOfTable);
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    if (staffData?.length > 0) {
      return staffData?.slice(firstPageIndex, lastPageIndex);
    }
  }, [currentPage, staffData]);
  return (
    <StaffWrapper isActive={isActive}>
      <div className="header">
        <h3>MANAGE STAFF</h3>
        <button onClick={() => setOpenModal(true)}>Add new user/staff</button>
      </div>
      <div className="search--wrapper">
        <CustomSelect
          defaultLabel="Sort by"
          options={['Ascending', 'Descending']}
        />
        <SearchStyle>
          <input placeholder="Search for a staff" type="search" onChange />
        </SearchStyle>
      </div>
      <div className="table-container">
        <p className="mt-3">
          {pageSummaryEnhanced(
            startSeriaNum,
            staffData?.length,
            currentTableData
          )}
        </p>
        <table>
          <thead>
            <tr>
              <th>User/Staff name</th>
              <th>Country</th>
              <th>Email</th>
              <th>Phone number</th>
              <th>Is superuser</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentTableData && currentTableData.length > 0 ? (
              currentTableData?.map((item) => (
                <tr key={item.id}>
                  <td>
                    <img
                      className="table-image"
                      src="https://thc-s3-bucket.s3.amazonaws.com/images/avatar.png"
                      alt={item?.first_name}
                    />
                    {item?.first_name} {item?.last_name}
                  </td>
                  <td>{item?.country}</td>
                  <td>{item?.email}</td>
                  <td>{item?.phone_number}</td>
                  <td>{item?.is_superuser ? 'Yes' : 'No'}</td>
                  <td className="three-dots" onClick={() => showModal(item)}>
                    ...
                    {item.id === idOfTable ? (
                      <div ref={ref} className="popup">
                        {/* <div onClick={handSendInvitation} className="pop-text">
                          Send Invitation Email
                        </div> */}
                        <div
                          onClick={() => handleUpdateStaff(item)}
                          className="pop-text"
                        >
                          Update user
                        </div>
                        {/* <div className="pop-text">View Staff Profile</div> */}
                        <div
                          onClick={() =>
                            deleteStaff(
                              handDeleteStaff,
                              item.id,
                              item.first_name
                            )
                          }
                          className="red pop-text"
                        >
                          Delete Staff
                        </div>
                      </div>
                    ) : null}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>No staff to display</td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="pagination-container">
          {staffData && staffData?.length > 0 && (
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={staffData?.length}
              pageSize={pageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          )}
        </div>
      </div>
      {staffRoleModal && (
        <div className={'form-modal'}>
          <FormModal handleModal={handleUpdateStaff}>
            <UpdateStaffRole
              closeForm={() => setStaffRoleModal(false)}
              item={currItem}
            />
          </FormModal>
        </div>
      )}
      {openModal && (
        <div className={'form-modal'}>
          <FormModal height="400px" handleModal={() => setOpenModal(false)}>
            <ModalTitle
              title="Add new staff"
              onClick={() => setOpenModal(false)}
            />
            <AddNewStaff closeForm={() => setOpenModal(false)} />
          </FormModal>
        </div>
      )}
    </StaffWrapper>
  );
}

const StaffWrapper = styled.div`
  background-color: ${(props) =>
    props.isActive === 'staff-management' ? '#F5F8FE' : ''};
  box-sizing: border-box;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  & .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    & h3 {
      font-family: 'Sofia Pro';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 1px;
      letter-spacing: 0.1em;
      color: #2254d3;
      @media screen and (max-width: 425px) {
        font-size: 10px;
      }
    }

    & button {
      min-width: 89.64px;
      height: 25px;
      background: #071232;
      border-radius: 5px;
      font-family: 'Sofia Pro';
      font-style: normal;
      font-weight: 500;
      font-size: 11px;

      color: #ffffff;
      outline: none;
      border: none;
      @media screen and (max-width: 425px) {
        font-size: 9px;
      }
    }

    @media screen and (max-width: 325px) {
      justify-content: flex-start;
      gap: 20px;
    }
  }
  .form-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
  }

  & .search--wrapper {
    display: grid;
    grid-template-columns: 1fr 5fr;
    gap: 15px;
  }

  @media screen and (max-width: 425px) {
    padding: 40px 5px;
  }
  .popup {
    position: absolute;
    width: 175px;
    right: 50px;
    /* top: -70px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    padding: 1rem;
    border: 1px solid rgba(33, 51, 79, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    z-index: 3;
    border-radius: 10px;
    z-index: 1;
    /* overflow-y: auto; */

    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #071232;
    .pop-text {
      padding: 5px;
    }

    .pop-text:hover {
      display: inline-block;
      font-weight: 700;
      cursor: pointer;
    }
  }
  .red {
    color: #ff0000;
  }
`;
