// payment-mode/
// payment-mode/<uuid:pk>/update
import {
  FETCH_PAYMENT_MODE_SUCCESS,
  FETCH_PAYMENT_MODE_FAILED,
  UPDATE_PAYMENT_MODE_SUCCESS,
  UPDATE_PAYMENT_MODE_FAILED,
  FETCH_TRANSACTION_SUCCESS,
  FETCH_TRANSACTION_FAILED,
  UPDATE_TRANSACTION_SUCCESS,
  UPDATE_TRANSACTION_FAILED,
  LOADING,
} from '../type';
import { retrieveErrMessage } from '@utils/helper';
import request, { headers } from '@request';
import { toast } from 'react-toastify';

const fetchPaymentModeAction = (facility, plan) => async (dispatch) => {
  let token = localStorage.getItem('token');
  dispatch({ type: LOADING, payload: true });
  try {
    const res = await request.get(
      `/utils/payment-mode/${facility}/${plan}/`,
      headers(token)
    );
    dispatch({
      type: FETCH_PAYMENT_MODE_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    const err = retrieveErrMessage(error);
    dispatch({
      type: FETCH_PAYMENT_MODE_FAILED,
      payload: {},
      error: err,
    });
  }
};

export const updatePaymentMode = (id, payload) => async (dispatch) => {
  let token = localStorage.getItem('token');
  dispatch({ type: LOADING, payload: true });
  try {
    const res = await request.patch(
      `/utils/payment-mode/${id}/`,
      payload,
      headers(token)
    );

    dispatch({
      type: UPDATE_PAYMENT_MODE_SUCCESS,
      payload: res.data,
    });
    toast.success('payment mode update');
  } catch (error) {
    const err = retrieveErrMessage(error);
    dispatch({
      type: UPDATE_PAYMENT_MODE_FAILED,
      payload: {},
      error: err,
    });
  }
};

export default fetchPaymentModeAction;

export const fetchTransaction = (facility) => async (dispatch) => {
  let token = localStorage.getItem('token');
  dispatch({ type: LOADING, payload: true });
  try {
    const res = await request.get(
      `/utils/transaction-charge/${facility}/`,
      headers(token)
    );
    dispatch({
      type: FETCH_TRANSACTION_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    const err = retrieveErrMessage(error);
    dispatch({
      type: FETCH_TRANSACTION_FAILED,
      payload: {},
      error: err,
    });
  }
};

export const updateTransaction = (payload) => async (dispatch) => {
  let token = localStorage.getItem('token');
  dispatch({ type: LOADING, payload: true });
  try {
    const res = await request.patch(
      `/utils/transaction-charge/update/`,
      payload,
      headers(token)
    );

    dispatch({
      type: UPDATE_TRANSACTION_SUCCESS,
      payload: res.data,
    });
    toast.success('payment mode update');
  } catch (error) {
    const err = retrieveErrMessage(error);
    dispatch({
      type: UPDATE_TRANSACTION_FAILED,
      payload: {},
      error: err,
    });
  }
};
