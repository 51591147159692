import React from 'react';
import { createRoot } from 'react-dom/client';
import './styles/index.css';
import './styles/scrollbar.css';
import store from './redux/store';
import Root from './routes/Root';

const container = document.getElementById('root');

const root = createRoot(container);
root.render(<Root store={store} />);
