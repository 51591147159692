import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import ClipLoader from 'react-spinners/ClipLoader';
import request, { headers } from '@request';
import SubmitSuccess from '@components/SubmitSuccess';
import FormTitleSection from '@components/modalComponents/FormTitleSection';
import Checkbox from '@components/formInputs/checkbox';
import Button from '@components/Button/Button';
import { useFetchTHCStaff } from '../../../hooks/queries';
const UpdateStaffRole = ({ closeForm, item }) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [currStatus, setCurrStatus] = useState({
    is_verified: false,
    is_active: false,
    is_superuser: false,
  });
  const { refetch } = useFetchTHCStaff();

  const token = localStorage.getItem('token');
  useEffect(() => {
    if (item) {
      setCurrStatus({
        is_verified: item.is_verified,
        is_active: item.is_active,
        is_superuser: item.is_superuser,
      });
    }
  }, [item]);

  const onChangeFunction = (name) => {
    const copyD = { ...currStatus };
    setCurrStatus({ ...copyD, [name]: !copyD[name] });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // eslint-disable-next-line
      const res = await request.patch(
        `/utils/thc-admins/${item.id}/deactivate/`,
        currStatus,
        headers(token)
      );
      refetch();
      toast.success('User type updated');
      setSubmitted(true);
    } catch (error) {
      if (!navigator.onLine) {
        toast.error('Network Error');
        setLoading(false);
      }

      setLoading(false);
    }
  };

  return (
    <ScheduleFormWrapper>
      <FormTitleSection onClick={closeForm} title="Update Staff Role" />
      {submitted ? (
        <SubmitSuccess message={`user updated`} />
      ) : (
        <div>
          <form>
            <div className="form--content">
              <div className="form-input-wrapper">
                <Checkbox
                  isChecked={currStatus.is_superuser}
                  label="Is superuser"
                  onChangeFunction={() => onChangeFunction('is_superuser')}
                />
                <Checkbox
                  isChecked={currStatus.is_active}
                  label="Activate user"
                  onChangeFunction={() => onChangeFunction('is_active')}
                />

                <Checkbox
                  isChecked={currStatus.is_verified}
                  label="Verify staff"
                  onChangeFunction={() => onChangeFunction('is_verified')}
                />
              </div>
              <div className="checkbox-area">
                <Button type="submit" onClick={onSubmit}>
                  {loading ? (
                    <ClipLoader
                      type="ThreeDots"
                      color="#00BFFF"
                      height={20}
                      width={20}
                    />
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            </div>
          </form>
        </div>
      )}
    </ScheduleFormWrapper>
  );
};

export default UpdateStaffRole;

const ScheduleFormWrapper = styled.div`
  background: #fff;
  padding: 20px;

  & .form--heading {
    & p {
      font-family: 'Sofia Pro';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      padding: 0;
      margin: 0;
      color: #2254d3;
    }
  }

  .hide {
    display: none;
  }
`;
