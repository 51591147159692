import React, { Suspense } from 'react';
import { Switch } from 'react-router-dom';
import MyLoader from '../../utils/loader';
// import Doctor from '../../pages/doctors/index'
import ErrorBoundary from '../../components/ErrorBoundary';
import ProtectedRoute from './ProtectedRoute';
import Countries from '../../thcmgt/generalPages/countries';
import Forums from '../../thcmgt/generalPages/forums';
import Option from '../../thcmgt/generalPages/optionList';
import Users from '../../thcmgt/generalPages/users';
import Settings from '../../thcmgt/countrySpecificPages/settings';
import { useSelector } from 'react-redux';
import Dashboard from '../../thcmgt/countrySpecificPages/dashboard';
import Hospital from '../../thcmgt/countrySpecificPages/hospital';
import Diagnostic from '../../thcmgt/countrySpecificPages/diagnostic';
import Pharmacy from '../../thcmgt/countrySpecificPages/pharmacy';
import NGO from '../../thcmgt/countrySpecificPages/ngo';
import Subscriptions from '../../thcmgt/countrySpecificPages/subscriptions';
import Independent from '../../thcmgt/countrySpecificPages/independent';
import Insurance from '../../thcmgt/countrySpecificPages/insurance';
import Plans from '../../thcmgt/countrySpecificPages/plans';
import Transactions from '../../thcmgt/countrySpecificPages/transactions';
import Patient from '../../thcmgt/countrySpecificPages/patient';
import CurrencyActivation from '../../thcmgt/generalPages/countryActivation';
import FacilityDataset from '../../thcmgt/countrySpecificPages/FacilityDataset';

const thcMgtRoutes = () => {
  const { name } = useSelector((state) => state.country);
  return (
    <ErrorBoundary>
      <Suspense
        fallback={
          <div>
            <MyLoader />
          </div>
        }
      >
        <>
          <Switch>
            <ProtectedRoute
              exact
              path={`/thc-admin-general/countries`}
              component={Countries}
            />
            <ProtectedRoute
              exact
              path={`/thc-admin-general/forums`}
              component={Forums}
            />
            <ProtectedRoute
              exact
              path={`/thc-admin-general/result-parameters`}
              component={Option}
            />
            <ProtectedRoute
              exact
              path={`/thc-admin-general/countries-activation`}
              component={CurrencyActivation}
            />
            <ProtectedRoute
              exact
              path={`/thc-admin-general/users`}
              component={Users}
            />
            <ProtectedRoute
              exact
              path={`/thc-admin/${name}/dashboard`}
              component={Dashboard}
            />
            <ProtectedRoute
              exact
              path={`/thc-admin/${name}/hospitals`}
              component={Hospital}
            />
            <ProtectedRoute
              exact
              path={`/thc-admin/${name}/diagnostics`}
              component={Diagnostic}
            />

            <ProtectedRoute
              exact
              path={`/thc-admin/${name}/facility-dataset/:id`}
              component={FacilityDataset}
            />

            <ProtectedRoute
              exact
              path={`/thc-admin/${name}/pharmacies`}
              component={Pharmacy}
            />
            <ProtectedRoute
              exact
              path={`/thc-admin/${name}/ngos`}
              component={NGO}
            />
            <ProtectedRoute
              exact
              path={`/thc-admin/${name}/subscriptions`}
              component={Subscriptions}
            />
            <ProtectedRoute
              exact
              path={`/thc-admin/${name}/independents`}
              component={Independent}
            />
            <ProtectedRoute
              exact
              path={`/thc-admin/${name}/insurances`}
              component={Insurance}
            />
            <ProtectedRoute
              exact
              path={`/thc-admin/${name}/mobile-app-users`}
              component={Patient}
            />
            <ProtectedRoute
              exact
              path={`/thc-admin/${name}/plans`}
              component={Plans}
            />
            <ProtectedRoute
              exact
              path={`/thc-admin/${name}/transactions`}
              component={Transactions}
            />
            <ProtectedRoute
              exact
              path={`/thc-admin/${name}/settings`}
              component={Settings}
            />
          </Switch>
        </>
      </Suspense>
    </ErrorBoundary>
  );
};

export default thcMgtRoutes;
