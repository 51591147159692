import React from 'react';
import ErrorPage from './ErrorPage';

interface Props {
  children: any;
  hasError?: Boolean;
}

class ErrorBoundary extends React.Component<Props> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.props.hasError) {
      // You can render any custom fallback UI
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
