export const SIGNUP = 'SIGNUP';
export const SIGNUP_FAILED = 'SIGNUP_FAILED';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';

export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_END = 'LOGIN_FAILED';
export const IS_LOGIN_IN = 'IS_LOGIN_IN';

export const LOGOUT = 'LOGOUT';
export const LOADING = 'LOADING';

export const FETCH_STAFF_SUCCESS = 'FETCH_STAFF_SUCCESS';
export const FETCH_STAFF_FAILED = 'FETCH_STAFF_FAILED';

export const FETCH_BILLS_SUCCESS = 'FETCH_BILLS_SUCCESS';
export const FETCH_BILLS_FAILED = 'FETCH_BILLS_FAILED';

export const GET_BANKS_SUCCESS = 'GET_BANKS_SUCCESS';
export const GET_BANKS_FAILED = 'GET_BANKS_FAILED';

export const FETCH_HOSPITAL_SERVICES_SUCCESS =
  'FETCH_HOSPITAL_SERVICES_SUCCESS';
export const FETCH_HOSPITAL_SERVICES_FAILED = 'FETCH_HOSPITAL_SERVICES_FAILED';

export const FETCH_HOSPITAL_LOCATION_SUCCESS =
  'FETCH_HOSPITAL_LOCATION_SUCCESS';
export const FETCH_HOSPITAL_LOCATION_FAILED = 'FETCH_HOSPITAL_LOCATION_FAILED';

export const VALIDATED = 'VALIDATED';

export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS';
export const FETCH_SERVICES_FAILED = 'FETCH_SERVICES_FAILED';
export const FETCH_SERVICE_CAT_SUCCESS = 'FETCH_SERVICE_CAT_SUCCESS';
export const FETCH_SERVICE_CAT_FAILED = 'FETCH_SERVICE_CAT_FAILED';

export const FETCH_FACILITIES_SUCCESS = 'FETCH_FACILITIES_SUCCESS';
export const FETCH_FACILITIES_FAILED = 'FETCH_FACILITIES_FAILED';

// subscription
export const FETCH_PAYMENT_MODE_SUCCESS = 'FETCH_FACILITIES_SUCCESS';
export const FETCH_PAYMENT_MODE_FAILED = 'FETCH_FACILITIES_FAILED';
export const UPDATE_PAYMENT_MODE_SUCCESS = 'UPDATE_PAYMENT_MODE_SUCCESS';
export const UPDATE_PAYMENT_MODE_FAILED = 'UPDATE_PAYMENT_MODE_FAILED';

// subscription
export const FETCH_TRANSACTION_SUCCESS = 'FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_FAILED = 'FETCH_TRANSACTION_FAILED';
export const UPDATE_TRANSACTION_SUCCESS = 'UPDATE_TRANSACTION_SUCCESS';
export const UPDATE_TRANSACTION_FAILED = 'UPDATE_TRANSACTION_FAILED';

// fetch facility  base on country of user
export const FETCH_COUNTRY_SUCCESS = 'FETCH_COUNTRY_SUCCESS';
export const FETCH_COUNTRY_FAILED = 'FETCH_COUNTRY_FAILED';
