import { LOGIN_FAILED, LOGIN_SUCCESS, IS_LOGIN_IN } from '../../actions/type';
import jwt_decode from 'jwt-decode';

const loginState = { token: '', errors: '', user_type: '', isLoading: false };

const LoginReducer = (state = loginState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: payload.data,
      };

    case IS_LOGIN_IN:
      return {
        ...state,
        isLoading: true,
      };
    case LOGIN_SUCCESS:
      const decode = jwt_decode(payload.data.access);
      if (decode.is_verified === false) {
        return {
          ...state,
          isLoading: false,
          token: payload.data.access,
          errors: 'Please verify your email',
        };
      }
      // console.log('payload', payload);
      localStorage.setItem('token', payload.data.access);
      localStorage.setItem('refreshToken', payload.data.refresh);
      localStorage.setItem('user_details', JSON.stringify(decode));
      localStorage.setItem('user_type', decode.role);
      localStorage.setItem('is_admin', decode.is_admin);
      localStorage.setItem('is_hospital', decode.is_hospital);
      localStorage.setItem('facility', decode.user_type);
      if (decode.email.includes('totalhealthcare360.com')) {
        localStorage.setItem('facilityType', 'totalhealthcare');
        window.location.href = `/thc-admin-general/countries`;
      }

      return {
        ...state,
        token: payload.data.data,
      };
    default:
      return state;
  }
};

export default LoginReducer;
