import React, { useState, useEffect } from 'react';
import TabNavigation from '@components/TabNavigation';
import { tabs } from './tabData';
import { PlanWrapper } from './containerStyle';
import ClipLoader from 'react-spinners/ClipLoader';
import { useFetchPlans } from '@hooks/plans';
import PackageServicesTableData from './PackageServicesTableData';
import Button from '@components/Button/Button';
import CreateNew from './CreateNew';
import PaymentInfo from './PaymentInfo';

const Package = ({ url, editUrl, facilityType, deleteUrl }) => {
  const { data: plansss, isLoading: loading, refetch } = useFetchPlans(url);
  const [createService, setCreateService] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState(false);

  const plans = plansss?.data?.data;

  const [activeTab, setActiveTab] = useState('BASIC');
  const [activePlan, setActivePlan] = useState([]);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  useEffect(() => {
    if (plans && plans.length > 0) {
      setActivePlan(plans.filter((plan) => plan.plan_type === activeTab));
    }
  }, [plans, activeTab]);

  return (
    <PlanWrapper isActive={activeTab}>
      <TabNavigation
        tabs={tabs}
        activeTab={activeTab}
        onTabClick={handleTabClick}
      />

      {loading === true && (
        <div className="spin-parent">
          <ClipLoader color="blue" size="40px" className="spinner" />
        </div>
      )}
      <div className="grid grid-col-2 align-items-center mt-2 mb-2">
        <div className="wp-50">
          <Button
            backgroundColor="black"
            onClick={() => setPaymentInfo(!paymentInfo)}
            width="200px"
          >
            View/Edit Payment info
          </Button>
        </div>
        <div>
          <Button
            backgroundColor="black"
            onClick={() => setCreateService(!createService)}
            width="200px"
          >
            Create New Service
          </Button>
        </div>
      </div>

      <PackageServicesTableData
        tableData={plans}
        activePlan={activePlan}
        setActivePlan={setActivePlan}
        refetch={refetch}
        deleteUrl={deleteUrl}
        editUrl={editUrl}
      />

      {loading === false && activePlan.length === 0 && (
        <p>No service for this plan yet</p>
      )}

      {createService && (
        <CreateNew
          createService={createService}
          setCreateService={setCreateService}
          refetch={refetch}
        />
      )}
      {paymentInfo && (
        <PaymentInfo
          paymentInfo={paymentInfo}
          setPaymentInfo={setPaymentInfo}
          facilityType={facilityType}
          planType={activeTab}
        />
      )}
    </PlanWrapper>
  );
};

export default Package;
