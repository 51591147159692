import React from 'react';
import Button from '@components/Button/AddButton';
import Select from '@components/Select/Select';
import styled from 'styled-components';
import CustomSearch from '@components/formInputs/CustomSearch';

const PageTop = ({
  placeholder,
  handleChange,
  countryPage,
  activateCountry,
}) => {
  const sortOptions = ['Ascending', 'Descending'];
  return (
    <PatientStyle>
      <div className="item filter">
        <Select defaultLabel={'Sort by'} options={sortOptions} />
      </div>

      <div className="item search">
        <CustomSearch handleChange={handleChange} placeholder={placeholder} />
      </div>
      {countryPage && (
        <div className="item addbtn">
          <Button
            onClick={activateCountry}
            title="Add a country"
            display="none"
            height="39px"
          />
        </div>
      )}
    </PatientStyle>
  );
};

export default PageTop;

const PatientStyle = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  .item {
    width: 100%;
  }
  .filter {
    max-width: 20%;
  }
  .addbtn {
    max-width: 20%;
  }
  .sms {
    max-width: 15%;
  }
  .schedule {
    max-width: 20%;
  }
  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  @media (max-width: 768px) {
    font-size: 9px;
  }
`;
