import Header from '../../common/Header';
import Layout from '../dashboardSidebar/Layout';
import Forum from './Table';

export default function Index() {
  return (
    <Layout>
      <Header heading="forums | " />

      <div>
        <Forum />
      </div>
    </Layout>
  );
}
