import styled from 'styled-components';

export const TabNavigationWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.isActive === 'history' ? '0px' : '10px')};
  & input::placeholder {
    color: #3968df;
    opacity: 0.7;
  }
  & input:-ms-input-placeholder {
    color: #3968df;
    opacity: 0.7;
  }
  & input::-ms-input-placeholder {
    color: #3968df;
    opacity: 0.7;
  }

  & .tab {
    box-sizing: border-box;
    display: flex;
    gap: 50px;
    border-bottom: 1px solid #e6e8ec;
    padding: 0px 30px;
    background-color: ${(props) =>
      props.isActive === 'history' ? '#F5F8FE' : ''};
    border-radius: ${(props) =>
      props.isActive === 'history' ? '5px' : 'none'};
    margin-top: ${(props) => (props.isActive === 'history' ? '20px' : '0px')};

    .isActiveTab {
      color: #2254d3;
      border-bottom: 3px solid #2254d3;
    }

    & p {
      margin: 0;
      font-family: 'Sofia Pro';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 19px;
      color: #8e919c;
      cursor: pointer;
      @media screen and (max-width: 425px) {
        font-size: 9px;
      }
    }
  }

  & .billing--option--wrapper {
    display: flex;
    flex-direction: column;
    /* border: 1px solid red; */
    gap: 20px;

    & .billing--option--header {
      box-sizing: border-box;
      padding: 20px 0px 40px 0px;
      border-bottom: 1px solid #e6e8ec;
      margin: 0 30px;

      & .heading--one {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;

        & h3 {
          font-family: 'Sofia Pro';
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
          /* identical to box height */

          letter-spacing: 0.1em;

          /* Blue */

          color: #2254d3;
          padding: 0;
          margin: 0;

          @media screen and (max-width: 425px) {
            font-size: 11px;
          }
        }

        & .header--actions {
          display: flex;
          align-items: center;
          gap: 10px;

          & button {
            width: 79px;
            height: 25px;

            font-family: 'Sofia Pro';
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 19px;
            /* identical to box height */

            /* White */

            color: #ffffff;
            border-radius: 5px;
            cursor: pointer;
            outline: none;
            border: none;
            display: grid;
            place-items: center;
          }

          & button:first-of-type {
            background: #071232;
          }
          & button:last-of-type {
            background: #2254d3;
          }

          @media screen and (max-width: 425px) {
            flex-wrap: wrap;
          }
        }

        @media screen and (max-width: 425px) {
          flex-wrap: wrap;
        }
      }

      & .header--two {
        /* display: grid;
        grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
        grid-column-gap: 6px;
        grid-row-gap: 6px;
        column-gap: 10px;
        row-gap: 10px;
        column-gap: 5px; */

        & form {
          display: flex;
          gap: 10px;
        }

        & .inputs {
          height: 80px;

          & label {
            font-family: 'Sofia Pro';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 19px;
            /* identical to box height */

            /* Black */
            color: #071232;
          }
          /* Chrome, Safari, Edge, Opera */
          &input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          & input[type='number'] {
            -moz-appearance: textfield;
          }
          & input {
            background: #dfe8fc;
            border-radius: 5px;
            height: 40px;
            border: none;
            outline: none;
            padding: 0 10px;
            font-family: 'Sofia Pro';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            /* identical to box height */

            /* Blue */

            color: #2254d3;

            @media screen and (max-width: 425px) {
              margin: 0;
              padding: 0;
              font-size: 10px;
              line-height: 0px;
            }
          }
        }

        @media screen and (max-width: 325px) {
          grid-template-columns: 1fr;
        }
      }

      @media screen and (max-width: 425px) {
        margin: 0;
        padding: 0;
      }
    }
  }

  @media screen and (max-width: 372px) {
    padding: 30px 0px;
    width: 100%;
  }

  /* pop up */
  .popup {
    position: absolute;
    min-width: 200px;
    /* max-height: 150px; */
    /* right: 50px;
    top: 40px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    padding: 1rem;
    border: 1px solid rgba(33, 51, 79, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    z-index: 10000;
    border-radius: 10px;
    /* z-index: 100; */
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin-left: 40px;
    color: #071232;
    p {
      padding: 5px 3px;
      &:hover {
        background: #2254d3;
        color: #fff;
        cursor: pointer;
      }
    }
  }
`;
