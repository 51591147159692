const pageInfoReducer = () => {
  const pageUrl = window.location.pathname;
  // get the last part of the url
  const splitUrl = pageUrl.split('/');
  const pageType = splitUrl[splitUrl.length - 1];
  let userType = '';
  const isAdmin = localStorage.getItem('is_admin');
  isAdmin === 'true'
    ? (userType = 'admin')
    : (userType = localStorage.getItem('user_type'));
  // get user details from localStorage
  const userDetails = JSON.parse(localStorage.getItem('user_details'));
  const facilityType = localStorage.getItem('facilityType');
  const userId = userDetails?.user_id;
  const userEmail = userDetails?.email;
  const firstName = userDetails?.first_name;
  const lastName = userDetails?.last_name;
  const practiceName = userDetails?.practice_name;
  const phoneNumber = userDetails?.phone_number;
  return {
    pageType: pageType,
    userType: userType,
    userId: parseInt(userId),
    userEmail: userEmail,
    firstName: firstName,
    lastName: lastName,
    practiceName: practiceName,
    facilityType: facilityType,
    phoneNumber: phoneNumber,
  };
};

export default pageInfoReducer;
