import {
  FETCH_FACILITIES_SUCCESS,
  LOADING,
  FETCH_FACILITIES_FAILED,
} from '../../actions/type';
const initialState = {
  errors: '',
  loading: false,
  data: [],
};

const allFacilitiesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOADING:
      return {
        ...state,
        loading: payload,
      };
    case FETCH_FACILITIES_SUCCESS:
      return {
        ...state,
        data: payload.data,
        errors: '',
        loading: false,
      };
    case FETCH_FACILITIES_FAILED:
      return {
        ...state,
        data: [],
        errors: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default allFacilitiesReducer;
