import React from 'react';
import Layout from '../dashboardSidebar/Layout';
import Header from '../../common/Header';
import PageTop from '../../common/PageTops';
import styled from 'styled-components';
import greeting from '@utils/greeting';
import { useSelector } from 'react-redux';
import { FiArrowRight } from 'react-icons/fi';
// import { colors } from '@assets/colors/theme';
import { useFetchActiveCountries } from '@hooks/queries';

const Index = () => {
  const { firstName } = useSelector((state) => state.pageInfo);

  const onSetCountry = (item) => {
    localStorage.setItem('country', JSON.stringify(item));
    window.location.href = `/thc-admin/${item.country}/dashboard`;
  };
  const { data, isLoading } = useFetchActiveCountries();
  const countriesData = data?.data?.data?.data;
  return (
    <Layout>
      <StyledWrapper>
        <Header
          // practiceName={practiceName}
          heading="countries | "
          subheading={`${firstName}`}
          greeting={greeting()}
        />
        <div className="mt-2">
          <PageTop />
        </div>
        <div className="countries">
          {isLoading && <p>Loading...</p>}
          {data && countriesData.length > 0 ? (
            countriesData.map((item) => (
              <div onClick={() => onSetCountry(item)} className="country-card">
                <img src={item.flag} alt={item.country} />
                <p>{item.country}</p>
                <FiArrowRight />
              </div>
            ))
          ) : (
            <p>No active country</p>
          )}
        </div>
      </StyledWrapper>
    </Layout>
  );
};

export default Index;

const StyledWrapper = styled.div`
  .countries {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1rem;
  }
  .country-card {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 10px;
    background-color: #f3f4f8;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 1rem;
    }
  }
`;
