import React, { useRef, useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import '@styles/table.style.css';
import Pagination from '@utils/pagination';
import { serialNum, pageSummaryEnhanced } from '@utils/pageSummary';
import { PAGESIZE } from '@constant/pageSize';
import ClipLoader from 'react-spinners/ClipLoader';
import { FacilityTableStyle } from '../../facilityTableStyle';
import { useFetchActiveCountries } from '@hooks/queries';
import Header from '../../common/Header';
import PageTop from '../../common/PageTops';
import Layout from '../dashboardSidebar/Layout';
import { toast } from 'react-toastify';
import request, { headers } from '../../../apiUrls/request';
import { ActivateCountry } from './activateCountry';
import { ActivateCurrency } from './countryCurrency';

const pageSize = PAGESIZE;

const PatientTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [activateCountry, setActivateCountry] = useState(false);
  const [activateCurrency, setActivateCurrency] = useState(false);
  const { data, isLoading: loading, refetch } = useFetchActiveCountries();
  const countriesData = data?.data?.data?.data;
  console.log('countries', countriesData);

  const totalCount = countriesData?.length;

  let startSeriaNum = serialNum(currentPage, pageSize);
  const [idOfTable, setIdOfTable] = useState(-1);
  // const history = useHistory();
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIdOfTable(-1);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });
  const [selectedCountry, setSelectedCountry] = useState(null);

  const showModal = (item) => {
    setIdOfTable(item.id);
    setSelectedCountry(item);
  };
  const token = localStorage.getItem('token');

  const deactivate = async (country) => {
    try {
      const res = await request.patch(
        `utils/countries/${idOfTable}/update/`,
        { is_active: false },
        headers(token)
      );
      if (res.status === 200) {
        refetch();
        toast.success(`${country} is now deactivated`);
      } else {
        toast.error(`Error deactivating ${country}`);
      }
    } catch (error) {
      toast.error(`Error activating ${country}`);
    }
  };

  const deleteCurrency = async (code) => {
    try {
      const res = await request.delete(
        `billings/delete-country-currency/${code}/`,
        { is_active: false },
        headers(token)
      );
      console.log('status', res.status);
      if (res.status === 204) {
        refetch();
        toast.success(`${code} is now deactivated`);
      } else {
        toast.error(`Error deleting ${code} currency`);
      }
    } catch (error) {
      toast.error(`Error deleting ${code} currency`);
    }
  };

  // delete-country-currency

  return (
    <Layout>
      <Header heading="Countries Activation" />
      <PageTop
        countryPage
        activateCountry={() => setActivateCountry(!activateCountry)}
      />
      <FacilityTableStyle>
        {loading ? (
          <div className="spin-parent">
            <ClipLoader color="blue" size="40px" className="spinner" />
          </div>
        ) : (
          <div className="">
            <p className="mt-3">
              {pageSummaryEnhanced(startSeriaNum, totalCount, countriesData)}
            </p>
            <table>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Flag</th>
                  <th>Name</th>
                  <th>Transaction currency</th>
                  <th>Subscription currency</th>
                  <th>No. of subscriptions</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data && countriesData?.length > 0 ? (
                  countriesData?.map((item, index) => (
                    <tr key={index}>
                      <td>{startSeriaNum++}</td>
                      <td>
                        <img className="flags" src={item?.flag} />
                      </td>
                      <td>
                        <Link className="patient-name" to="">
                          {item?.country}
                        </Link>
                      </td>
                      <td>{`${item?.country_currency?.transaction_currency}(${item?.country_currency?.transaction_currency_code})`}</td>
                      <td>
                        <td>{`${item?.country_currency?.subscription_currency}(${item?.country_currency?.subscription_currency_code})`}</td>
                      </td>

                      <td>{item?.subscription_length}</td>

                      <td
                        className="three-dots"
                        style={{ width: '9%', position: 'relative' }}
                        onClick={() => showModal(item)}
                      >
                        ...
                        {item?.id === idOfTable ? (
                          <Fragment>
                            <span ref={ref} className="popup">
                              <p
                                style={{
                                  color: '#2254D3',
                                  fontStyle: 'italic',
                                }}
                              >
                                <u>For {item?.country}</u>
                              </p>
                              <p
                                onClick={() =>
                                  setActivateCurrency(!activateCurrency)
                                }
                              >
                                Add/Edit country currency
                              </p>
                              <p
                                className=""
                                onClick={() =>
                                  deleteCurrency(item?.country_code)
                                }
                              >
                                Delete currency
                              </p>

                              <p
                                className=""
                                onClick={() => deactivate(item?.country)}
                              >
                                Deactivate country
                              </p>
                            </span>
                          </Fragment>
                        ) : null}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      <h4 className="title">No hospital found</h4>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="pagination-container">
              {data && countriesData.length > 0 && (
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={totalCount}
                  pageSize={pageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              )}
            </div>
          </div>
        )}

        {/* Activate country */}
        {activateCountry && (
          <ActivateCountry
            activateCountry={activateCountry}
            setActivateCountry={setActivateCountry}
          />
        )}

        {/* Activate country */}
        {activateCurrency && (
          <ActivateCurrency
            activateCurrency={activateCurrency}
            setActivateCurrency={setActivateCurrency}
            data={selectedCountry}
          />
        )}
      </FacilityTableStyle>
    </Layout>
  );
};
export default PatientTable;
