import React from 'react';
import styled from 'styled-components';
// import { FaArrowDown, FaArrowUp } from "react-icons/fa";

interface Props {
  number: string;
  label: string;
  colorchange?: boolean;
  signal?: any;
}
const RectangularBox = ({ number, label, colorchange, signal }: Props) => {
  return (
    <Container>
      <div className="content-container">
        <p className="num">{number}</p>
        <p className="label">{label}</p>
      </div>
      {signal && (
        <div className="right-side">
          <div>
            <p
              style={{
                color: colorchange ? '#FF6F4F' : '#22D389',
                marginRight: '10px',
              }}
            >
              {/* {colorchange ? <FaArrowDown /> : <FaArrowUp />} */}
            </p>
          </div>
          <div>
            <p
              style={{
                color: colorchange ? '#FF6F4F' : '#22D389',
                fontSize: 12,
              }}
            >
              {/* 24% */}
            </p>
          </div>
        </div>
      )}
    </Container>
  );
};

export default RectangularBox;

const Container = styled.div`
  width: 100%;
  height: 90px;
  background: #f3f4f8;
  border-radius: 15px;
  font-family: 'Sofia Pro';
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  .right-side {
    display: flex;
    margin-top: 10px;
  }
  .content-container {
    display: flex;
    flex-direction: column;
    margin-left: 7px;
    margin-top: 5px;
    .num {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 45px;
      color: #071232;
      margin-bottom: -5px;
    }
    .label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #2254d3;
    }
  }
`;
