import React from 'react';
import styled from 'styled-components';
import Layout from '../dashboardSidebar/Layout';
import Header from '../../common/Header';
import { UserTab } from './usetTab';

const index = () => {
  return (
    <Layout>
      <SettingsNavWrapper>
        <Header heading="users | " />
        <div>
          <UserTab />
        </div>
      </SettingsNavWrapper>
    </Layout>
  );
};

const SettingsNavWrapper = styled.div`
  /* padding: 40px 30px; */
  box-sizing: border-box;
`;

export default index;
