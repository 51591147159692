import React from 'react';
import styled from 'styled-components';

interface Props {
  label?: string;
  onChange?: () => void;
  backgroundColor?: string;
  rows?: any;
  name?: string;
  border?: string;
  placeholder?: string;
  value?: string;
  color?: string;
  height?: string;
  disabled?: boolean;
}
const textAreaInput = ({
  onChange,
  backgroundColor,
  rows,
  name,
  border,
  placeholder,
  value,
  color,
  height,
  disabled,
}: Props) => {
  return (
    <TextAreaStyle
      height={height}
      placeholder={placeholder}
      name={name}
      rows={rows ? rows : '2'}
      onChange={onChange}
      value={value}
      backgroundColor={backgroundColor}
      border={border}
      color={color}
      disabled={disabled}
    />
  );
};

interface TextAreaStyleProps {
  backgroundColor?: string;
  border?: string;
  color?: string;
  height?: string;
}
const TextAreaStyle = styled.textarea<TextAreaStyleProps>`
  outline: none;
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#ffffff'};
  border-radius: 5px;
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  /* line-height: 138.5%; */
  color: ${({ color }) => (color ? color : '#364354')};
  width: 100%;
  padding: 5px;
  border: ${(props) =>
    props.border ? '1px solid ' + props.border : '1px solid #2254d3'};
  height: ${({ height }) => height || ''};
  &:disabled {
    background: white;
    color: '#8E919C';
    cursor: not-allowed;
  }
`;

export default textAreaInput;
