import React, { useState } from 'react';
import styled from 'styled-components';
import TabNavigation from '@components/TabNavigation';
import Consultant from './consultant';
import Diagnostic from './diagnostic';
import Hospital from './hospital';
import Insurance from './insurance';
import Pharmacy from './pharmacy';

const PlanWrapper = styled.div`
  .sub-title {
    color: #2254d3;
    font-size: 16px;
    font-weight: 500;
  }
`;

const PlanSubscription = () => {
  const [activeTab, setActiveTab] = useState('hospital');

  const tabs = [
    { id: 'hospital', label: 'HOSPITALS', content: <Hospital /> },
    {
      id: 'diagnostic',
      label: 'DIAGNOSTIC CENTRES',
      content: <Diagnostic />,
    },
    { id: 'pharmacy', label: 'PHARMACIES', content: <Pharmacy /> },
    { id: 'insurance', label: 'INSURANCE', content: <Insurance /> },
    {
      id: 'consultant',
      label: 'INDEPENDENT CONSULTANT',
      content: <Consultant />,
    },
  ];

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <PlanWrapper isActive={activeTab}>
      <p className="sub-title mt-3">TRANSACTION CHARGES</p>
      <TabNavigation
        tabs={tabs}
        activeTab={activeTab}
        onTabClick={handleTabClick}
      />
    </PlanWrapper>
  );
};

export default PlanSubscription;
