import BASE_URL, { headers } from '@request';
import { useQuery } from 'react-query';

const token = localStorage.getItem('token');

export const fetchInvestigation = async () => {
  return await BASE_URL.get(`/utils/investigation/`);
};

export const useFetchInvestigation = () => {
  const data = useQuery(['investigationOptions'], () => fetchInvestigation(), {
    retry: false,
    staleTime: 3000,
  });
  const investigationOption = data?.data?.data?.map((fa) => ({
    label: fa.name,
    value: fa.name,
  }));
  const investigationId = data?.data?.data?.map((fa) => ({
    label: fa.name,
    value: fa.id,
  }));
  return { investigationOption, investigationId, data };
};

export const useFetchInvestigationResultParameter = () => {
  const urlEndpoint = async () => {
    const response = await BASE_URL.get(
      `utils/result-parameter/get-all/`,
      headers(token)
    );
    return response.data;
  };

  return useQuery(['useFetchInvestigationResultParameter'], urlEndpoint);
};

export const useFetchInvestigationsNoPagination = () => {
  const urlEndpoint = async () => {
    const response = await BASE_URL.get(`utils/investigation/`, headers(token));
    return response.data;
  };

  return useQuery(['useFetchInvestigations'], urlEndpoint);
};

export const fetchInvestigationCategory = async () => {
  return await BASE_URL.get(`utils/investigation-category/`);
};

export const useFetchInvestigationCategory = () => {
  const data = useQuery(
    ['investigationCategory'],
    () => fetchInvestigationCategory(),
    {
      retry: false,
      staleTime: 3000,
    }
  );
  const investigationCategoryOptions = data?.data?.data?.map((fa) => ({
    label: fa.name,
    value: fa.name,
  }));
  return investigationCategoryOptions;
};

export const useFetchInvestigationCategories = () => {
  const urlEndpoint = async () => {
    const response = await BASE_URL.get(`utils/investigation-category/`);
    return response.data;
  };

  return useQuery(['useFetchInvestigationCategories'], urlEndpoint);
};

export const useFetchInvestigations = (currentPage, searchKeyWord) => {
  const urlEndpoint = async () => {
    const response = await BASE_URL.get(
      `utils/investigation-name/?page=${currentPage}&search=${searchKeyWord}`,
      headers(token)
    );
    return response.data;
  };

  return useQuery(
    ['useFetchInvestigations', currentPage, searchKeyWord],
    urlEndpoint
  );
};
