import React, { useState } from 'react';
import Layout from '../layout';
import Header from '../../common/Header';
import styled from 'styled-components';
import TabNavigation from '@components/TabNavigation';
import Consultant from './consultant';
import Diagnostic from './diagnostic';
import Hospital from './hospital';
import Insurance from './insurance';
import MobileApp from './mobileApp';
import Pharmacy from './pharmacy';

const Billing = () => {
  const [activeTab, setActiveTab] = useState('hospital');

  const tabs = [
    { id: 'hospital', label: 'HOSPITALS', content: <Hospital /> },
    {
      id: 'diagnostic',
      label: 'DIAGNOSTIC CENTRES',
      content: <Diagnostic />,
    },
    { id: 'pharmacy', label: 'PHARMACIES', content: <Pharmacy /> },
    { id: 'insurance', label: 'INSURANCE', content: <Insurance /> },
    {
      id: 'consultant',
      label: 'INDEPENDENT CONSULTANT',
      content: <Consultant />,
    },
    { id: 'app', label: 'MOBILE APP USERS', content: <MobileApp /> },
  ];

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  return (
    <Layout>
      <Header heading="Subscription plans" />
      <PlanWrapper isActive={activeTab}>
        <TabNavigation
          tabs={tabs}
          activeTab={activeTab}
          onTabClick={handleTabClick}
        />
      </PlanWrapper>
    </Layout>
  );
};
export default Billing;

const PlanWrapper = styled.div`
  .sub-title {
    color: #2254d3;
    font-size: 16px;
    font-weight: 500;
  }
  .sub-plan-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
