import React from 'react';
import styled from 'styled-components';

const AddmoreButton = ({ onClick, title, type, width, disabled }) => {
  return (
    <StyleWrapper
      disabled={disabled}
      onClick={onClick}
      className="add-more-btn-top-sub"
      type={type}
      width={width}
    >
      <span>
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.0977 22C6.57466 22 2.09766 17.523 2.09766 12C2.09766 6.477 6.57466 2 12.0977 2C17.6207 2 22.0977 6.477 22.0977 12C22.0977 17.523 17.6207 22 12.0977 22ZM11.0977 11H7.09766V13H11.0977V17H13.0977V13H17.0977V11H13.0977V7H11.0977V11Z"
            fill="#2254D3"
          />
        </svg>
      </span>
      {title}
    </StyleWrapper>
  );
};

export default AddmoreButton;
const StyleWrapper = styled.button`
  width: ${({ width }) => (width ? width : '213px')};
  height: 30px;
  background: #d8d8d8;
  border-radius: 40px;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #2254d3;
  display: flex;
  align-items: center;
  padding: 0 20px;
  &:disabled {
    background: #c4c4c4;
    cursor: not-allowed;
  }

  span {
    margin-right: 12px;
  }

  .nysos {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;
