import React from 'react';
import Plans from './PlansTab';
import Layout from '../layout';
import Header from '../../common/Header';
// import PageTop from '../../common/PageTops';
import { useSelector } from 'react-redux';
const Billing = () => {
  const { name } = useSelector((state) => state.country);
  return (
    <Layout>
      <Header heading={`${name}: TRANSACTION`} />
      <Plans />
    </Layout>
  );
};
export default Billing;
