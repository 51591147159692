export const customStyles = {
  control: (base) => ({
    ...base,
    height: '37px',
    border: '1px solid #2254d3',
    borderRadius: '10px',
    backgroundColor: '#000000',
    fontSize: '12px',
    fontWeight: '400',
    color: '#ffffff',
    fontFamily: 'Sofia Pro',
    '&:hover': {
      border: '1px solid #E5E5E5',
    },
  }),
  option: (
    provided
    // state
  ) => ({
    ...provided,
    borderBottom: '1px solid #E5E5E5',
    // color: state.isSelected ? '#333333' : '#333333',
    padding: 10,
    fontFamily: 'Sofia Pro',
    fontSize: '12px',
    fontWeight: '400',
    '&:hover': {
      backgroundColor: '#F5F7FA',
    },
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
    boxShadow: 'none',
    border: '1px solid #E5E5E5',
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    borderRadius: 0,
    boxShadow: 'none',
    border: '1px solid #E5E5E5',
    backgroundColor: '#fff',
  }),
  placeholder: (base) => ({
    ...base,
    color: '#ffffff',
    fontFamily: 'Sofia Pro',
    fontSize: '12px',
    fontWeight: '400',
  }),
  singleValue: (base) => ({
    ...base,
    color: '#ffffff',
    fontFamily: 'Sofia Pro',
    fontSize: '12px',
    fontWeight: '400',
  }),
  // remove vertical line in select
  indicatorSeparator: () => ({
    display: 'none',
  }),
};

export const whiteBackground = {
  control: (base) => ({
    ...base,
    height: '37px',
    border: '1px solid #2254d3',
    borderRadius: '5px',
    backgroundColor: '#ffffff',
    fontSize: '12px',
    fontWeight: '400',
    color: '#000',
    fontFamily: 'Sofia Pro',
    // display: 'flex', // Add flex display
    // alignItems: 'center', // Align items vertically center
    '&:hover': {
      border: '1px solid #E5E5E5',
    },
  }),
  option: (
    provided
    // state
  ) => ({
    ...provided,
    borderBottom: '1px solid #E5E5E5',
    // color: state.isSelected ? '#333333' : '#333333',
    // padding: 10,
    fontFamily: 'Sofia Pro',
    fontSize: '12px',
    fontWeight: '400',
    // '&:hover': {
    //   backgroundColor: '#F5F7FA',
    // },
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
    boxShadow: 'none',
    border: '1px solid #E5E5E5',
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    borderRadius: 0,
    boxShadow: 'none',
    border: '1px solid #E5E5E5',
    backgroundColor: '#fff',
  }),
  placeholder: (base) => ({
    ...base,
    color: '#8E919C',
    fontFamily: 'Sofia Pro',
    fontSize: '12px',
    fontWeight: '400',
  }),
  singleValue: (base) => ({
    ...base,
    color: '#000',
    fontFamily: 'Sofia Pro',
    fontSize: '12px',
    fontWeight: '400',
  }),
  // remove vertical line in select
  indicatorSeparator: () => ({
    display: 'none',
  }),
};
