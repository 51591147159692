import BASE_URL, { headers } from '@request';
import { useQuery } from 'react-query';

const token = localStorage.getItem('token');

export const useFetchPlans = (url) => {
  const urlEndpoint = async () => {
    const response = await BASE_URL.get(`utils/${url}/`, headers(token));
    return response.data;
  };

  return useQuery(['useFetchInvestigations', url], urlEndpoint);
};

export const useFetchPaymentModeAction = (facility, plan) => {
  const urlEndpoint = async () => {
    const response = await BASE_URL.get(
      `utils/payment-mode/${facility}/${plan}/`,
      headers(token)
    );
    return response.data;
  };

  return useQuery(['useFetchPaymentModeAction', facility, plan], urlEndpoint);
};
