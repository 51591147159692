import Package from '../Package';

export default function Index() {
  return (
    <Package
      url="plan/hospital-plans"
      createUrl="utils/create-health-plan-service-for-a-facility-type"
      editUrl="utils/plan/hospital-plan/retrieve-update-destroy"
      deleteUrl="hospital-plan"
      facilityType="HOSPITAL"
    />
  );
}
