import styled from 'styled-components';
export const PlanWrapper = styled.div`
  background: #f5f8fe;
  padding: 20px;
  .spin-parent {
    text-align: center;
    border-radius: 5px;
    width: 100%;
    height: 45px;
    margin-top: 40px;
  }

  .payment-mode {
    display: grid;
    grid-template-columns: 1fr 1fr 0.5fr 1fr;
    gap: 10px;
  }
  /* existing styles for the BillingWrapper here */
`;
