import React, { useState, useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';
import { useFetchInvestigationCategories } from '@hooks/investigations';
import Checkbox from '@components/formInputs/checkbox';
import TableLoader from '@components/TableLoader';
// import { SmallButton } from '@components/Button/SmallButton';
import CreateCategory from './CreateCategory';
import { toast } from 'react-toastify';
import request, { headers } from '@request';
import { useHandleDialogueDelete } from '@hooks/useDialog';
import CustomSearch from '@components/formInputs/CustomSearch';
import Button from '@components/Button/Button';

const InvestigationCategories = () => {
  const { data, isLoading, refetch } = useFetchInvestigationCategories();
  const [create, setCreate] = useState(false);
  const [editCat, setEditCat] = useState(false);
  const [searchKeyWord, setSearchKeyWord] = useState('');

  const ref = useRef(null);

  const [idOfTable, setIdOfTable] = useState(-1);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIdOfTable(-1);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const [currData, setCurrData] = useState(null);

  const showModal = (item) => {
    setCurrData(item);
    setIdOfTable(item.id);
  };

  const token = localStorage.getItem('token');
  const deleteTest = async () => {
    try {
      await request.delete(
        `utils/investigation-update-category/${currData.id}/`,
        headers(token)
      );
      toast.success('Category deleted');
    } catch (error) {
      console.log('error', error.response);
      toast.error('Error');
    }
  };

  const searchResult = useMemo(() => {
    return data?.filter((item) =>
      item.name.toLowerCase().includes(searchKeyWord.toLowerCase())
    );
  }, [searchKeyWord, data]);

  return (
    <InvestigationStyle>
      <main className="main-area">
        <div className="d-flex justify-content-between gap-3">
          <CustomSearch
            placeholder="Search..."
            handleChange={(e) => setSearchKeyWord(e.target.value)}
            value={searchKeyWord}
          />

          <Button type="button" onClick={() => setCreate(!create)}>
            Create new category
          </Button>
        </div>
        {isLoading && <TableLoader rows={5} columns={5} />}
        {data && (
          <table>
            <thead>
              <tr>
                <td>
                  <Checkbox color="#2254D3" />
                </td>
                <th>ID</th>
                <th>Name</th>
                <th>Unique identifier</th>
                <th>Description</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {searchResult && searchResult.length > 0 ? (
                searchResult.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Checkbox name={item.id} color="#2254D3" />
                    </td>
                    <td className="w-70">{item?.id?.slice(0, 6)}</td>
                    <td>{item?.name}</td>
                    <td>{item?.unique_id}</td>
                    <td className="foot-note-cell">
                      {item?.description && item?.description.length > 50 ? (
                        <>
                          <span className="short-text">
                            {item?.description?.slice(0, 50)}...
                          </span>
                          <span className="full-text">{item?.description}</span>
                        </>
                      ) : (
                        item?.description
                      )}
                    </td>

                    <td
                      id={item?.id}
                      className="three-dots"
                      onClick={() => showModal(item)}
                    >
                      ...
                      {item?.id === idOfTable ? (
                        <div ref={ref} className="popup">
                          <p onClick={() => setEditCat(!editCat)}>
                            Edit Category
                          </p>
                          <p
                            onClick={() =>
                              useHandleDialogueDelete(
                                deleteTest,
                                `Are you sure you want delete ${currData.name}, Process cannot be reversed?`
                              )
                            }
                            className="text-danger"
                          >
                            Delete test
                          </p>
                        </div>
                      ) : null}
                    </td>
                  </tr>
                ))
              ) : (
                <p>No diagnostic pricing </p>
              )}
            </tbody>
          </table>
        )}
      </main>
      {create && (
        <CreateCategory
          setUpdateCat={setCreate}
          updateCat={create}
          refetch={refetch}
        />
      )}

      {editCat && (
        <CreateCategory
          setUpdateCat={setEditCat}
          updateCat={editCat}
          currentData={currData}
          refetch={refetch}
        />
      )}
    </InvestigationStyle>
  );
};

export default InvestigationCategories;

const InvestigationStyle = styled.div``;
