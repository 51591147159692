import React from 'react';
import styled from 'styled-components';
import { AiOutlinePlus } from 'react-icons/ai';

interface Props {
  title?: string;
  onClick?: () => any;
  backgroundColor?: string;
  borderRadius?: string;
  sBackgroundColor?: string;
  sBorderRadius?: string;
  display?: string;
  height?: string;
}
const AddMoreButton = ({
  title,
  onClick,
  backgroundColor,
  borderRadius,
  sBackgroundColor,
  sBorderRadius,
  display,
  height,
}: Props) => {
  return (
    <Wrapper
      icon={display}
      onClick={onClick}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      height={height}
    >
      {display && (
        <IconWrapper
          icon={display}
          sBackgroundColor={sBackgroundColor}
          sBorderRadius={sBorderRadius}
        >
          <AiOutlinePlus className="icon" />
        </IconWrapper>
      )}
      <p className="add-txt">{title}</p>
    </Wrapper>
  );
};

export default AddMoreButton;

interface StyledProp {
  backgroundColor?: string;
  borderRadius?: string;
  icon?: string;
  sBackgroundColor?: string;
  sBorderRadius?: string;
  height?: string;
}
const Wrapper = styled.div<StyledProp>`
  height: ${({ height }) => (height ? height : '40px')};
  width: 100%;
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#22d389'};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '10px'};
  padding: 10px;
  color: white;
  cursor: pointer;
  position: relative;
  font-weight: 700;

  .add-txt {
    margin: 0;
    margin-left: ${({ icon }) => (!icon ? '' : '40px!important')};
    text-align: ${({ icon }) => (icon ? '' : 'center')};
  }

  @media (max-width: 991px) {
    .add-txt {
      font-size: 12px;
    }
    .icon {
      font-size: 18px;
    }
  }
  @media (max-width: 767px) {
    .add-txt {
      font-size: 12px;
      line-height: 10px;
    }
  }
`;

const IconWrapper = styled.div<StyledProp>`
  display: ${({ icon }) => (icon ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.sBackgroundColor ? props.sBackgroundColor : '#18bd78'};
  border-radius: ${(props) =>
    props.sBorderRadius ? props.sBorderRadius : '10px'};
  height: 25px;
  width: 25px;
  position: absolute;
  left: 8px;
  top: 8px;

  .icon {
    font-size: 20px;
  }
`;
