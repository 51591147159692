import React, { useState } from 'react';
import styled from 'styled-components';
import MyButton from '../../components/Button/Button';
// import { useHistory } from "react-router";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import ClipLoader from 'react-spinners/ClipLoader';
import request from '../../apiUrls/request';

const StaffAccess = () => {
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object({
    password: Yup.string()
      .required('*Required')
      .min(8, '*Password must be at least 8 characters'),
  });

  const urlParams = new URLSearchParams(window.location.search);
  const staffid = urlParams.get('staffid');
  const email = urlParams.get('email');
  const role = urlParams.get('position');

  const initialValues = {
    staffnum_or_regnum: '' + staffid,
    email: '' + email,
    role: '' + role,
    password: '',
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const response = await request.patch('/main/staff-verification/', values);
      if (response.status === 200) {
        toast.success(response.data.message);
        setLoading(false);
      } else {
        toast.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  const onSubmit = (values) => {
    handleSubmit(values);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  // const history = useHistory();
  return (
    <SignupWrapper>
      <div className="container">
        <form onSubmit={formik.handleSubmit}>
          <ToastContainer style={{ zIndex: '10' }} position="bottom-left" />
          <h4>Set Up you account</h4>
          {/* <div className="form-group">
            <label>Enter Staff ID</label>
            <input
              type="text"
              name="staffnum_or_regnum"
              onChange={formik.handleChange}
              value={formik.values.staffnum_or_regnum}
              className="form-control"
              placeholder=""
            />
            {formik.errors.staffnum_or_regnum &&
            formik.touched.staffnum_or_regnum ? (
              <div className="error">{formik.errors.staffnum_or_regnum}</div>
            ) : null}
          </div> */}
          {/* <div className="form-group">
            <label>Enter Email</label>
            <input
              type="email"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              className="form-control"
              placeholder=""
            />
            {formik.errors.email && formik.touched.email ? (
              <div className="error">{formik.errors.email}</div>
            ) : null}
          </div> */}
          <div className="form-group">
            <label>Enter Password</label>
            <input
              type="password"
              name="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              className="form-control"
              placeholder=""
            />
            {formik.errors.password && formik.touched.password ? (
              <div className="error">{formik.errors.password}</div>
            ) : null}
          </div>

          <MyButton
            onClick={formik.handleSubmit}
            className="form-btn add-client-btn"
            type="submit"
            full
          >
            {loading ? <ClipLoader color={'#fff'} size={'30px'} /> : 'Login'}
          </MyButton>
        </form>
      </div>
    </SignupWrapper>
  );
};

export default StaffAccess;

const SignupWrapper = styled.div`
  .error {
    color: red;
  }
  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -90%);
    margin-top: 100px;
    width: 500px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    background: #ffffff;
    border: 1px solid #dfe8fc;
    box-sizing: border-box;
    box-shadow: 0px 8px 19px rgb(34 84 211 / 15%);
    border-radius: 0.9em;
    @media screen and (max-width: 511px) {
      width: 95%;
    }
  }
  .form-group {
    margin-bottom: 10px;
  }
  input:focus {
    outline: none;
    border: 1px solid #2254d3;
    box-shadow: none;
  }
  .form-group label {
    font-family: SofiaProMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #8e919c;
  }
  h3 {
    font-family: SofiaProMedium;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;
    color: #071232;
    margin-bottom: 0;
  }
`;
