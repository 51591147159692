import React from 'react';
import styled from 'styled-components';

interface Props {
  children?: React.ReactNode;
  white?: boolean;
  href?: string;
  min?: any;
  max?: any;
  full?: any;
  className?: string;
  type?: any;
  transparent?: any;
  green?: any;
  black?: any;
  height?: any;
  onClick?: () => any;
  loading?: boolean;
  backgroundColor?: string;
  color?: string;
  width?: string;
  disabled?: boolean;
}
import ClipLoader from 'react-spinners/ClipLoader';
const Button = ({
  children,
  white,
  min,
  max,
  full,
  className,
  type,
  transparent,
  green,
  black,
  onClick,
  loading,
  backgroundColor,
  color,
  height,
  width,
  disabled,
  ...props
}: Props) => {
  return (
    <div className="btn-parent">
      <StyledButton
        className={className || 'btn btn-component'}
        white={white}
        green={green}
        min={min}
        full={full}
        type={type}
        max={max}
        onClick={onClick}
        black={black}
        disabled={disabled}
        backgroundColor={backgroundColor}
        color={color}
        height={height}
        width={width}
        transparent={transparent}
        {...props}
      >
        {loading ? (
          <ClipLoader color="white" size="15px" className="spinner" />
        ) : (
          children
        )}
      </StyledButton>
    </div>
  );
};

export default Button;
interface StyledProp {
  white?: any;
  transparent?: any;
  color?: string;
  green?: any;
  black?: any;
  backgroundColor?: string;
  min?: any;
  max?: any;
  full?: any;
  className?: any;
  height?: any;
  width?: string;
}
const StyledButton = styled.button<StyledProp>`
  color: white;
  text-decoration: none;
  display: inline;
  border-radius: 5px;
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : '37px')};
  font-weight: 400;
  font-size: 16px;
  border: none;
  text-align: center;
  color: ${({ white, transparent, color }) =>
    color ? color : white ? '#34A853' : transparent ? '#34A853' : 'white'};
  background: ${({ white, green, transparent, black, backgroundColor }) =>
    backgroundColor
      ? backgroundColor
      : white
      ? '#FFF'
      : green
      ? '#22D389'
      : transparent
      ? 'transparent'
        ? black
        : '#071232'
      : '#2254D3'};
  /* margin-bottom: ${({ min }) => (min ? '0' : '1rem')}; */
  white-space: nowrap;
  border: ${({ transparent }) => (transparent ? '1px solid #34A853' : 'none')};
  :hover {
    cursor: pointer;
    color: #8e919c;
    transition: all 100ms;
  }
  :disabled {
    cursor: not-allowed;
  }
  .spin-parent {
    position: absolute;
  }

  @media only screen and (max-width: 600px) {
    font-size: 12px;
  }
`;
