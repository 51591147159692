import {
  FETCH_BILLS_SUCCESS,
  LOADING,
  FETCH_BILLS_FAILED,
  GET_BANKS_SUCCESS,
  GET_BANKS_FAILED,
  VALIDATED,
} from '../type';
import { retrieveErrMessage } from '../../../utils/helper';
import request, { headers } from '../../../apiUrls/request';
import axios from 'axios';
import { toast } from 'react-toastify';

export const fetchBill = (facility) => async (dispatch) => {
  let token = localStorage.getItem('token');
  dispatch({ type: LOADING, payload: true });
  try {
    const res = await request.get(
      `/utils/payout-option/${facility}/`,
      headers(token)
    );
    //  console.log(res)

    dispatch({
      type: FETCH_BILLS_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    const err = retrieveErrMessage(error);
    dispatch({
      type: FETCH_BILLS_FAILED,
      payload: [],
      error: err,
    });
  }
};

export const getBanks = () => async (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  try {
    const response = await request.get(`/billings/banks/`);
    dispatch({
      type: GET_BANKS_SUCCESS,
      payload: response.data,
    });
    // console.log(response.data);
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({
      type: GET_BANKS_FAILED,
      payload: error,
    });
    dispatch({ type: LOADING, payload: false });
  }
};

export const validateAccountAction = (payload) => async (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  console.log(payload);
  try {
    const response = await axios.post(
      `http://localhost:8000/api/v1/utils/validate-bank-account/`,
      payload
    );
    dispatch({
      type: VALIDATED,
      payload: response.data,
    });
    return response.data;
  } catch (error) {
    toast.error('Invalid Account Number');
  }
};

export default fetchBill;
