import React, { useState } from 'react';
import { whiteBackground } from '@styles/react-select-custom';
import Select from 'react-select';
import ModalWrapper from '@components/modalComponents/NewModal';
import FormTitleSection from '@components/modalComponents/FormTitleSection';
import { useFetchAllCountries, useFetchActiveCountries } from '@hooks/queries';
import Button from '@components/Button/Button';
import { toast } from 'react-toastify';
import request, { headers } from '../../../apiUrls/request';
export const ActivateCurrency = ({
  activateCurrency,
  setActivateCurrency,
  data,
}) => {
  const { refetch } = useFetchActiveCountries();
  const { data: allCountries } = useFetchAllCountries();
  const currencyOptions = allCountries?.data?.data?.data?.map((item) => ({
    label: item.currency,
    value: item.currency_code,
  })) || [{ label: 'Select country', value: '' }];

  const token = localStorage.getItem('token');
  const [subscriptionCurrency, setSubscriptionCurrency] = useState(null);
  const [transactionCurrency, setTransactionCurrency] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const submit = async (e) => {
    e.preventDefault();
    if (!subscriptionCurrency || !transactionCurrency) {
      toast.error('All fields are require');
      return;
    }
    setSubmitLoading(true);
    try {
      const res = await request.post(
        `billings/country-currency/create/`,
        {
          country: data.country,
          country_code: data.country_code,
          subscription_currency: subscriptionCurrency.label,
          subscription_currency_code: subscriptionCurrency.value,
          transaction_currency: transactionCurrency.label,
          transaction_currency_code: transactionCurrency.value,
        },
        headers(token)
      );
      if (res.status === 201) {
        refetch();
        setActivateCurrency(false);
        toast.success(
          `Transaction and subscription currencies set  for ${data.country}`
        );
      } else {
        toast.error(`Error setting currencies for ${data.country}`);
      }
    } catch (error) {
      toast.error(`Error setting currencies for ${data.country}`);
      console.log('error', error);
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <ModalWrapper>
      <FormTitleSection
        title="Activate a country"
        onClick={() => setActivateCurrency(!activateCurrency)}
      />
      <form className="">
        <div className="mb-3">
          <Select
            options={currencyOptions}
            placeholder="subscription country"
            style={whiteBackground}
            value={subscriptionCurrency}
            onChange={(e) => setSubscriptionCurrency(e[0])}
          />
        </div>
        <div className="mb-3">
          <Select
            options={currencyOptions}
            placeholder="transaction country"
            style={whiteBackground}
            value={transactionCurrency}
            onChange={(e) => setTransactionCurrency(e[0])}
          />
        </div>
        <div className="mt-3">
          <Button onClick={submit} type="submit" title={submitLoading}>
            save
          </Button>
        </div>
      </form>
    </ModalWrapper>
  );
};
