import swal from 'sweetalert';
import { toast } from 'react-toastify';

/**
 * Represents a React hook to show a delete dialog box.
 *
 */

export default function useDialog() {
  const deleteItem = (handleDelete, id) => {
    swal({
      title: 'Are you sure',
      text: `You want to delete? process cannot be reversed`,
      icon: 'warning',
      buttons: {
        none: {
          text: 'cancel',
          className: 'cancel',
          value: null,
        },
        confirm: {
          text: `delete`,
          value: true,
          closeModal: false,
          className: 'confirm',
        },
      },
    }).then((value) => {
      if (value) {
        handleDelete(id)
          .then(() => {
            swal.stopLoading();
            swal.close();
            toast.success('Deleted successfully');
          })
          .catch((err) => {
            swal.stopLoading();
            swal.close();
            toast.error(err?.response?.data?.message || `Could not delete`);
          });
      } else {
        swal.stopLoading();
        swal.close();
      }
    });
  };
  const deleteStaff = (handleDelete, id, name) => {
    swal({
      title: `Are you sure you want to delete ${name}?`,
      text: `You want to delete ${name}? process cannot be reversed`,
      icon: 'warning',
      buttons: {
        none: {
          text: 'cancel',
          className: 'cancel',
          value: null,
        },
        confirm: {
          text: 'delete',
          value: true,
          closeModal: false,
          className: 'confirm',
        },
      },
    }).then((value) => {
      if (value) {
        handleDelete(id)
          .then(() => {
            swal.stopLoading();
            swal.close();
            toast.success('Deleted successfully');
          })
          .catch((err) => {
            swal.stopLoading();
            swal.close();
            toast.error(err?.response?.data?.message || 'Could not delete');
          });
      } else {
        swal.stopLoading();
        swal.close();
      }
    });
  };
  return {
    deleteItem,
    deleteStaff,
  };
}

export const useHandleDialogueDelete = (handleAction, message) => {
  swal({
    title: `Warning`,
    text: message,
    icon: 'warning',
    buttons: {
      none: {
        text: 'cancel',
        className: 'cancel',
        value: null,
      },
      confirm: {
        text: 'confirm',
        value: true,
        closeModal: false,
        className: 'confirm',
      },
    },
  }).then((value) => {
    if (value) {
      handleAction()
        .then(() => {
          swal.stopLoading();
          swal.close();
          // toast.success('Deleted successfully');
        })
        .catch((err) => {
          swal.stopLoading();
          swal.close();
          toast.error('Could not update status');
          console.log('error', err);
        });
    } else {
      swal.stopLoading();
      swal.close();
    }
  });
};
