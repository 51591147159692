import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBanks,
  validateAccountAction,
} from '@redux/actions/hospitalActions/fetchBill';
import Select from 'react-select';
import AddMoreButton from '@components/Button/AddMoreButton';
import styled from 'styled-components';
import request, { headers } from '@request';
import { toast } from 'react-toastify';

const BankAccounts = ({ facility }) => {
  const [bank, setBank] = useState(null);
  const [account_number, setAccount_number] = useState('');
  const [account_name, setAccount_name] = useState('');
  const [sortCode, setSortCode] = useState('');
  const dispatch = useDispatch();
  const [billCat, setBillCat] = useState('');
  const { banks } = useSelector((state) => state.bills);
  // console.log('banks', banks);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [isActive] = useState(false);
  const validateAccount = useCallback(async () => {
    const value = await dispatch(
      validateAccountAction({
        account_number: account_number,
        bank_code: bank.value,
      })
    );
    // console.log(value.data.account_name);
    setAccount_name(value.data.account_name);
  }, [account_number, bank, dispatch]);

  useEffect(() => {
    if ((account_number.length === 10) & (bank !== null)) {
      validateAccount();
    }
  }, [account_number, bank, validateAccount]);

  useEffect(() => {
    dispatch(getBanks());
    fetchPaymentOptions();
    // eslint-disable-next-line
  }, [0]);
  const subscriptionPlans = [
    { value: 'ALL', label: 'All' },
    { value: 'REGISTRATION', label: 'Registration' },
    { value: 'CONSULTATION', label: 'Consultation' },
    { value: 'DIAGNOSTICS', label: 'Diagnostics' },
    { value: 'LABORATORY', label: 'Laboratory' },
    { value: 'EMERGENCY', label: 'Emergencies' },
    { value: 'ESERVICE', label: 'Eservices' },
    { value: 'HOMESERVICE', label: 'Home services' },
    { value: 'INSURANCE', label: 'Insurance' },
  ];

  let token = localStorage.getItem('token');

  const onSubmit = async () => {
    let payload = {
      account_number: account_number,
      account_name: account_name,
      sort_code: sortCode,
      bank_name: bank.label,
      billing_category: billCat,
      note: '',
      facility: facility,
    };
    try {
      const res = await request.post(
        `/utils/transaction-payout-option/create/`,
        payload,
        headers(token)
      );
      fetchPaymentOptions();
      resetForm();
      res.status === 201 && toast.success('Account Created Successfully');
    } catch (err) {
      console.log(err);
    }
  };

  const resetForm = () => {
    setBank(null);
    setAccount_number('');
    setAccount_name('');
    setBillCat('');
  };

  const fetchPaymentOptions = async () => {
    try {
      const res = await request.get(
        `/utils/transaction-payout-option/${facility}/`,
        headers(token)
      );
      setPaymentOptions(res.data.data.data);
    } catch (err) {
      console.log(err);
    }
  };
  // console.log('payout options', paymentOptions);
  return (
    <BillingWrapper isActive={isActive}>
      <div className="heading--one">
        <h3>PAYOUT OPTIONS</h3>
      </div>
      <form onSubmit={onSubmit}>
        <div className="input-wrapper">
          <div className="inputs">
            <label>Subscription plan</label>
            <Select
              styles={colorStyles}
              placeholder="Subscription plan"
              options={subscriptionPlans || []}
              value={subscriptionPlans.find((obj) => obj.value === billCat)}
              onChange={(e) => setBillCat(e.value)}
            />
          </div>
          <div className="inputs">
            <label>Bank Name</label>
            <Select
              styles={colorStyles}
              options={banks.map((item) => ({
                value: item.code,
                label: item.name,
              }))}
              height={30}
              placeholder="Select  bank"
              value={bank}
              onChange={(e) => setBank(e)}
            />
          </div>
          <div className="inputs">
            <label>Account Number</label>
            <input
              className="field"
              type="number"
              maxLength={'10'}
              placeholder="Enter Account Number"
              // readonly={editAccount}
              value={account_number}
              onChange={(e) => setAccount_number(e.target.value)}
            />
          </div>
          <div className="inputs">
            <label>SortCode/Routing Number</label>
            <input
              className="field"
              type="number"
              maxLength={'10'}
              placeholder="SortCode/Routing  Number"
              // readonly={editAccount}
              value={sortCode}
              onChange={(e) => setSortCode(e.target.value)}
            />
          </div>
          <div className="inputs">
            <label>Account Name</label>
            <input
              className="field"
              type="text"
              placeholder="Account Name"
              readOnly={true}
              value={account_name}
            />
          </div>
        </div>
        <div className="inputs addmore">
          <AddMoreButton closeModal={onSubmit} preIcon cyan />
        </div>
      </form>

      {/* Display accounts */}
      {paymentOptions.length > 0 ? (
        paymentOptions.map((item) => (
          <form>
            <div className="inputs">
              <input
                className="field"
                // readonly={editAccount}
                value={item.billing_category}
              />
            </div>

            <div className="inputs">
              <input className="field" value={item.bank_name} />
            </div>
            <div className="inputs">
              <input className="field" value={item.account_number} />
            </div>
            <div className="inputs">
              <input
                className="field"
                placeholder="Sort code"
                value={item.sort_code}
              />
            </div>

            <div className="inputs">
              <input
                className="field"
                value={item.account_name}
                readOnly={true}
              />
            </div>
            <div className="inputs">
              <img src="/images/edit.svg" alt="delete" />
            </div>
          </form>
        ))
      ) : (
        <p>You don't have any payout option yet</p>
      )}
    </BillingWrapper>
  );
};

export default BankAccounts;

export const BillingWrapper = styled.div`
  /* background: #f5f8fe; */
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.isActive === 'history' ? '0px' : '10px')};

  & input::placeholder {
    color: #3968df;
    opacity: 0.7;
  }
  & input:-ms-input-placeholder {
    color: #3968df;
    opacity: 0.7;
  }
  & input::-ms-input-placeholder {
    color: #3968df;
    opacity: 0.7;
  }
  & .heading--one {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    & h3 {
      font-family: 'Sofia Pro';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      /* identical to box height */

      letter-spacing: 0.1em;

      /* Blue */

      color: #2254d3;
      padding: 0;
      margin: 0;

      @media screen and (max-width: 425px) {
        font-size: 11px;
      }
    }
  }
  form {
    width: 100%;
    display: flex;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
  }
  .field {
    background: #dfe8fc;
    border-radius: 5px;
    border: none;
    outline: none;
    padding: 0 10px;
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #2254d3;
    height: 40px;
    width: 100%;
    @media screen and (max-width: 425px) {
      margin: 0;
      padding: 0;
      font-size: 10px;
      line-height: 0px;
    }
  }
  .input-wrapper {
    width: 100%;
    display: flex;
    gap: 2px;
    justify-content: space-between;
  }
  label {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    color: #071232;
  }
  .addmore {
    margin-top: 26px;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const colorStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: '#dfe8fc',
    color: '#2254D3',
    borderWidth: 0,
    // height: 30,
    fontSize: '14px',
  }),
  option: (styles) => {
    return {
      ...styles,
      backgroundColor: '#dfe8fc',
      color: '#2254d3',
      ':hover': {
        ...styles[':hover'],
        backgroundColor: '#2254D3',
        color: 'white',
      },
    };
  },
  placeholder: (styles) => {
    return {
      ...styles,
      color: '#3968df',
      opacity: 0.7,
    };
  },
  singleValue: (styles) => ({ ...styles, color: '#2254D3' }),
};
