const countryInfo = () => {
  const country = JSON.parse(localStorage.getItem('country'));
  return {
    name: country?.country,
    code: country?.country_code,
    currency: country?.currency,
    currencyCode: country?.currency_code,
    flagUrl: country?.flag_url,
  };
};

export default countryInfo;
