export const parameters = [
  '12h Fluid-restricted Urine Osmolality',
  '24h Urine Osmolality',
  '24h Urine Potassium (K+)',
  '24h Urine Sodium (Na+)',
  '24h Urine Total Protein',
  '2-Hour Plasma Glucose ',
  'Abnormal Cell Populations ',
  'Accuracy ',
  'Activated partial thromboplastin time (APTT)',
  'Adenovirus 40/41 (Enteric) PCR',
  'Alanine aminotransferase (ALT) ',
  'Albumin',
  'Albumin Creat Ratio Urine',
  'Albumin Urine',
  'Alkaline phosphatase (ALP) ',
  'Alpha Fetoprotein',
  'Alpha-Fetoprotein (AFP)',
  'Ammonia',
  'Amphetamines (AMP) ',
  'Amylase',
  'Antibody to Hep B envelope antigen (anti-HBe)',
  'Antibody to Hep B surface antigen (anti-HBs)',
  'Anti-HBc IgG',
  'Anti-HBc IgM',
  'Antimicrobial Susceptibility Testing (AST)',
  'Appearance',
  'Appearance (color) ',
  'ASC-H (Atypical Squamous Cells - Cannot Exclude HSIL)',
  'Ascorbic Acid ',
  'ASC-US (Atypical Squamous Cells of Undetermined Significance)',
  'Aspartate Aminotransferase (AST)',
  'Asterixis ',
  'Astrovirus PCR ',
  'Atypical Glandular Cells (AGC) ',
  'Barbiturates (BAR)',
  'Base Excess',
  'Basophils',
  'Benzodiazepines (BZO)',
  'Beta Human Chorionic Gonadotrophin (bHCG)',
  'Beta Human Chorionic Gonadotrophin (bHCG) ',
  'Bilharzia',
  'Bilirubin',
  'Bioinformatics Analysis Parameters',
  'Blood Urea Nitrogen (BUN)',
  'Brain Natriuretic Peptide (BNP)',
  'Buprenorphine (BUP).',
  'CA 125',
  'CA 15-3',
  'CA 19-9 ',
  'CA-125',
  'CA19-9',
  'Ca2+(adjusted)',
  'Calcium ',
  'Calcium Lactate ',
  'Cannabinoids (THC) ',
  'Carbon Dioxide (CO2)',
  'Carcinoembryonic Antigen (CEA)',
  'Carcinoma In Situ ',
  'CD10 ',
  'CD117',
  'CD11c',
  'CD13',
  'CD14',
  'CD16/CD56',
  'CD19',
  'CD2',
  'CD23',
  'CD25',
  'CD33',
  'CD34',
  'CD38',
  'CD4',
  'CD5',
  'CD79b',
  'CD8',
  'Cell morphology',
  'Cell Populations Analyzed (Lymphocytes Myeloid Cells Stem/Progenitor Cells (if applicable)) ',
  'Cell Surface Markers (Specific Antigens Analyzed e.g. CD3 CD4 CD8 CD19 CD20 Expression Levels)',
  'Ceruloplasmin',
  'Chlamydia',
  'Chloride',
  'Cholesterol',
  'Cholesterol/HDL',
  'Chromatin ',
  'CK-MB (Creatine Kinase-MB)',
  'Clarity',
  'Clinical History',
  'Cluster Density ',
  'Cluster Passing Filter (PF) Percentage)',
  'Cobalt',
  'Cocaine (COC)',
  'Colonies observed ',
  'Colour',
  'Complete Blood Count (CBC)',
  'Confirmation of Sickle Cell Disease.',
  'Consistency',
  'Controls',
  'Controls: (+/-)',
  'Copper',
  'Cortisol (random)',
  'Cortisol (random)',
  'Coverage',
  'C-reactive Protein (CRP)',
  'Creatine Kinase (CK)',
  'Creatine Urine Spot',
  'Creatinine',
  'Cross-Reactivity',
  'CRP',
  'Cryptosporidium',
  'Crystals',
  'Culture Growth',
  'Cycle Threshold (Ct) Value',
  'cytoCD79a',
  'cytoMPO',
  'cytoTdT',
  'Date and time of specimen collection',
  'D-dimer',
  'Description of the specimen including its size and appearance',
  'Detection of other JAK2 mutations (if applicable))',
  'Electrolytes',
  'Electrolytes (Na+ K+ Ca2+ Cl–)',
  'Electrolytes- Sodium (Na)',
  'Entamoeba',
  'Eosinophils',
  'Erythrocyte Sedimentation Rate ESR',
  'Estimated glomerular filtration rate (eGFR)',
  'Estradiol',
  'Evidence of Head Trauma',
  'Fasting Blood Glucose',
  'Fasting Blood Glucose and 2 hours Postprandial ',
  'Fasting Plasma Glucose (FPG 8 hours overnight fast)',
  'Fat content (fecal fat) ',
  'Female- FSH',
  'Ferritin:',
  'Fibrinogen',
  'FMC7',
  'Folate',
  'Food Allergens',
  'Free T3 ',
  'Free T3: 3.5 – 7.8 pmol/L',
  'Free T4',
  'Gamma GT ',
  'Gamma-glutamyltransferase  (GGT)',
  'Genotype and Mutational Analysis (optional)',
  'GGT',
  'Giardia lamblia ',
  'Glucose',
  'Glucose Challenge Level (1-Hour Plasma Glucose)',
  'Grading and Staging (if applicable)',
  'Gram Stain ',
  'Gross Description',
  'Growth hormone (random)',
  'Haematocrit',
  'Haemoglobin (Hb)',
  'HbA1c',
  'HBV DNA',
  'HCO3',
  'HDL',
  'HDL-C ',
  'Hematocrit',
  'Hemoglobin (Hb)',
  'Hep B envelope Antigen (HBeAg)',
  'Hep B surface antigen (HBsAg)',
  'Hepatitis B Core Antibody (Anti-HBc)',
  'Hepatitis B DNA (Viral Load) ',
  'Hepatitis B e Antibody (Anti-HBe or HBeAb)',
  'Hepatitis B e Antigen (HBeAg)',
  'Hepatitis B Surface Antibody (Anti-HBs or HBsAb)',
  'Hepatitis B Surface Antigen (HBsAg)',
  'HER2/neu (Human Epidermal Growth Factor Receptor 2)',
  'High-density lipoprotein cholesterol (HDL-C)',
  'HPV Test Result',
  'HSIL (High-Grade Squamous Intraepithelial Lesion)',
  'Identification of any abnormalities such as tumors inflammation or infection',
  'Identification tests',
  'IgG anti-HBc',
  'IgM anti-HBc',
  'Insect Venom ',
  'Insert Size ',
  'Insulin Levels',
  'Interpretation and Clinical Correlation',
  'Interpretation by Healthcare Professional.',
  'Invalid or Error Result (if applicable)',
  'JAK2 Gene Expression ',
  'Jo-1',
  'K+',
  'Kappa and lambda light chains on B cells',
  'Ketone ',
  'La (SS-B) ',
  'Lactate',
  'Lactate (plasma)',
  'Lactate dehydrogenase (LDH)',
  'LDL',
  'LDL/HDL ratio',
  'LDL-C',
  'Legionella',
  'Leukocytes',
  'Level of Consciousness (e.g. GCS/AVPU)',
  'LH',
  'Library Preparation Metrics',
  'Low-density Lipoprotein Cholesterol (LDL-C)',
  'Lower Limit of Detection (LLD)',
  'LSIL (Low-Grade Squamous Intraepithelial Lesion)',
  'Lymphocyte',
  "Macroscopic examination findings describing the specimen's appearance to the naked eye",
  'Magnesium (MG) ',
  'Male- FSH',
  'Maturation or Activation Status (if applicable)',
  'Mean Cell Volume (MCV)',
  'Mean Corpuscular Haemoglobin (MCH)',
  'Measurement',
  'Medications',
  'Methadone (MTD)',
  'Methamphetamine (MET)',
  'Method of Testing (Skin Prick Test Blood Test (IgE test) Patch Test)',
  'Mg2+',
  'Microalbumin',
  'Microbiota Aanalysis',
  'Microorganisms Observed',
  'Microscopic Description',
  'Microscopic Examination ',
  'Minimum Inhibitory Concentration (MIC)',
  'Monocytes',
  'Morphology (Shape)',
  'Motility',
  'MTB Detection(+/-)',
  'Mucus',
  'Mutation Analysis (Detection of JAK2 V617F Mutation ',
  'Mycoplasma',
  'Myoglobin',
  'Na+',
  'Neutrophils',
  'Neutrophils (% of total WCC)',
  'Nitrite',
  'Non-HDL-C',
  'Non-high-density lipoprotein cholesterol (non-HDL-C): ',
  'Normal Range',
  'Norovirus PCR',
  'N-terminal pro-BNP (NT-proBNP)',
  'NT-proBNP:',
  'Occult blood',
  'Odor',
  'Opening pressure',
  'Opiates (OPI) ',
  'Other Abnormalities (Polychromasia)',
  'Oxycodone (OXY) ',
  'Parasites',
  'Parasites and Pathogens ',
  'Parathyroid hormone',
  'pCO2',
  'Percentage of Sickle Cells',
  'Percentages or Absolute Counts ',
  'pH',
  'pH (Acidity/Alkalinity)',
  'pH level',
  'Phencyclidine (PCP) ',
  'Phosphate',
  'Platelet Count',
  'Platelet Morphology ',
  'pO2',
  'Postprandial (2 hours after eating)',
  'Potassium (K) Chloride (Cl)',
  'Presence of Hemoglobin S (HbS)',
  'Presence or absence of normal structures',
  'Progesterone',
  'Progressive Motility ',
  'Prolactin',
  'Propoxyphene (PPX)',
  'Prostate Specific Antigen (PSA)',
  'Protein',
  'Prothrombin time (PT)',
  'Quality Control Measures',
  'Quality Control Measures Interpretation Guidelines.',
  'Quantitative Measurement (for Blood Tests)',
  'Random urine osmolality',
  'Ratio of Cell Subsets',
  'Read Length ',
  'Read Quality ',
  'Recommendations (Allergen Avoidance Immunotherapy (if applicable)) ',
  'Red Blood Cell Morphology',
  'Red blood cells (RBC)',
  'Red blood cells with a target-like appearanceHowell-Jolly Bodies)',
  'Red cell count (RCC)',
  'Red cell distribution width (RDW)',
  'Reference Gene(s)',
  'Results (Allergy Status Severity)',
  'Reticulocyte Count',
  'Ribosomal P ',
  'RIF Resistance Detection (+/-)',
  'RNP (RNP A RNP 68)',
  'Ro (SS-A 52 SSA-60)',
  'Rotavirus PCR ',
  'Round Cells (Non-Sperm Cells)',
  'Run Metrics (Run Duration) ',
  'SAAG -(serum albumin) - (ascitic fluid albumin)',
  'Salmonella Paratyphi A (AH) Antigen',
  'Salmonella Paratyphi B (BH) Antigen.',
  'Salmonella Typhi H (TH) Antigen ',
  'Salmonella Typhi O (TO) Antigen ',
  'Sample Type(Blood Bone Marrow or Other)',
  'Sapovirus PCR',
  'Schistosoma haematobium',
  'Scl-70',
  'Semiquantitative Information(High/Low/Medium) ',
  'Sequencing Platform',
  'Serum Albumin',
  'Serum Calcium',
  'Serum Creatinine',
  'Serum Ketones',
  'Serum Osmolality',
  'Serum Phosphate',
  'Serum Potassium',
  'Serum Sodium',
  'Serum Sodium concentration',
  'Serum Total Protein',
  'Serum Urea',
  'Size color consistency and any other relevant features',
  'Sm',
  'Sm/RNP',
  'Sodium Urine Spot',
  'Sodium/Creat Ratio',
  'Sources of infection',
  'Special Stains or Immunohistochemistry',
  'Specific Gravity',
  'Specify Panel (Inhalant Allergens )',
  'Specimen Information',
  'Sperm Concentration ',
  'Sperm Count ',
  'Squamous Cell Carcinoma or Adenocarcinoma ',
  'Staining Characteristics',
  'Symptom Correlation ',
  'T3 ',
  'T4',
  'Target Cells (Codocytes)',
  'TC/HDL ratio',
  'Testosterone',
  'TG/HDL ratio ',
  'Threshold for Treatment Initiation',
  'Thresholds or Reference Ranges ',
  'Throughput',
  'Thyroglobulin',
  'Total Cholesterol (TC) ',
  'Total Cholesterol to HDL ratio',
  'Total Iron Binding Capacity (TIBC)',
  'Total Serum Iron:',
  'Transferrin',
  'Transferrin Saturation',
  'TRH Thyroid-Releasing Hormone',
  'Triglycerides (TG)',
  'Troponin I (cTnI)',
  'Troponin T',
  'Troponin T (cTnT',
  'TSH',
  'TSH  thyroid-stimulating hormone',
  'Undigested food particles',
  'Urate',
  'Urea',
  'Urine pH (random)',
  'Urobilinogen',
  'Viability (Vitality)',
  'Viral Load',
  'Viscosity',
  'Vitamin A',
  'Vitamin B12',
  'Vitamin B2 (riboflavin)',
  'Vitamin B3 (nicotinic acid)',
  'Vitamin B6',
  'Vitamin C',
  'Vitamin D',
  'Vitamin E',
  'Volume',
  'WBC',
  'White Blood Cell (WBC) Morphology ',
  'White Blood Cells (WBCs)',
  'White Cell Count (WCC) Eosinophils',
  'White Cell Count (WCC) Lymphocytes',
  'White Cell Count (WCC) Monocytes',
  'White Cell Count (WCC) Neutrophils',
  'White Cell Count (WCC) Total',
  'White Cell Count (WCC)Basophils',
  'Zone of inhibition',
];

export const units = [
  '%',
  '°C',
  'µg/L',
  'μg',
  'µmol/L',
  'μs',
  'A',
  'atm',
  'bp',
  'cal',
  'cd',
  'cells/µl',
  'cl',
  'cm',
  'cm^3',
  'Days',
  'dB',
  'dyn',
  'eV',
  'fl',
  'ft',
  'g',
  'g/dl',
  'g/L',
  'Gb',
  'Hours',
  'Hz',
  'iu/L',
  'iu/ml',
  'J',
  'K',
  'kg',
  'kg/m^3',
  'Kpa',
  'kWh',
  'L',
  'Lb',
  'm',
  'M',
  'm',
  'm^3',
  'mcg',
  'mcg/dl',
  'mcg/L',
  'mckat/L',
  'mcl',
  'mEq',
  'mEq/L',
  'mg',
  'mg/dl',
  'mg/L',
  'mIU/L',
  'ml',
  'mm',
  'mm3',
  'mmHg',
  'mmol',
  'mmol/24 Hr',
  'mmol/L',
  'mol',
  'mOsm/kg ',
  'ms',
  'mU/g',
  'mU/L',
  'mU/ml',
  'N',
  'ng/dl',
  'ng/L',
  'ng/ml',
  'ng/ml/hr',
  'nmol',
  'nmol/L',
  'P',
  'Pa·s',
  'pg',
  'pg/c',
  'pg/ml',
  'pmol/L',
  'ppm',
  's',
  'Titers',
  'u/L',
  'u/ml',
  'Weeks',
  'x 10^9/L',
  'Years',
];
