//error message retriever
export const retrieveErrMessage = (err) => {
  if (err.response && err.response.data) {
    return err.response.data.message || err.response.data.error;
  } else if (err.message) {
    return err.message;
  } else {
    return err;
  }
};

export function capitalizeFirstLetter(txt) {
  return txt.charAt(0).toUpperCase() + txt.slice(1); //or if you want lowercase the rest txt.slice(1).toLowerCase();
}

export function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

export const formatTimeSlotToRemovSeconds = (timeSlot) => {
  if (timeSlot) {
    const time = timeSlot?.split('-');
    const startTime = time[0].split(':');
    const endTime = time[1].split(':');
    const startTimeFormatted = `${startTime[0]}:${startTime[1]}`;
    const endTimeFormatted = `${endTime[0]}:${endTime[1]}`;
    return `${startTimeFormatted}-${endTimeFormatted}`;
  } else {
    return timeSlot;
  }
};
