import styled from 'styled-components';

export const FacilityTableStyle = styled.div`
  .patient-name {
    color: #2254d3 !important;
  }

  .act {
    cursor: pointer;
  }
  .popup {
    position: absolute;
    min-width: 200px;
    /* max-height: 150px; */
    right: 50px;
    top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    padding: 1rem;
    border: 1px solid rgba(33, 51, 79, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    z-index: 10000;
    border-radius: 10px;
    /* z-index: 100; */
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #071232;
    /* overflow: auto; */
    p:not(:last-child) {
      margin-bottom: 12px !important;
    }
    p:hover {
      display: inline-block;
      transition: all 1000ms;
      font-weight: 700;
    }

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .red {
    color: rgba(180, 0, 0, 0.4);
  }
  .pagination-container {
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .pagination-bar {
    display: flex;
    justify-content: space-between;
    width: 300px;
  }
  .spin-parent {
    text-align: center;
    border-radius: 5px;
    width: 100%;
    height: 45px;
    margin-top: 40px;
  }
  .flags {
    height: 20px;
    width: 30px;
  }
`;
