import { combineReducers } from 'redux';
import LoginReducer from './auth/login.reducer';
import SignupReducer from './auth/signup.reducer';
import logoutReducer from './auth/logout.reducer';
import pageInfoReducer from './reducers/pageInfoReducer';
import allFacilitiesReducer from './reducers/fetchFacilities';
import fetchStaffReducer from './reducers/fetchStaffReducer';
import country from './reducers/countryInfo';
import fetchBillReducer from './reducers/fetchBillsReducer';
import fetchPaymentModeReducer, {
  transactionReducer,
} from './reducers/subscription_transaction_reducers';
import fetchFacilityReducer from './reducers/fetctFacilityReducer';

const rootReducer = combineReducers({
  login: LoginReducer,
  signup: SignupReducer,
  logout: logoutReducer,
  staff: fetchStaffReducer,
  pageInfo: pageInfoReducer,
  facilities: allFacilitiesReducer,
  country,
  bills: fetchBillReducer,
  paymentMode: fetchPaymentModeReducer,
  transaction: transactionReducer,
  facility: fetchFacilityReducer,
});

export default rootReducer;
